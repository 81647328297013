import React from 'react';
import {
  Flex,
  Text,
  Select,
  Button,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useState } from 'react';
import { useEffect } from 'react';
import { Input } from '../Input';
import { api } from '../../service/api';
import {
  convertCurrencyAmerican,
  convertCurrencyBrazil,
} from '../../utils/convertData';

type GeneralSettingsFormData = {
  id: number;
  allowWithdraw: string;
  allowInvestment: string;
  minWithdraw: string;
  maxWithdraw: string;
  feeWithdraw: string;
  estimatePayday: number;
  whatsappNumber: string;
  allowProfitability: string;
  monthlyPayment: string;
  indicationPercentageLevel1: string;
  indicationPercentageLevel2: string;
  indicationPercentageLevel3: string;
  indicationPercentageLevel4: string;
  indicationPercentageLevel5: string;
  indicationPercentageLevel6: string;
  percentageIndicationForNewUser: string;
};

type GeneralSettingsData = {
  id: number;
  allowWithdraw: boolean;
  allowInvestment: boolean;
  minWithdraw: number;
  maxWithdraw: number;
  feeWithdraw: number;
  estimatePayday: number;
  whatsappNumber: string;
  allowProfitability: boolean;
  monthlyPayment: number;
  indicationPercentageLevel1: number;
  indicationPercentageLevel2: number;
  indicationPercentageLevel3: number;
  indicationPercentageLevel4: number;
  indicationPercentageLevel5: number;
  indicationPercentageLevel6: number;
  percentageIndicationForNewUser: number;
};

interface Props {
  setIsLoading: (isLoading: boolean) => void;
}

export function GeneralSettings({ setIsLoading }: Props): JSX.Element {
  const [generalSettings, setGeneralSettings] = useState<GeneralSettingsData>(
    {} as GeneralSettingsData,
  );

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { register, handleSubmit, formState, setValue } = useForm();

  useEffect(() => {
    try {
      setIsLoading(true);
      api
        .get('settings/setting')
        .then(response => setGeneralSettings(response.data));

      setValue('allowWithdraw', generalSettings.allowWithdraw ? 'yes' : 'no');
      setValue(
        'allowInvestment',
        generalSettings.allowInvestment ? 'yes' : 'no',
      );
      setValue(
        'maxWithdraw',
        convertCurrencyBrazil(generalSettings.maxWithdraw),
      );
      setValue(
        'minWithdraw',
        convertCurrencyBrazil(generalSettings.minWithdraw),
      );
      setValue(
        'feeWithdraw',
        convertCurrencyBrazil(generalSettings.feeWithdraw),
      );
      setValue('estimatePayday', generalSettings.estimatePayday);
      setValue('whatsappNumber', generalSettings.whatsappNumber);
      setValue(
        'allowProfitability',
        generalSettings.allowProfitability ? 'yes' : 'no',
      );
      setValue(
        'indicationPercentageLevel1',
        generalSettings.indicationPercentageLevel1,
      );
      setValue(
        'indicationPercentageLevel2',
        generalSettings.indicationPercentageLevel2,
      );
      setValue(
        'indicationPercentageLevel3',
        generalSettings.indicationPercentageLevel3,
      );
      setValue(
        'indicationPercentageLevel4',
        generalSettings.indicationPercentageLevel4,
      );
      setValue(
        'indicationPercentageLevel5',
        generalSettings.indicationPercentageLevel5,
      );
      setValue(
        'indicationPercentageLevel6',
        generalSettings.indicationPercentageLevel6,
      );
      setValue(
        'monthlyPayment',
        convertCurrencyBrazil(generalSettings.monthlyPayment),
      );
      setValue(
        'percentageIndicationForNewUser',
        generalSettings.percentageIndicationForNewUser,
      );
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    generalSettings.allowProfitability,
    generalSettings.allowWithdraw,
    generalSettings.allowInvestment,
    generalSettings.estimatePayday,
    generalSettings.indicationPercentageLevel1,
    generalSettings.indicationPercentageLevel2,
    generalSettings.indicationPercentageLevel3,
    generalSettings.indicationPercentageLevel4,
    generalSettings.indicationPercentageLevel5,
    generalSettings.indicationPercentageLevel6,
    generalSettings.maxWithdraw,
    generalSettings.minWithdraw,
    generalSettings.feeWithdraw,
    generalSettings.whatsappNumber,
    generalSettings.monthlyPayment,
    generalSettings.percentageIndicationForNewUser,
    setValue,
  ]);

  const toast = useToast();

  const handleGeneralSettings: SubmitHandler<GeneralSettingsFormData> =
    async data => {
      try {
        const settings = {
          allowWithdraw: data.allowWithdraw === 'yes',
          allowProfitability: data.allowProfitability === 'yes',
          allowInvestment: data.allowInvestment === 'yes',
          minWithdraw: convertCurrencyAmerican(data.minWithdraw),
          maxWithdraw: convertCurrencyAmerican(data.maxWithdraw),
          feeWithdraw: convertCurrencyAmerican(data.feeWithdraw),
          monthlyPayment: convertCurrencyAmerican(data.monthlyPayment),
          estimatePayday: data.estimatePayday,
          whatsappNumber: data.whatsappNumber
            .replace('(', '')
            .replace(')', '')
            .replace('-', '')
            .trim(),
          indicationPercentageLevel1: data.indicationPercentageLevel1,
          indicationPercentageLevel2: data.indicationPercentageLevel2,
          indicationPercentageLevel3: data.indicationPercentageLevel3,
          indicationPercentageLevel4: data.indicationPercentageLevel4,
          indicationPercentageLevel5: data.indicationPercentageLevel5,
          indicationPercentageLevel6: data.indicationPercentageLevel6,
          percentageIndicationForNewUser: data.percentageIndicationForNewUser,
        };

        await api.put('settings/setting', settings);

        toast({
          title: 'Configurações atualizadas com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (err) {
        toast({
          title: 'Erro ao atualizar as configurações, tente novamente!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    };

  return (
    <Flex
      w="100%"
      h="100%"
      gap={5}
      flexDir={isWideVersion ? 'row' : 'column'}
      justifyContent="center"
    >
      <Flex
        as="form"
        onSubmit={handleSubmit(handleGeneralSettings)}
        h="100%"
        bg="gray.800"
        p="10"
        w="100%"
        maxW="1100px"
        rounded={10}
        flexDir="column"
      >
        <Flex gap="4" align="center" justify="space-between" flexDir="column">
          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align="center"
            justify="space-between"
            gap="4"
            w="100%"
          >
            <Flex flexDir="column" w="100%">
              <Text>Permitir saque?</Text>

              <Select
                placeholder="Selecione uma opção"
                {...register('allowWithdraw')}
              >
                <option value="yes" style={{ background: '#353646' }}>
                  Sim
                </option>
                <option value="no" style={{ background: '#353646' }}>
                  Não
                </option>
              </Select>
            </Flex>

            <Flex flexDir="column" w="100%">
              <Text>Permitir depósito?</Text>

              <Select
                placeholder="Selecione uma opção"
                {...register('allowInvestment')}
              >
                <option value="yes" style={{ background: '#353646' }}>
                  Sim
                </option>
                <option value="no" style={{ background: '#353646' }}>
                  Não
                </option>
              </Select>
            </Flex>

            <Flex flexDir="column" w="100%">
              <Text>Habilitar rendimento automático?</Text>

              <Select
                placeholder="Selecione uma opção"
                {...register('allowProfitability')}
              >
                <option value="yes" style={{ background: '#353646' }}>
                  Sim
                </option>
                <option value="no" style={{ background: '#353646' }}>
                  Não
                </option>
              </Select>
            </Flex>
          </Flex>

          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align="center"
            justify="space-between"
            gap="4"
            w="100%"
          >
            <Input
              label="Valor mínimo do saque"
              mask="currency"
              icon="$ "
              {...register('minWithdraw')}
            />

            <Input
              label="Valor máximo do saque"
              mask="currency"
              icon="$ "
              {...register('maxWithdraw')}
            />

            <Input
              label="Taxa de saque"
              mask="currency"
              icon="$ "
              {...register('feeWithdraw')}
            />
          </Flex>

          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align="center"
            justify="space-between"
            gap="4"
            w="100%"
          >
            <Input
              label="Valor da mensalidade"
              mask="currency"
              icon="$ "
              {...register('monthlyPayment')}
            />

            <Input
              label="Prazo de Pagamento de Saque (dias)"
              type="number"
              {...register('estimatePayday')}
            />

            <Input
              mask="mobile"
              label="Número de whatsapp para contato"
              {...register('whatsappNumber')}
            />
          </Flex>
        </Flex>

        <Flex flexDir="column" mt="5">
          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align="center"
            justify="space-between"
            gridGap="4"
          >
            <Input
              label="Nível 1"
              icon="%"
              {...register('indicationPercentageLevel1')}
            />
            <Input
              label="Nível 2"
              icon="%"
              {...register('indicationPercentageLevel2')}
            />
            <Input
              label="Nível 3"
              icon="%"
              {...register('indicationPercentageLevel3')}
            />
          </Flex>

          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align="center"
            justify="space-between"
            gridGap="4"
          >
            <Input
              label="Nível 4"
              icon="%"
              {...register('indicationPercentageLevel4')}
            />
            <Input
              label="Nível 5"
              icon="%"
              {...register('indicationPercentageLevel5')}
            />
            <Input
              label="Nível 6"
              icon="%"
              {...register('indicationPercentageLevel6')}
            />
          </Flex>

          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align="center"
            justify="space-between"
            gridGap="4"
          >
            <Input
              label="Porcentagem de indicação de investimento"
              icon="%"
              {...register('percentageIndicationForNewUser')}
            />
          </Flex>
        </Flex>

        <Button
          mt="10"
          type="submit"
          isLoading={formState.isSubmitting}
          bg="#0C6E9C"
          alignSelf="center"
          w={!isWideVersion ? '28' : '100%'}
        >
          Salvar
        </Button>
      </Flex>
    </Flex>
  );
}
