import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  IconButton,
  useToast,
  useDisclosure,
  Button,
  Collapse,
  Text,
} from '@chakra-ui/react';
import { addDays, format, setHours } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { KeyboardEvent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { addMonths } from 'date-fns/esm';
import { api } from '../../service/api';
import { Input } from '../Input';
import { Select } from '../Select';
import { ConvertDateAmerican } from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';

interface User {
  id: number;
  name: string;
  email: string;
  birthDate: Date;
  country: string;
  gender: string;
  cep: string;
  city: string;
  state: string;
}
interface WithdrawalsData {
  id: number;
  user: User;
  description: string;
  paydayDate: Date;
  estimatePayday: Date;
  date: Date;
  status: string;
  amount: number;
  netAmount: number;
  bankName: string;
  bankAgency: string;
  bankAccount: string;
  digitalWallet: string;
}

export function Withdrawals(): JSX.Element {
  const [withdrawals, setWithdrawals] = useState<WithdrawalsData[]>([]);
  const [isLoadingWithdrawals, setIsLoadingWithdrawals] = useState(false);
  const [isLoadingCancelWithdrawals, setIsLoadingCancelWithdrawals] =
    useState(false);

  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [statusData, setStatusData] = useState('');
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingClearFilter, setIsLoadingClearFilter] = useState(false);
  const [initialDate, setInitialDate] = useState(addMonths(new Date(), -1));
  const [finalDate, setFinalDate] = useState(new Date());

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  const toast = useToast();

  useEffect(() => {
    try {
      api.get('/withdraws').then(response => setWithdrawals(response.data));
    } catch (error) {
      //
    }
  }, []);

  useEffect(() => {
    if (isLargerThan1368) {
      onOpen();
    } else {
      onClose();
    }
  }, [isLargerThan1368, onOpen, onClose]);

  function handleInitialDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setInitialDate(setHours(date, 0));
  }

  function handleFinalDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setFinalDate(setHours(date, 23));
  }

  async function handleConfirmationWithdrawals(id: number) {
    try {
      setIsLoadingWithdrawals(true);
      const result = window.confirm(
        'Tem certeza que gostaria de confirmar esse saque?',
      );

      if (!result) {
        setIsLoadingWithdrawals(false);
        return;
      }

      await api.get(`admin/withdraw/confirm/${id}`);

      toast({
        title: 'Saque confirmado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setIsLoadingWithdrawals(false);

      api.get('/withdraws').then(response => setWithdrawals(response.data));
    } catch (error) {
      setIsLoadingWithdrawals(false);

      const { data } = error.response;

      toast({
        title: 'Erro confirmar o saque!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: data,
        position: 'top-right',
      });
    }
  }

  async function handleDenyWithdraw(id: number) {
    try {
      setIsLoadingCancelWithdrawals(true);
      const result = window.confirm(
        'Tem certeza que gostaria de cancelar esse saque?',
      );

      if (!result) {
        setIsLoadingCancelWithdrawals(false);
        return;
      }

      await api.get(`admin/withdraw/deny/${id}`);

      toast({
        title: 'Saque cancelado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setIsLoadingCancelWithdrawals(false);

      api.get('/withdraws').then(response => setWithdrawals(response.data));
    } catch (error) {
      setIsLoadingCancelWithdrawals(false);

      const { data } = error.response;

      toast({
        title: 'Erro cancelar o saque!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: data,
        position: 'top-right',
      });
    }
  }

  function handleFilterInvoices() {
    try {
      setIsLoadingFilter(true);

      const initalDateRequest = ConvertDateAmerican(initialDate);
      const finalDateRequest = ConvertDateAmerican(addDays(finalDate, 1));

      api
        .get(
          `withdraws?name=${name}&amount=${amount}&initialDate=${initalDateRequest}&finalDate=${finalDateRequest}&status=${statusData}`,
        )
        .then(response => setWithdrawals(response.data));

      if (!isLargerThan1368) onClose();
    } catch (error) {
      //
    } finally {
      setIsLoadingFilter(false);
    }
  }

  async function handleClearFilterInvoices() {
    try {
      setIsLoadingClearFilter(true);
      await api
        .get('/withdraws')
        .then(response => setWithdrawals(response.data));

      setName('');
      setAmount(0);
      setStatusData('-1');

      if (!isLargerThan1368) onClose();
    } catch (error) {
      //
    } finally {
      setIsLoadingClearFilter(false);
    }
  }

  if (!withdrawals) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  function handleKeyPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const { nativeEvent } = event;
    if (nativeEvent.key === 'Enter') {
      handleFilterInvoices();
    }
  }

  return (
    <Flex flexDir="column" gridGap="4">
      <Box bg="whiteAlpha" overflowX="auto">
        {!isLargerThan1368 && (
          <Button
            colorScheme="teal"
            alignSelf="center"
            marginY="2"
            onClick={onToggle}
          >
            Filtros
          </Button>
        )}

        <Collapse animateOpacity in={isOpen}>
          <Flex flexDir="column" bg="gray.800" gridGap="10" p="4" rounded={10}>
            <Flex flexDir="column" gridGap="4">
              <Flex
                gridGap="2"
                justify="space-between"
                w="100%"
                flexDir={isLargerThan1368 ? 'row' : 'column'}
              >
                <Input
                  name="name"
                  label="Nome"
                  placeholder="Nome ou usuário"
                  value={name}
                  onChange={event => setName(event.target.value)}
                  onKeyPress={handleKeyPressEnter}
                />

                <Flex flexDir="column" w="75%">
                  <Text mb={2}>Saldo</Text>
                  <NumberFormat
                    prefix="$ "
                    placeholder="Valor do saque"
                    allowNegative={false}
                    decimalSeparator=","
                    decimalScale={2}
                    thousandSeparator="."
                    onValueChange={values => {
                      const { floatValue } = values;
                      setAmount(floatValue || 0);
                    }}
                    onKeyPress={handleKeyPressEnter}
                    style={{
                      width: '100%',
                      outline: '2px solid transparent',
                      outlineOffset: 2,
                      backgroundColor: '#181B23',
                      height: '3rem',
                      borderRadius: '0.375rem',
                      border: '2px solid trasparent',
                      fontSize: '1.125rem',
                      paddingInlineStart: '1rem',
                      paddingInlineEnd: '2.5rem',
                    }}
                  />
                </Flex>

                <Select
                  name="status"
                  label="Status"
                  value={statusData}
                  onChange={event => setStatusData(event.target.value)}
                >
                  <option value="-1">Todos</option>
                  <option value="0">Em Análise</option>
                  <option value="1">Pago</option>
                  <option value="2">Negado</option>
                </Select>
              </Flex>

              <Flex gridGap="4">
                <DatePicker
                  selected={initialDate}
                  maxDate={finalDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => handleInitialDate(date)}
                  customInput={<Input name="from" label="De" />}
                  locale={ptBR}
                />

                <DatePicker
                  selected={finalDate}
                  minDate={initialDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => handleFinalDate(date)}
                  customInput={<Input name="to" label="Até" />}
                  locale={ptBR}
                />

                <Flex ml="auto" gridGap="4" align="flex-end">
                  <Button
                    colorScheme="teal"
                    type="button"
                    onClick={handleFilterInvoices}
                    isLoading={isLoadingFilter}
                  >
                    Pesquisar
                  </Button>
                  <Button
                    type="button"
                    colorScheme="whiteAlpha"
                    variant="outline"
                    onClick={handleClearFilterInvoices}
                    isLoading={isLoadingClearFilter}
                  >
                    Limpar
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Collapse>

        <Box
          hidden={isOpen && !isLargerThan1368}
          overflowX="auto"
          overflowY="auto"
          maxH={isLargerThan1368 ? '530px' : '380px'}
          sx={{
            '::-webkit-scrollbar': {
              width: '4px',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#666',
              borderRadius: '4px',
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: '#2f3136',
            },
          }}
        >
          <Table bg="#0C6E9C" mt="10px" rounded={10}>
            <Thead>
              <Tr>
                <Th color="#FFF">Usuário</Th>
                <Th color="#FFF">Descrição</Th>
                <Th color="#FFF">Status</Th>
                <Th color="#FFF">Valor</Th>
                <Th color="#FFF">Valor Líquido</Th>
                <Th color="#FFF">Data de Saque</Th>
                <Th color="#FFF">Data de pagamento</Th>
                <Th color="#FFF">Conta | Carteira Digital</Th>
                <Th color="#FFF">Açoẽs</Th>
              </Tr>
            </Thead>
            <Tbody>
              {withdrawals.map(withdrawal => (
                <Tr key={String(withdrawal.id)}>
                  <Td>{withdrawal.user.name}</Td>
                  <Td>{withdrawal.description}</Td>
                  <Td>{withdrawal.status}</Td>
                  <Td>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={withdrawal.amount}
                      decimalScale={2}
                      prefix="$"
                    />
                  </Td>
                  <Td>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={withdrawal.netAmount}
                      decimalScale={2}
                      prefix="$"
                    />
                  </Td>
                  <Td>{format(new Date(withdrawal.date), 'dd/MM/yyyy')}</Td>
                  <Td>
                    {String(withdrawal.paydayDate) === '0001-01-01T00:00:00'
                      ? ''
                      : format(new Date(withdrawal.paydayDate), 'dd/MM/yyyy')}
                  </Td>
                  <Td>
                    {`${withdrawal.bankName} (Ag:${withdrawal.bankAgency} | Cc:${withdrawal.bankAccount})`}
                  </Td>
                  <Td height="73px">
                    {withdrawal.status === 'Em Análise' && (
                      <Flex gridGap="2" align="center">
                        <IconButton
                          colorScheme="green"
                          aria-label="Confirmar saque"
                          title="Confirmar saque"
                          type="button"
                          icon={<FiCheckCircle />}
                          isLoading={isLoadingWithdrawals}
                          onClick={() =>
                            handleConfirmationWithdrawals(withdrawal.id)
                          }
                        />

                        <IconButton
                          colorScheme="red"
                          aria-label="Negar Saque"
                          title="Negar Saque"
                          type="button"
                          isLoading={isLoadingCancelWithdrawals}
                          icon={<FiXCircle />}
                          onClick={() => handleDenyWithdraw(withdrawal.id)}
                        />
                      </Flex>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Flex>
  );
}
