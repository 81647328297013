import React, { useState, createContext, ReactNode, useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { EncryptStorage } from 'encrypt-storage';

import { api } from '../service/api';
import { ConvertDateAmerican } from '../utils/convertData';

enum Roles {
  Admin = 0,
  Client = 1,
  Franchise = 2,
}

interface UpdateUser {
  name: string;
  cpf: string;
  email: string;
  birthDate: string;
  country: string;
  gender: string;
  phoneNumber: string;
  phoneNumberAux: string;
  cep: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  number: number;
  complement: string;
  identityImage: File;
  adressImage: File;
  contractConfirmation: boolean;
}

export interface User {
  id: number;
  activeStatus: number;
  name: string;
  username: string;
  cpf: string;
  email: string;
  birthDate: Date;
  gender: string;
  phoneNumber: string;
  phoneNumberAux: string;
  cep: string;
  number: number;
  street: string;
  state: string;
  city: string;
  country: string;
  neighborhood: string;
  complement: string;
  bankName: string;
  bankAgency: string;
  bankAccount: string;
  bankAccountType: string;
  profitabilityGroup: {
    id: number;
    groupName: string;
    profitabilityBonus: number;
    referralBonus: number;
  };
  balance: number;
  balanceAvailable: number;
  dadId: number;
  role: Roles;
  creationDate: Date;
  hasSecurityPass: boolean;
  notes: string;
  contractConfirmation: boolean;
  digitalWallet: string;
  profitabilityDayMonth: number;
  pix: string;
  isMigration: boolean;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  login: string;
  password: string;
  isRemember: boolean;
}

interface AuthContextData {
  user: User;
  validToken(): string | undefined;
  signIn(crendetials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: UpdateUser): Promise<void>;
  refreshUser(): Promise<void>;
}

interface AuthProvideProps {
  children: ReactNode;
}

interface DecodeTokenProps {
  exp: number;
  iat: number;
}

const secetKey = 'Xa4HH{)o+c@{*<c';
const encryptStorage = new EncryptStorage(secetKey, {
  prefix: '@Active',
});

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function AuthProvider({ children }: AuthProvideProps) {
  const [data, setData] = useState(() => {
    const token = encryptStorage.getItem('token');
    const user = encryptStorage.getItem('user');

    if (token && user) {
      const decode = jwt_decode<DecodeTokenProps>(token);
      const dateNow = new Date();
      const isTokenExpired = decode.exp < dateNow.getTime() / 1000;

      if (isTokenExpired) {
        encryptStorage.removeItem('token');
        encryptStorage.removeItem('user');

        return {} as AuthState;
      }

      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      return { token, user };
    }

    return {} as AuthState;
  });

  function signOut() {
    encryptStorage.removeItem('token');
    encryptStorage.removeItem('user');

    setData({} as AuthState);
  }

  async function signIn({ login, password, isRemember }: SignInCredentials) {
    if (isRemember) {
      localStorage.setItem('@Active:rememberLogin', login);
    } else {
      localStorage.setItem('@Active:rememberLogin', '');
    }

    const response = await api.post<AuthState>('users/authenticate', {
      login,
      password,
    });

    const { token, user } = response.data;

    setData({ token, user });

    encryptStorage.setItem('user', user);
    encryptStorage.setItem('token', token);

    api.defaults.headers.common.Authorization = `Bearer ${data.token}`;
  }

  async function refreshUser() {
    const response = await api.get(`users/${data.user.id}`, {
      headers: { Authorization: `Bearer ${data.token}` },
    });

    setData({ token: data.token, user: response.data });

    encryptStorage.setItem('user', response.data);
  }

  async function updateUser(updateUser: UpdateUser) {
    const formData = new FormData();

    formData.append('name', updateUser.name);
    formData.append('neighborhood', updateUser.neighborhood);
    formData.append('phoneNumber', updateUser.phoneNumber);
    formData.append('state', updateUser.state);
    formData.append('street', updateUser.street);
    formData.append('cep', updateUser.cep);
    formData.append('city', updateUser.city);
    formData.append('country', updateUser.country);
    formData.append('cpf', updateUser.cpf);
    formData.append('email', updateUser.email);
    formData.append('gender', updateUser.gender);
    formData.append('number', String(updateUser.number));
    formData.append(
      'contractConfirmation',
      String(updateUser.contractConfirmation),
    );

    const birthDate = ConvertDateAmerican(updateUser.birthDate);
    formData.append('birthDate', birthDate);

    if (updateUser.phoneNumberAux)
      formData.append('phoneNumberAux', updateUser.phoneNumberAux);

    if (updateUser.complement)
      formData.append('complement', updateUser.complement);

    if (updateUser.adressImage)
      formData.append('adressImage', updateUser.adressImage);

    if (updateUser.identityImage)
      formData.append('identityImage', updateUser.identityImage);

    await api.put(`users/data/${data.user.id}`, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    });

    await refreshUser();
  }

  function validToken() {
    if (data.token) {
      return data.token;
    }

    return undefined;
  }

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        refreshUser,
        validToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
