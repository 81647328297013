import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Text,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';
import { Select } from '../Select';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';
import {
  convertCurrencyAmerican,
  convertCurrencyBrazil,
} from '../../utils/convertData';

type WithdrawProps = {
  description: string;
  status: 0;
  amount: string;
  securityPassword: string;
  currencyType: 'R$' | '$' | '';
};

interface SettingsProps {
  feeWithdraw: number;
}

const createUserFormSchema = yup.object().shape({
  amount: yup.string().required('Valor do saque obrigatório'),
  description: yup.string(),
  status: yup.number(),
  securityPassword: yup.string().required('Senha de segurança obrigatória'),
});

export function WithdrawModal(): JSX.Element {
  const { user, refreshUser, validToken } = useAuth();
  const { isOpenWithdrawModal, handleCloseWithdrawModal } = useReactModal();
  const { register, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(createUserFormSchema),
    defaultValues: {
      amount: '',
      status: 0,
      description: '',
      securityPassword: '',
      currencyType: user && user.role === 2 ? '' : '$',
    },
  });

  const [currencyTypeSelected, setCurrencyTypeSelected] = useState(
    user && user.role === 2 ? '' : '$',
  );
  const [settings, setSettings] = useState<SettingsProps>({} as SettingsProps);

  const toast = useToast();

  const { errors } = formState;

  useEffect(() => {
    try {
      api.get('settings/setting').then(response => setSettings(response.data));
    } catch {
      // Error
    }
  }, []);

  const handleNewWithdraw: SubmitHandler<WithdrawProps> = async values => {
    try {
      if (user && user.role === 2 && !currencyTypeSelected) {
        setError('currencyType', { message: 'Informe o tipo de carteira' });
        return;
      }

      const request = {
        user,
        amount: convertCurrencyAmerican(values.amount),
        status: 0,
        description: values.description,
        securityPassword: values.securityPassword,
        currency: currencyTypeSelected,
      };

      await api.post('withdraws', request, {
        headers: { Authorization: `Bearer ${validToken()}` },
      });

      toast({
        title: 'Saque solicitado!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        description: 'Logo logo o dinheiro será depósitado.',
      });

      await refreshUser();

      handleCloseWithdrawModal();
    } catch (error) {
      const { data, status } = error.response;
      toast({
        title: 'Erro na solicitação do saque!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        description: status === 400 ? data : '',
      });
    }
  };

  return (
    <Modal isOpen={isOpenWithdrawModal} onClose={handleCloseWithdrawModal}>
      <ModalOverlay />

      <ModalContent bg="gray.800" p="4">
        <ModalHeader>Solicitar saque</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex
            as="form"
            onSubmit={handleSubmit(handleNewWithdraw)}
            flexDir="column"
            gridGap="2"
          >
            {user && user.role === 2 && (
              <Select
                placeholder="Escolha de qual carteira vai sacar"
                error={errors.currencyType}
                {...register('currencyType')}
                onChange={event => setCurrencyTypeSelected(event.target.value)}
              >
                <option style={{ color: '#000' }} value="$">
                  Dólar - $
                </option>
              </Select>
            )}

            <Input
              label="Valor do saque"
              mask="currency"
              icon={currencyTypeSelected}
              disabled={!currencyTypeSelected}
              error={errors.amount}
              {...register('amount')}
            />

            <Input
              label="Mensagem"
              disabled={!currencyTypeSelected}
              error={errors.description}
              {...register('description')}
            />

            <Input
              label="Senha de segurança"
              type="password"
              disabled={!currencyTypeSelected}
              error={errors.securityPassword}
              {...register('securityPassword')}
            />

            {currencyTypeSelected === '$' && (
              <Text my="4" fontSize="large" fontWeight="bold">
                Taxa de saque: $ {convertCurrencyBrazil(settings.feeWithdraw)}
              </Text>
            )}

            <Flex gridGap="4" mt="4" alignSelf="flex-end">
              <Button
                type="button"
                colorScheme="whiteAlpha"
                onClick={handleCloseWithdrawModal}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                bg="#0C6E9C"
                isLoading={formState.isSubmitting}
              >
                Solicitar saque
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
