import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';
import { api } from '../../service/api';
import {
  convertCurrencyAmerican,
  convertCurrencyBrazil,
} from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';

type FranchisedPropsApi = {
  name: string;
  price: number;
};

type FranchisedPropsScene = {
  name: string;
  price: string;
};

const newInvestmentPropsSchema = yup.object().shape({
  name: yup.string().required('Nome da franquia obrigatório'),
  price: yup.string().required('Valor da franquia obrigatório'),
});

export function FranchisedModal(): JSX.Element {
  const { isOpenNewFranchised, handleCloseNewFranchised, idFranchised } =
    useReactModal();

  const { register, handleSubmit, formState, clearErrors, reset } = useForm({
    resolver: yupResolver(newInvestmentPropsSchema),
  });

  const [franchised, setFranchised] = useState<FranchisedPropsScene>();
  const [isLoading, setIsLoading] = useState(false);

  const { errors } = formState;

  const toast = useToast();

  useEffect(() => {
    try {
      if (!idFranchised || idFranchised < 0) return;

      setIsLoading(true);

      clearErrors('amount');

      api.get<FranchisedPropsApi>(`franchises/${idFranchised}`).then(response =>
        setFranchised({
          name: response.data.name,
          price: convertCurrencyBrazil(response.data.price),
        }),
      );
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [idFranchised, clearErrors, isOpenNewFranchised]);

  useEffect(() => {
    reset(franchised);
  }, [franchised, reset]);

  const handleNewFranchised: SubmitHandler<FranchisedPropsScene> =
    async data => {
      try {
        const dataSendApi = {
          name: data.name,
          price: convertCurrencyAmerican(data.price),
        };

        if (idFranchised && idFranchised > 0) {
          await api.put(`franchises/${idFranchised}`, dataSendApi);
          return;
        }

        await api.post('franchises', dataSendApi);

        toast({
          title: 'Franquia criada com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        toast({
          title: `Erro ao ${
            !idFranchised || idFranchised < 0 ? 'criar' : 'editar'
          } a franquia!`,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        handleCloseNewFranchised();
      }
    };

  return (
    <Modal isOpen={isOpenNewFranchised} onClose={handleCloseNewFranchised}>
      <ModalOverlay />

      <ModalContent bg="gray.800" p="4">
        <ModalHeader>
          {idFranchised === 0 ? 'Nova' : 'Editar'} franquia{' '}
          {idFranchised !== 0 && franchised ? franchised.name : ''}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {isLoading ? (
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          ) : (
            <Flex
              as="form"
              onSubmit={handleSubmit(handleNewFranchised)}
              flexDir="column"
              gridGap="2"
            >
              <Input
                label="Nome da franquia"
                error={errors.name}
                {...register('name')}
              />

              <Input
                label="Valor da franquia"
                mask="currency"
                icon="$"
                error={errors.price}
                {...register('price')}
              />

              <Flex gridGap="4" mt="4" alignSelf="flex-end">
                <Button
                  type="button"
                  colorScheme="whiteAlpha"
                  onClick={handleCloseNewFranchised}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  bg="#0C6E9C"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
