import React, { ReactNode } from 'react';
import { useRadio, Box, UseRadioProps } from '@chakra-ui/react';

interface Props extends UseRadioProps {
  children: ReactNode;
}

export function RadioCard(props: Props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const { children } = props;

  return (
    <Box as="label" w="100%" textAlign="center" fontSize={20}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'green.600',
          color: '#FFF',
          borderColor: 'green.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
}
