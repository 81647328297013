import { useMemo, useState } from 'react';
import { convertCurrencyAmericanNumber } from '../utils/convertData';

type Direction = 'ascending' | 'descending' | undefined;

interface ConfigProps {
  key: string;
  direction: Direction;
  type: TypeData;
}

type TypeData = 'number' | 'date' | 'string' | undefined;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any[];
  config: ConfigProps;
}

export const useSortableData = ({ items, config }: Props) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (!sortConfig.type) return 0;

        if (sortConfig.type === 'number') {
          const aNumber = convertCurrencyAmericanNumber(a[sortConfig.key]);
          const bNumber = convertCurrencyAmericanNumber(b[sortConfig.key]);

          if (aNumber < bNumber) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }

          if (aNumber > bNumber) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }

          return 0;
        }

        if (sortConfig.type === 'date') {
          if (!a[sortConfig.key] || !b[sortConfig.key]) return 0;

          const [dayA, monthA, yearA] = a[sortConfig.key].split('/');
          const [dayB, monthB, yearB] = b[sortConfig.key].split('/');
          const aDate = new Date(`${monthA}-${dayA}-${yearA}`);
          const bDate = new Date(`${monthB}-${dayB}-${yearB}`);

          if (aDate < bDate) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }

          if (aDate > bDate) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }

          return 0;
        }

        const strA = a[sortConfig.key]
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const strB = b[sortConfig.key]
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');

        if (strA < strB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }

        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }

        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string, type: TypeData) => {
    if (sortConfig.direction === 'descending') {
      setSortConfig({ key, direction: undefined, type });
      return;
    }

    let direction: Direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction, type });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
