import React from 'react';
import {
  Divider,
  Flex,
  Image,
  Link,
  useBreakpointValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Button,
} from '@chakra-ui/react';
import { FiDollarSign } from 'react-icons/fi';

import { useSidebarDrawer } from '../../contexts/SidebarDrawerContent';
import { SidebarNav } from './SidebarNav';
import { useAuth } from '../../hooks/auth';
import { useReactModal } from '../../contexts/ReactModal';

import logoSignin from '../../assets/logo-escritorio.png';

export function Sidebar(): JSX.Element {
  const { user } = useAuth();
  const { isOpen, onClose } = useSidebarDrawer();

  const { handleOpenNewInvestment } = useReactModal();

  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false,
  });

  if (isDrawerSidebar) {
    return (
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bg="gray.800" p="4">
            <DrawerCloseButton mt="6" />
            <DrawerHeader>Navegação</DrawerHeader>

            <DrawerBody>
              <Flex flexDir="column">
                <SidebarNav />

                {user.role !== 0 && user.activeStatus === 1 && isDrawerSidebar && (
                  <Button
                    leftIcon={<FiDollarSign />}
                    onClick={handleOpenNewInvestment}
                    colorScheme="green"
                    alignSelf="center"
                    mt="4"
                  >
                    Depositar
                  </Button>
                )}
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }

  return (
    <Flex
      as="aside"
      minW="72"
      w="15vw"
      maxH="100%"
      justify="stretch"
      flexDir="column"
      align="center"
      bg="#0C6E9C"
    >
      <Flex align="center" marginY="2">
        <Link href="/dashboard" alignSelf="center">
          <Image src={logoSignin} alt="Logo Active" fit="cover" maxW="200px" />
        </Link>
      </Flex>

      <Divider mb="7" />

      <SidebarNav />
    </Flex>
  );
}
