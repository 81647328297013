import React from 'react';
import {
  Avatar,
  Flex,
  IconButton,
  Icon,
  Text,
  Button,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { FiMenu, FiPower, FiDollarSign } from 'react-icons/fi';

import { useLocation } from 'react-router-dom';
import { useSidebarDrawer } from '../contexts/SidebarDrawerContent';
import { useReactModal } from '../contexts/ReactModal';

import { useAuth } from '../hooks/auth';
import { Messages } from './Messages';

export function Header(): JSX.Element {
  const { user, signOut } = useAuth();
  const { handleOpenNewInvestment } = useReactModal();
  const { onOpen } = useSidebarDrawer();
  const { pathname } = useLocation();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const isDraweSidebarSomeScreens =
    pathname === '/reports' ||
    pathname === '/financial-adm' ||
    pathname === '/management';

  function renderRole(): JSX.Element {
    switch (user.role) {
      case 0:
        return <Text fontSize="4xl">Administrador</Text>;
      case 2:
        return <Text fontSize="4xl">Consultor</Text>;
      default:
        return <Text fontSize="4xl">Cliente</Text>;
    }
  }

  return (
    <Flex
      alignItems="center"
      justify="space-between"
      p="4"
      bg="#0C6E9C"
      h="5rem"
      w="100%"
    >
      {(!isWideVersion || isDraweSidebarSomeScreens) && (
        <IconButton
          aria-label="Abrir navegação"
          icon={<Icon as={FiMenu} />}
          fontSize="24"
          variant="unstyled"
          onClick={onOpen}
        />
      )}

      {user.role !== 0 && user.activeStatus === 1 && (
        <Box>
          {isWideVersion ? (
            <Button
              leftIcon={<FiDollarSign />}
              onClick={handleOpenNewInvestment}
              colorScheme="green"
            >
              Depositar
            </Button>
          ) : (
            <IconButton
              position="fixed"
              aria-label="Pagar rendimento"
              icon={<FiDollarSign size={20} />}
              bottom="5"
              left="5"
              zIndex="99"
              colorScheme="green"
              onClick={handleOpenNewInvestment}
            />
          )}
        </Box>
      )}

      <Box marginX="auto" alignSelf="center">
        {renderRole()}
      </Box>

      <Flex gap="4" alignItems="center">
        <Messages />

        <Flex alignItems="center">
          <Avatar size="md" name={user.name} mr="4" />
          {isWideVersion && <Text>{user.username}</Text>}
        </Flex>

        <IconButton
          aria-label="Sair da aplicação"
          icon={<Icon as={FiPower} />}
          fontSize="24"
          variant="unstyled"
          onClick={signOut}
        />
      </Flex>
    </Flex>
  );
}
