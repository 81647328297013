import {
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useBreakpointValue,
  Slide,
  useDisclosure,
  Button,
  useMediaQuery,
} from '@chakra-ui/react';
import { format, addDays, setHours } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useAuth, User } from '../../hooks/auth';
import { api } from '../../service/api';
import { Input } from '../Input';
import { LoadingSpinner } from '../LoadingSpinner';

interface Extract {
  id: string;
  type: number;
  user: User;
  description: string;
  date: Date;
  amount: number;
}

interface FinancialStatementProps {
  monthGain: number;
  totalGain: number;
  extractAlls: Extract[];
}

export function FinancialStatement(): JSX.Element {
  const { user } = useAuth();
  const [extracts, setExtracts] = useState<FinancialStatementProps>();
  const [initialDate, setInitialDate] = useState(addDays(new Date(), -7));
  const [finalDate, setFinalDate] = useState(new Date());
  const { isOpen, onToggle } = useDisclosure();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  useEffect(() => {
    try {
      api
        .post(`extracts/user/${user.id}`, {
          initialDate,
          finalDate,
        })
        .then(response => setExtracts(response.data));
    } catch (error) {
      //
    }
  }, [user.id, initialDate, finalDate]);

  function handleInitialDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setInitialDate(setHours(date, 0));
  }

  function handleFinalDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setFinalDate(setHours(date, 23));
  }

  if (!extracts) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <>
      {!isLargerThan1368 && (
        <Flex align="center" justify="center" w="100%">
          <Button colorScheme="linkedin" onClick={onToggle}>
            Redimentos Totais | Rendimentos mês
          </Button>
        </Flex>
      )}
      <Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
        <Box
          p="40px"
          color="white"
          mt="4"
          bg="gray.700"
          rounded="md"
          shadow="md"
        >
          <Flex gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
            <Flex
              p="4"
              rounded={10}
              background="green.400"
              flexDir="row"
              flex="1"
              align="center"
            >
              <Flex flexDir="column">
                <Text fontSize="4xl">
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={extracts.totalGain}
                    decimalScale={2}
                    prefix="$ "
                  />
                </Text>
                <Text fontSize="xl">Total em ganhos</Text>
              </Flex>
            </Flex>

            <Flex
              p="4"
              rounded={10}
              background="green.600"
              flexDir="column"
              flex="1"
              align="center"
            >
              <Text fontSize="4xl">
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  displayType="text"
                  value={extracts.monthGain}
                  decimalScale={2}
                  prefix="$ "
                />
              </Text>
              <Text fontSize="xl"> Ganhos neste mês</Text>
            </Flex>
          </Flex>
        </Box>
      </Slide>
      <Flex flexDir="column" gridGap="4">
        {isLargerThan1368 && (
          <Flex gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
            <Flex
              p="4"
              rounded={10}
              flexDir="column"
              flex="1"
              align="center"
              bg="#0C6E9C"
            >
              <Flex align="center" gridGap="5">
                <Text fontSize="4xl">
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={extracts.totalGain}
                    decimalScale={2}
                    prefix="$ "
                  />
                </Text>
              </Flex>
              <Text fontSize="3xl">Total em ganhos</Text>
            </Flex>

            <Flex
              p="4"
              rounded={10}
              bg="#0C6E9C"
              flexDir="column"
              flex="1"
              align="center"
            >
              <Flex align="center" gridGap="5">
                <Text fontSize="4xl">
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={extracts.monthGain}
                    decimalScale={2}
                    prefix="$ "
                  />
                </Text>
              </Flex>
              <Text fontSize="3xl"> Ganhos neste mês</Text>
            </Flex>
          </Flex>
        )}

        <Flex
          justify="space-between"
          flexDir={isWideVersion ? 'row' : 'column'}
          p="4"
          bg="#0C6E9C"
          mt="2"
        >
          <Flex flexDir="row" gridGap="10">
            <DatePicker
              selected={initialDate}
              maxDate={finalDate}
              dateFormat="dd/MM/yyyy"
              onChange={date => handleInitialDate(date)}
              customInput={<Input name="from" label="De" />}
              locale={ptBR}
            />

            <DatePicker
              selected={finalDate}
              minDate={initialDate}
              dateFormat="dd/MM/yyyy"
              onChange={date => handleFinalDate(date)}
              customInput={<Input name="to" label="Até" />}
              locale={ptBR}
            />
          </Flex>
        </Flex>

        <Box overflowY="auto" maxH={isLargerThan1368 ? '480px' : '250px'}>
          <Table bg="#0C6E9C">
            <Thead>
              <Tr>
                <Th color="#fff">Descrição</Th>
                <Th color="#fff">Data</Th>
                <Th color="#fff">Valor</Th>
              </Tr>
            </Thead>
            <Tbody bg="#0C6E9C">
              {extracts?.extractAlls.map(extract => (
                <Tr key={extract.id}>
                  <Td>{extract.description}</Td>
                  <Td>{format(new Date(extract.date), 'dd/MM/yyyy')}</Td>
                  <Td>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={extract.amount}
                      decimalScale={2}
                      prefix="$"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </>
  );
}
