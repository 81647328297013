import React from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  RadioGroup,
  Stack,
  Radio,
  Text,
  Image,
  Center,
  FormLabel,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useEffect } from 'react';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';
import { api } from '../../service/api';
import { convertCurrencyAmerican } from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';
import logoBnb from '../../assets/bnb-icon.svg';

type NewInvestmentProps = {
  amount: string;
  paymentVoucher: FileList;
};

const newInvestmentPropsSchema = yup.object().shape({
  amount: yup.string().required('Valor do depósito obrigatório'),
});

interface User {
  name: string;
}

export function NewInvestmentAdm(): JSX.Element {
  const [userInvestiment, setUserInvestiment] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpenNewInvestmentAdmModal,
    handleCloseNewInvestmentAdmModal,
    idInvestmentUser,
  } = useReactModal();

  const { register, handleSubmit, formState, clearErrors } = useForm({
    resolver: yupResolver(newInvestmentPropsSchema),
  });

  const { errors } = formState;

  const toast = useToast();

  useEffect(() => {
    try {
      if (!idInvestmentUser || idInvestmentUser === 0) return;

      setIsLoading(true);

      clearErrors('amount');

      api
        .get<User>(`users/${idInvestmentUser}`)
        .then(response => setUserInvestiment(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [clearErrors, idInvestmentUser]);

  const handleNewInvestment: SubmitHandler<NewInvestmentProps> =
    async values => {
      try {
        const formData = new FormData();

        formData.append('amount', convertCurrencyAmerican(values.amount));

        // formData.append('paymentVoucher', values.paymentVoucher[0]);

        await api.post(`admin/investment/${idInvestmentUser}`, formData, {
          headers: {
            'Content-Type': `multipart/form-data;`,
          },
        });

        toast({
          title: 'Depósito realizado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });

        handleCloseNewInvestmentAdmModal();
      } catch (error) {
        toast({
          title: 'Erro ao realizar o depósito!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    };

  return (
    <Modal
      isOpen={isOpenNewInvestmentAdmModal}
      onClose={handleCloseNewInvestmentAdmModal}
      size="xl"
    >
      <ModalOverlay />

      <ModalContent bg="gray.800" p="4">
        {userInvestiment.name && (
          <ModalHeader display="flex">
            <Image
              src={logoBnb}
              alt="Logo BNB"
              fit="cover"
              maxW="50px"
              marginRight="0.5rem"
            />
            Depósito BUSD para {userInvestiment.name}
          </ModalHeader>
        )}
        <ModalCloseButton />

        <ModalBody>
          {isLoading ? (
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          ) : (
            <Flex
              as="form"
              onSubmit={handleSubmit(handleNewInvestment)}
              flexDir="column"
              gridGap="2"
            >
              <FormLabel>
                Carteira para depósito de BUSD: <br />
                0x012af3b45ebca4e379b2fc51d608ff29bb931d52 <br /> Atenção! O
                envio deverá ser feito através da rede BSC - BNB Smart Chain
                (BEP20)
              </FormLabel>
              <Input
                label="Valor do depósito"
                mask="currency"
                icon="$ "
                error={errors.amount}
                {...register('amount')}
              />
              <Input
                label="Hash da Transação"
                icon="0x"
                error={errors.hashTransactionBep20}
                {...register('hashTransactionBep20')}
              />

              <Flex gridGap="4" mt="4" alignSelf="flex-end">
                <Button
                  type="button"
                  colorScheme="whiteAlpha"
                  onClick={handleCloseNewInvestmentAdmModal}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  bg="#0C6E9C"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
