import React, {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  ReactNode,
} from 'react';
import {
  FormControl,
  FormLabel,
  InputProps as ChakraInputProps,
  Input as ChakraInput,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';
import { cep, cpf, currency, date, phone, mobile, percentual } from './mask';

interface InputProps extends ChakraInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  error?: FieldError;
  mask?:
    | 'cep'
    | 'currency'
    | 'cpf'
    | 'date'
    | 'phone'
    | 'mobile'
    | 'percentual';
  icon?: ReactNode;
  rightAction?: ReactElement;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    disabled = false,
    mask,
    icon,
    error = null,
    rightAction,
    ...rest
  },
  ref,
) => {
  const handleKeyUp = (e: FormEvent<HTMLInputElement>) => {
    switch (mask) {
      case 'cep':
        cep(e);
        break;
      case 'cpf':
        cpf(e);
        break;
      case 'date':
        date(e);
        break;
      case 'phone':
        phone(e);
        break;
      case 'mobile':
        mobile(e);
        break;
      case 'currency':
        currency(e);
        break;
      case 'percentual':
        percentual(e);
        break;
    }
  };

  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      {icon ? (
        <InputGroup display="flex" alignItems="center">
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            height="100%"
            fontSize="1.2em"
            children={icon}
          />
          <ChakraInput
            name={name}
            id={name}
            disabled={disabled}
            focusBorderColor="gray.500"
            bg="gray.900"
            variant="filled"
            _hover={{ bgColor: 'gray.900' }}
            size="lg"
            onKeyUp={handleKeyUp}
            ref={ref}
            {...rest}
          />
        </InputGroup>
      ) : (
        <InputGroup display="flex" alignItems="center" w="100%">
          <ChakraInput
            name={name}
            id={name}
            disabled={disabled}
            focusBorderColor="gray.500"
            bg="gray.900"
            variant="filled"
            _hover={{ bgColor: 'gray.900' }}
            size="lg"
            onKeyUp={handleKeyUp}
            ref={ref}
            {...rest}
          />
          <InputRightElement
            alignItems="center"
            justifyContent="center"
            height="100%"
            bg="transparent"
          >
            {rightAction}
          </InputRightElement>
        </InputGroup>
      )}

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Input = forwardRef(InputBase);
