import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  Flex,
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  IconButton,
  Button,
  useToast,
  Text,
} from '@chakra-ui/react';
import { FiEdit3, FiSearch, FiTrash } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { api } from '../../service/api';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { Input } from '../../components/Input';

interface ProfitabilityGroup {
  id: number;
  profitabilityBonus: number;
  referralBonus: number;
}
interface User {
  id: number;
  name: string;
  profitabilityGroup: ProfitabilityGroup;
  username: string;
}

interface UserGroup {
  id: number;
  name: string;
  username: string;
}
interface GroupAndUsers {
  id: number;
  groupName: string;
  profitabilityBonus: number;
  referralBonus: number;
  users: UserGroup[];
}
interface Group {
  id: number;
  groupName: string;
  profitabilityBonus: number;
  referralBonus: number;
}

export function ProfitabilityGroups(): JSX.Element {
  const [groups, setGroups] = useState<Group[]>([]);
  const [groupsAndUsers, setGroupsAndUsers] = useState<GroupAndUsers[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberGroupIndex, setNumberGroupIndex] = useState(0);
  const [userName, setUserName] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const indexOfLastItem = currentPage * 5;
  const indexOfFirstItem = indexOfLastItem - 5;

  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    setIsLoading(true);
    try {
      api
        .get<Group[]>('profitabilityGroups')
        .then(response => setGroups(response.data));
      api.get('users').then(response => setUsers(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const usersGroups = groups.map(group => {
      const { id, groupName, profitabilityBonus, referralBonus } = group;

      const usersGroup = users.filter(
        user =>
          user.profitabilityGroup && user.profitabilityGroup.id === group.id,
      );

      return {
        id,
        groupName,
        profitabilityBonus,
        referralBonus,
        users: usersGroup,
      };
    });

    setGroupsAndUsers(usersGroups);
  }, [groups, users]);

  async function handleRemoveGroup(idGroup: number) {
    try {
      const result = window.confirm(
        'Tem certeza que gostaria de deletar esse grupo de investimento?',
      );

      if (!result) {
        return;
      }

      await api.delete(`profitabilityGroups/${idGroup}`);

      setGroups(state => state.filter(group => group.id !== idGroup));
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro ao deletar o grupo de rendimento',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? data : '',
        position: 'top-right',
      });
    }
  }

  async function handleRemoveUserGroup(idUser: number) {
    try {
      await api.put(`users/profitabilityGroup/delete/${idUser}`);

      setUsers(state => state.filter(user => user.id !== idUser));
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro ao retirar usuário do grupo!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? data : '',
        position: 'top-right',
      });
    }
  }

  const usersFiltered = useMemo(() => {
    if (
      !groupsAndUsers[numberGroupIndex] ||
      !groupsAndUsers[numberGroupIndex].users
    )
      return [];

    const lowerCase = userName?.toLowerCase().trim();

    setCurrentPage(1);
    setMinPageNumberLimit(0);
    setMaxPageNumberLimit(5);

    return groupsAndUsers[numberGroupIndex]?.users?.filter(user =>
      user.username?.toLowerCase().includes(lowerCase),
    );
  }, [groupsAndUsers, numberGroupIndex, userName]);

  const usersPagination = useMemo(() => {
    return usersFiltered.slice(indexOfFirstItem, indexOfLastItem);
  }, [indexOfFirstItem, indexOfLastItem, usersFiltered]);

  const pages = useMemo(() => {
    const pages = [] as number[];

    if (!usersFiltered || usersFiltered.length <= 1) return pages;

    const sizeList = Math.ceil(usersFiltered.length / 5);
    for (let i = 1; i <= sizeList; i++) {
      pages.push(i);
    }

    return pages;
  }, [usersFiltered]);

  const handleNextbtn = useCallback(() => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + 5);
      setMinPageNumberLimit(minPageNumberLimit + 5);
    }
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit]);

  const handlePrevbtn = useCallback(() => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % 5 === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - 5);
      setMinPageNumberLimit(minPageNumberLimit - 5);
    }
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit]);

  const renderPageNumbers: ReactElement[] = useMemo(() => {
    const buttonsPagination = [] as ReactElement[];
    pages.forEach(number => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        const button = (
          <Button
            key={number}
            colorScheme={number === currentPage ? 'facebook' : 'twitter'}
            onClick={() => setCurrentPage(number)}
            color="#FFF"
          >
            {number}
          </Button>
        );

        buttonsPagination.push(button);
      }
    });

    return buttonsPagination;
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit, pages]);

  const showButtonsPagination = useMemo(() => {
    return renderPageNumbers.length > 1;
  }, [renderPageNumbers]);

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  const listCurrentPage = useMemo(() => {
    let listCurrentPage = currentPage * 5;

    if (listCurrentPage >= usersFiltered.length) {
      listCurrentPage = usersFiltered.length;
    }

    return listCurrentPage;
  }, [currentPage, usersFiltered.length]);

  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex w="100%" flexDir="column">
        <Header />

        <Box p="10">
          <Box p="10" border="2px" borderRadius={8} borderColor="#0C6E9C">
            <Flex w="100%">
              <Heading mb="4">Grupo de rendimento</Heading>
              <Button
                ml="auto"
                colorScheme="green"
                onClick={() => history.push('/new-profitability-groups')}
              >
                Novo grupo
              </Button>
            </Flex>

            <Accordion allowToggle bg="#0C6E9C" rounded={4}>
              {groupsAndUsers &&
                groupsAndUsers.map((group, indexGroup) => (
                  <AccordionItem key={group.id}>
                    <Flex
                      alignItems="center"
                      gridGap={2}
                      paddingY="4"
                      paddingX="2"
                      borderBottomWidth={2}
                      borderBottomColor="#0C6E9C"
                      w="100%"
                    >
                      <IconButton
                        colorScheme="blue"
                        icon={<FiEdit3 />}
                        aria-label="Editar franquia"
                        onClick={() =>
                          history.push(`/new-profitability-groups/${group.id}`)
                        }
                      />
                      <IconButton
                        colorScheme="red"
                        icon={<FiTrash />}
                        aria-label="Deletar franquia"
                        onClick={() => handleRemoveGroup(group.id)}
                      />

                      <Text as="h2" w="100%">
                        <AccordionButton
                          w="100%"
                          onClick={() => setNumberGroupIndex(indexGroup)}
                        >
                          <Box flex="1" textAlign="left">
                            {group.groupName}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </Text>
                    </Flex>
                    <AccordionPanel pb={4}>
                      <Box maxW="50%" mb="4">
                        <Input
                          name="search"
                          icon={<FiSearch color="gray.300" />}
                          placeholder="Buscar usuário"
                          value={userName}
                          onChange={event => setUserName(event.target.value)}
                        />
                      </Box>
                      <Flex flexDir="column">
                        <Flex maxH="400px" flexDir="column" overflowY="auto">
                          {usersPagination &&
                            usersPagination.map(user => (
                              <Flex
                                width="50%"
                                align="center"
                                mb="4"
                                key={user.id}
                              >
                                {user.name} - {user.username}
                                <IconButton
                                  colorScheme="red"
                                  ml="auto"
                                  icon={<FiTrash />}
                                  aria-label="Deletar investidor desse grupo"
                                  onClick={() => handleRemoveUserGroup(user.id)}
                                />
                              </Flex>
                            ))}
                        </Flex>
                        {showButtonsPagination && (
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            maxW="50%"
                          >
                            <Box>
                              <Text fontSize="large">
                                {listCurrentPage} de{' '}
                                {usersPagination.length * pages.length}
                              </Text>
                            </Box>
                            <Flex
                              gridGap={3}
                              overflowY="auto"
                              mt="5"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Button
                                colorScheme="twitter"
                                onClick={handlePrevbtn}
                                disabled={currentPage === pages[0]}
                                color="#FFF"
                              >
                                Anterior
                              </Button>

                              {renderPageNumbers}

                              <Button
                                colorScheme="twitter"
                                onClick={handleNextbtn}
                                disabled={
                                  currentPage === pages[pages.length - 1]
                                }
                                color="#FFF"
                              >
                                Próximo
                              </Button>
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}
