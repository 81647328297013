import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ReactModalContextData {
  isOpenNewMessageModal: boolean;
  isOpenNewInvestment: boolean;
  isOpenWithdrawModal: boolean;
  isOpenResetPassword: boolean;
  isOpenResetSecurityPassword: boolean;
  isOpenListMessagesModal: boolean;
  idMessage: number;
  idConfirmedUser: number;
  idInvestmentUser: number;
  idFranchised: number;
  isOpenConfirmedUserModal: boolean;
  isOpenNewInvestmentAdmModal: boolean;
  isOpenNewFranchised: boolean;
  isOpenContractModal: boolean;
  isOpenContractModalDashboard: boolean;
  isOpenTicketModal: boolean;
  handleOpenNewMessageModal: () => void;
  handleCloseNewMessageModal: () => void;
  handleOpenNewInvestment: () => void;
  handleCloseNewInvestment: () => void;
  handleOpenWithdrawModal: () => void;
  handleCloseWithdrawModal: () => void;
  handleOpenResetPassword: () => void;
  handleCloseResetPassword: () => void;
  handleOpenListMessagesModal: () => void;
  handleCloseListMessagesModal: () => void;
  handleIdMessage(idMessage: number): void;
  handleOpenNewInvestmentAdmModal(idUser: number): void;
  handleCloseNewInvestmentAdmModal(): void;
  handleConfirmedUserOpen(idMessage: number): void;
  handleConfirmedUserClose: () => void;
  handleOpenResetSecurityPassword: () => void;
  handleCloseResetSecurityPassword: () => void;
  handleOpenNewFranchised: (idFranchised?: number) => void;
  handleCloseNewFranchised: () => void;
  handleOpenContractModal: () => void;
  handleCloseContractModal: () => void;
  handleOpenContractModalDashboard: () => void;
  handleCloseContractModalDashboard: () => void;
  handleOpenTicketModal: () => void;
  handleCloseTicketModal: () => void;
}

const ReactModalContext = createContext({} as ReactModalContextData);

interface ReactModalProviderProps {
  children: ReactNode;
}

export function ReactModalProvider({
  children,
}: ReactModalProviderProps): JSX.Element {
  const [isOpenNewMessageModal, setIsOpenNewMessageModal] = useState(false);
  const [isOpenNewInvestment, setIsOpenNewNewInvestment] = useState(false);
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false);
  const [isOpenResetPassword, setIsOpenResetPassword] = useState(false);
  const [isOpenResetSecurityPassword, setIsOpenResetSecurityPassword] =
    useState(false);
  const [isOpenListMessagesModal, setIsOpenListMessagesModal] = useState(false);
  const [isOpenNewInvestmentAdmModal, setIsOpenNewInvestmentAdmModal] =
    useState(false);
  const [isOpenConfirmedUserModal, setIsOpenConfirmedUserModal] =
    useState(false);
  const [isOpenNewFranchised, setIsOpenNewFranchised] = useState(false);
  const [isOpenContractModal, setIsOpenContractModal] = useState(false);
  const [isOpenContractModalDashboard, setIsOpenContractModalDashboard] =
    useState(false);

  const [idMessage, setIdMessage] = useState(0);
  const [idConfirmedUser, setidConfirmedUser] = useState(0);
  const [idInvestmentUser, setIdInvestmentUser] = useState(0);
  const [idFranchised, setIdFranchised] = useState(0);
  const [isOpenTicketModal, setIsOpenTicketModal] = useState(false);

  function handleOpenNewMessageModal() {
    setIsOpenNewMessageModal(true);
  }

  function handleCloseNewMessageModal() {
    setIsOpenNewMessageModal(false);
  }

  function handleOpenNewInvestment() {
    setIsOpenNewNewInvestment(true);
  }

  function handleCloseNewInvestment() {
    setIsOpenNewNewInvestment(false);
  }

  function handleOpenWithdrawModal() {
    setIsOpenWithdrawModal(true);
  }

  function handleCloseWithdrawModal() {
    setIsOpenWithdrawModal(false);
  }

  function handleOpenResetPassword() {
    setIsOpenResetPassword(true);
  }

  function handleCloseResetPassword() {
    setIsOpenResetPassword(false);
  }

  function handleOpenResetSecurityPassword() {
    setIsOpenResetSecurityPassword(true);
  }

  function handleCloseResetSecurityPassword() {
    setIsOpenResetSecurityPassword(false);
  }

  function handleOpenListMessagesModal() {
    setIsOpenListMessagesModal(true);
  }

  function handleCloseListMessagesModal() {
    setIsOpenListMessagesModal(false);
  }

  function handleIdMessage(idMessage: number): void {
    setIdMessage(idMessage);
  }

  function handleConfirmedUserOpen(idUser: number): void {
    setIsOpenConfirmedUserModal(true);
    setidConfirmedUser(idUser);
  }

  function handleConfirmedUserClose() {
    setIsOpenConfirmedUserModal(false);
    setidConfirmedUser(0);
  }

  function handleOpenNewInvestmentAdmModal(idUser: number): void {
    setIsOpenNewInvestmentAdmModal(true);
    setIdInvestmentUser(idUser);
  }

  function handleCloseNewInvestmentAdmModal() {
    setIsOpenNewInvestmentAdmModal(false);
    setIdInvestmentUser(0);
  }

  function handleOpenNewFranchised(idFranchised?: number): void {
    setIsOpenNewFranchised(true);
    setIdFranchised(idFranchised || 0);
  }

  function handleCloseNewFranchised() {
    setIsOpenNewFranchised(false);
    setIdFranchised(0);
  }

  function handleOpenContractModal() {
    setIsOpenContractModal(true);
  }

  function handleCloseContractModal() {
    setIsOpenContractModal(false);
  }

  function handleOpenContractModalDashboard() {
    setIsOpenContractModalDashboard(true);
  }

  function handleCloseContractModalDashboard() {
    setIsOpenContractModalDashboard(false);
  }

  function handleOpenTicketModal() {
    setIsOpenTicketModal(true);
  }

  function handleCloseTicketModal() {
    setIsOpenTicketModal(false);
  }

  return (
    <ReactModalContext.Provider
      value={{
        isOpenNewMessageModal,
        isOpenNewInvestment,
        isOpenWithdrawModal,
        isOpenResetPassword,
        isOpenResetSecurityPassword,
        isOpenListMessagesModal,
        idMessage,
        idConfirmedUser,
        idInvestmentUser,
        idFranchised,
        isOpenConfirmedUserModal,
        isOpenNewInvestmentAdmModal,
        isOpenNewFranchised,
        isOpenContractModal,
        isOpenContractModalDashboard,
        isOpenTicketModal,
        handleOpenNewMessageModal,
        handleCloseNewMessageModal,
        handleOpenNewInvestment,
        handleCloseNewInvestment,
        handleOpenWithdrawModal,
        handleCloseWithdrawModal,
        handleOpenResetPassword,
        handleCloseResetPassword,
        handleOpenListMessagesModal,
        handleCloseListMessagesModal,
        handleIdMessage,
        handleConfirmedUserOpen,
        handleConfirmedUserClose,
        handleOpenResetSecurityPassword,
        handleCloseResetSecurityPassword,
        handleOpenNewInvestmentAdmModal,
        handleCloseNewInvestmentAdmModal,
        handleOpenNewFranchised,
        handleCloseNewFranchised,
        handleCloseContractModal,
        handleOpenContractModal,
        handleOpenContractModalDashboard,
        handleCloseContractModalDashboard,
        handleOpenTicketModal,
        handleCloseTicketModal,
      }}
    >
      {children}
    </ReactModalContext.Provider>
  );
}

export function useReactModal() {
  const context = useContext(ReactModalContext);

  return context;
}
