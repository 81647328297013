import React from 'react';
import {
  Button,
  Flex,
  Stack,
  Image,
  Heading,
  useBreakpointValue,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import personalFinance from '../../assets/background-login.jpg';

import { Input } from '../../components/Input';
import { api } from '../../service/api';

type SigninFormData = {
  password: string;
  password_confirmation: string;
};

const signinFormSchema = yup.object().shape({
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres'),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisam ser iguais'),
});

export function ResetPassword(): JSX.Element {
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(signinFormSchema),
  });

  const { errors } = formState;

  const handleSignin: SubmitHandler<SigninFormData> = async data => {
    try {
      const token = location.search.replace('?token=', '');

      if (!token) {
        throw new Error();
      }

      const { password } = data;

      await api.post('users/password/reset', {
        password,
        token,
      });

      toast({
        title: 'Senha alterada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      history.push('/');
    } catch (err) {
      toast({
        title: 'Erro ao resetar a senha!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex
      height={isWideVersion ? '100vh' : '100%'}
      align={isWideVersion ? 'stretch' : 'center'}
      justify={isWideVersion || isLargerThan1280 ? 'center' : 'center'}
    >
      <Flex
        direction="column"
        bg="#0C6E9C"
        maxW="800px"
        width="100%"
        height="100vh"
        p={12}
        as="form"
        onSubmit={handleSubmit(handleSignin)}
        justifyContent="center"
        alignItems="center"
      >
        <Heading mb={6}>Resetar Senha</Heading>

        <Stack spacing="4" width="100%" maxWidth="500px">
          <Input
            type="password"
            label="Nova senha"
            error={errors.password}
            {...register('password')}
          />

          <Input
            type="password"
            label="Confirme a nova senha"
            error={errors.password_confirmation}
            {...register('password_confirmation')}
          />

          <Button
            colorScheme="teal"
            mt="4"
            width="100%"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Salvar
          </Button>
        </Stack>
      </Flex>

      {isLargerThan1280 && (
        <Image src={personalFinance} flex="1" backgroundSize="cover" />
      )}
    </Flex>
  );
}
