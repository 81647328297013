import React, { useEffect, useMemo } from 'react';
import {
  Flex,
  Box,
  Heading,
  IconButton,
  Button,
  useToast,
  Input,
} from '@chakra-ui/react';
import { FiEdit3, FiSearch, FiTrash } from 'react-icons/fi';

import { useState } from 'react';
import { api } from '../../service/api';
import { useReactModal } from '../../contexts/ReactModal';
import { LoadingSpinner } from '../LoadingSpinner';

interface FranchisedProps {
  id: number;
  name: string;
  price: number;
}

export function Franchiseds(): JSX.Element {
  const [franchises, setFranchises] = useState<FranchisedProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [franchisesName, setFranchisesName] = useState('');

  const { isOpenNewFranchised, handleOpenNewFranchised } = useReactModal();

  const toast = useToast();

  useEffect(() => {
    if (isOpenNewFranchised) return;

    try {
      setIsLoading(true);
      api.get<FranchisedProps[]>('franchises').then(response => {
        setFranchises(response.data);
      });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [isOpenNewFranchised]);

  function handleOpenNewFranchisedModal() {
    handleOpenNewFranchised();
  }

  function handleOpenEditFranchisedModal(idFranchised: number) {
    handleOpenNewFranchised(idFranchised);
  }

  async function handleRemoveFranchised(idFranchised: number) {
    try {
      const result = window.confirm(
        'Tem certeza que gostaria de deletar esse franquia?',
      );

      if (!result) {
        return;
      }

      await api.delete(`franchises/${idFranchised}`);

      setFranchises(state => state.filter(item => item.id !== idFranchised));
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro ao remover a franquia',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? data : '',
        position: 'top-right',
      });
    }
  }

  const franchisesFiltered = useMemo(() => {
    const lowerCase = franchisesName?.toLowerCase().trim();

    return franchises.filter(franchise =>
      franchise.name?.toLowerCase().includes(lowerCase),
    );
  }, [franchisesName, franchises]);

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Box>
      <Box p="10" border="2px" borderRadius={8} borderColor="#0C6E9C">
        <Flex w="100%">
          <Heading mb="4">Franquias</Heading>

          <Button
            ml="auto"
            colorScheme="green"
            onClick={handleOpenNewFranchisedModal}
          >
            Nova franquia
          </Button>
        </Flex>

        {franchises && franchises.length > 0 ? (
          <>
            <Box maxW="30%" mb="10">
              <Input
                name="search"
                icon={<FiSearch color="gray.300" />}
                placeholder="Buscar franquia"
                value={franchisesName}
                onChange={event => setFranchisesName(event.target.value)}
              />
            </Box>

            <Box>
              {franchisesFiltered.map(franchise => (
                <Flex
                  key={franchise.id}
                  alignItems="center"
                  gridGap={4}
                  p="4"
                  borderBottomWidth={2}
                  borderBottomColor="#0C6E9C"
                  w="100%"
                >
                  <IconButton
                    colorScheme="blue"
                    icon={<FiEdit3 />}
                    aria-label="Editar franquia"
                    onClick={() => handleOpenEditFranchisedModal(franchise.id)}
                  />
                  <IconButton
                    colorScheme="red"
                    icon={<FiTrash />}
                    aria-label="Deletar franquia"
                    onClick={() => handleRemoveFranchised(franchise.id)}
                  />
                  <h2>
                    {franchise.name} -{' '}
                    {franchise.price.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </h2>
                </Flex>
              ))}
            </Box>
          </>
        ) : (
          <Heading textAlign="center" marginY={10}>
            Sem franquias cadastradas
          </Heading>
        )}
      </Box>
    </Box>
  );
}
