import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  useBreakpointValue,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Select } from '../Select';

import { Input } from '../Input';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

const bankFormSchema = yup.object().shape({
  bankName: yup.string().nullable(),
  bankAgency: yup.string().nullable(),
  bankAccount: yup.string().nullable(),
  bankAccountType: yup.string().nullable(),
  digitalWallet: yup.string().nullable(),
  pix: yup.string().nullable(),
});

interface BankProps {
  name: string;
  code: string;
}

interface User {
  id: number;
  bankName: string;
  bankAgency: string;
  bankAccount: string;
  bankAccountType: string;
  pix: string;
  digitalWallet: string;
}

export function BoxBank(user: User): JSX.Element {
  const toast = useToast();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(bankFormSchema),
    defaultValues: {
      bankName: user.bankName,
      bankAgency: user.bankAgency,
      bankAccount: user.bankAccount,
      bankAccountType: user.bankAccountType,
      digitalWallet: user.digitalWallet,
      pix: user.pix,
    },
  });

  const { errors } = formState;

  const [isLargerThan2200] = useMediaQuery('(min-width: 2200px)');
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [banks, setBanks] = useState<BankProps[]>();

  useEffect(() => {
    try {
      axios
        .get<BankProps[]>('https://brasilapi.com.br/api/banks/v1')
        .then(response => {
          const banks = response.data
            .filter(bank => bank.code !== null)
            .sort((a, b) => {
              if (a.name < b.name) return -1;

              if (a.name > b.name) return 1;

              return 0;
            });

          setBanks(banks);
        });
    } catch (error) {
      //
    }
  }, []);

  if (!banks) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
        bg="#0C6E9C"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  const handleUpdateBank: SubmitHandler<User> = async data => {
    try {
      await api.put(`users/updatebank/${user.id}`, data);

      toast({
        title: 'Dados bancários salvos com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro na atualização dos dados bancários!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        description: status === 400 || status === 404 ? data : '',
      });
    }
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(handleUpdateBank)}
      align="center"
      justify="space-around"
      flexDir="column"
      gridGap="4"
      rounded="10px"
      color="white"
      flex="1"
      p="4"
      bg="#0C6E9C"
    >
      <Flex gridGap="4" w="100%" flexDir={isWideVersion ? 'row' : 'column'}>
        <Select
          placeholder="Selecione o banco"
          label="Banco"
          error={errors.bankName}
          {...register('bankName')}
        >
          {banks.map(bank => (
            <option style={{ color: '#000' }} key={bank.code} value={bank.name}>
              {bank.code.toString().padStart(3, '0')} - {bank.name}
            </option>
          ))}
        </Select>

        <Input
          label="Conta com o dígito"
          placeholder="Número da conta"
          error={errors.bankAccount}
          {...register('bankAccount')}
        />
      </Flex>

      <Flex gridGap="4" w="100%" flexDir={isWideVersion ? 'row' : 'column'}>
        <Input
          label="Agência"
          placeholder="Número da agência"
          error={errors.bankAgency}
          {...register('bankAgency')}
        />

        <Select
          placeholder="Selecione o tipo"
          label="Tipo de Conta"
          error={errors.bankAccountType}
          {...register('bankAccountType')}
        >
          <option style={{ color: '#000' }} value="corrente">
            Corrente
          </option>
          <option style={{ color: '#000' }} value="poupanca">
            Poupança
          </option>
        </Select>
      </Flex>

      <Flex gridGap="4" w="100%" flexDir={isLargerThan2200 ? 'row' : 'column'}>
        <Input
          label="Pix"
          placeholder="Chave pix onde deseja receber os pagamentos"
          error={errors.pix}
          {...register('pix')}
        />

        <Input
          label="Sua carteira para recebimento: BUSD (Rede BSC - Bep20)"
          placeholder="Número da carteira digital"
          error={errors.digitalWallet}
          {...register('digitalWallet')}
        />
      </Flex>

      <Button
        colorScheme="green"
        w="100%"
        maxW="320px"
        type="submit"
        isLoading={formState.isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  );
}
