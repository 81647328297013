import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
} from '@chakra-ui/react';
import { FiEdit, FiPlusCircle, FiTrash } from 'react-icons/fi';

import { api } from '../../service/api';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { useReactModal } from '../../contexts/ReactModal';
import { LoadingSpinner } from '../../components/LoadingSpinner';

interface MessageProps {
  id: number;
  content: string;
  status: string;
  updatedDate: Date;
}

export function Messages(): JSX.Element {
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { handleOpenNewMessageModal, isOpenNewMessageModal, handleIdMessage } =
    useReactModal();

  useEffect(() => {
    setIsLoading(true);
    try {
      api
        .get<MessageProps[]>('notices')
        .then(response =>
          setMessages(
            response.data.sort((a, b) =>
              a.updatedDate > b.updatedDate ? -1 : 1,
            ),
          ),
        );
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [isOpenNewMessageModal]);

  async function handleDeleteMessage(id: number) {
    try {
      await api.delete(`notices/${id}`);
      setMessages(messages.filter(message => message.id !== id));
    } catch (error) {
      //
    }
  }

  function handleEditMessage(id: number) {
    handleIdMessage(id);
    handleOpenNewMessageModal();
  }

  function handleNewMessage() {
    handleIdMessage(0);
    handleOpenNewMessageModal();
  }

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex w="100%" flexDir="column">
        <Header />

        <Flex flexDir="column" gridGap="4">
          <Box p="10" bg="whiteAlpha" overflowY="auto">
            <Flex justify="space-between">
              <Heading>Avisos</Heading>

              <Button
                onClick={handleNewMessage}
                bg="#0C6E9C"
                leftIcon={<FiPlusCircle />}
              >
                Adicionar
              </Button>
            </Flex>
            <Table bg="#0C6E9C" mt="10px" rounded={10}>
              <Thead>
                <Tr>
                  <Th color="#FFF">Conteúdo</Th>
                  <Th color="#FFF">Status</Th>
                  <Th color="#FFF">Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {messages.map(message => (
                  <Tr key={message.id}>
                    <Td width="70%">{message.content}</Td>
                    <Td width="70%">{message.status}</Td>
                    <Td>
                      <Flex gridGap="10">
                        <Button
                          colorScheme="red"
                          onClick={() => handleDeleteMessage(message.id)}
                        >
                          <FiTrash />
                        </Button>

                        <Button
                          colorScheme="blue"
                          onClick={() => handleEditMessage(message.id)}
                        >
                          <FiEdit />
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
