import React, { useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';

type ResetPasswordFormData = {
  password: string;
  newPassword: string;
  password_confirmation: string;
};

const resetPasswordFormSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres'),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref('newPassword')], 'As senhas precisam ser iguais'),
});

export function ResetSecurityPassword(): JSX.Element {
  const { register, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(resetPasswordFormSchema),
    defaultValues: {
      password: '',
      newPassword: '',
      password_confirmation: '',
    },
  });

  const { user, refreshUser } = useAuth();

  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);

  const { isOpenResetSecurityPassword, handleCloseResetSecurityPassword } =
    useReactModal();

  const toast = useToast();

  const { errors } = formState;

  const handleNewWithdraw: SubmitHandler<ResetPasswordFormData> =
    async values => {
      try {
        const { password, newPassword } = values;

        if (user.hasSecurityPass && password === '') {
          setError('password', {
            message: 'Senha antiga obrigatória',
          });

          return;
        }

        const request = {
          user,
          password,
          newPassword,
        };

        await api.put('users/passwordSecurity/new', request);

        toast({
          title: 'Senha de segurança alterada com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });

        refreshUser();

        handleCloseResetSecurityPassword();
      } catch (error) {
        const { data, status } = error.response;
        toast({
          title: 'Erro ao resetar sua senha de segurança, tente novamente!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
          description: status === 400 ? data : '',
        });
      }
    };

  async function handleSendEmailPassword() {
    try {
      setIsLoadingSendEmail(true);
      await api.put(`email/password/send/${user.id}`);

      toast({
        title: 'Senha enviada para seu email!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setIsLoadingSendEmail(false);

      handleCloseResetSecurityPassword();
    } catch (error) {
      const { data, status } = error.response;
      toast({
        title: 'Erro ao resetar sua senha de segurança, tente novamente!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        description: status === 400 ? data : '',
      });

      setIsLoadingSendEmail(false);
    }
  }

  return (
    <Modal
      isOpen={isOpenResetSecurityPassword}
      onClose={handleCloseResetSecurityPassword}
    >
      <ModalOverlay />

      <ModalContent bg="gray.800" p="4">
        <ModalHeader>Resetar senha de segurança</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex
            as="form"
            onSubmit={handleSubmit(handleNewWithdraw)}
            flexDir="column"
            gridGap="2"
          >
            {user && user.hasSecurityPass && (
              <Input
                label="Senha antiga"
                type="password"
                error={errors.password}
                {...register('password')}
              />
            )}

            <Input
              label="Nova senha"
              type="password"
              error={errors.newPassword}
              {...register('newPassword')}
            />

            <Input
              label="Confirme a nova senha"
              type="password"
              error={errors.password_confirmation}
              {...register('password_confirmation')}
            />

            <Button
              onClick={handleSendEmailPassword}
              variant="link"
              mt="2"
              alignSelf="self-start"
              isLoading={isLoadingSendEmail}
            >
              Esqueceu a senha? Clique aqui!
            </Button>

            <Flex gridGap="4" mt="4" alignSelf="flex-end">
              <Button
                type="button"
                colorScheme="whiteAlpha"
                onClick={handleCloseResetSecurityPassword}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="pink"
                isLoading={formState.isSubmitting}
              >
                Resetar senha
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
