import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  CheckboxProps as ChakraCheckboxProps,
  Checkbox as ChakraCheckbox,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

interface CheckboxProps extends ChakraCheckboxProps {
  name: string;
  label?: string;
  disabled?: boolean;
  error?: FieldError;
  children: ReactNode;
}

const CheckboxBase: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> =
  ({ name, label, disabled = false, error = null, ...rest }, ref) => {
    return (
      <FormControl isInvalid={!!error}>
        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <ChakraCheckbox
          name={name}
          id={name}
          disabled={disabled}
          ref={ref}
          {...rest}
        />

        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    );
  };

export const Checkbox = forwardRef(CheckboxBase);
