import React, { useState } from 'react';
import {
  Box,
  Flex,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { UsersManagement } from '../../components/Management/Users';
import { GeneralSettings } from '../../components/Management/GeneralSettings';
import { FranchisedsByUser } from '../../components/ManagementFranchised/FranchisedsByUser';
import { LoadingSpinner } from '../../components/LoadingSpinner';

export function Management(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  function handleIsLoading(isLoadingParam: boolean) {
    setIsLoading(isLoadingParam);
  }

  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />
      <Flex flexDir="column" w="100%">
        <Header />

        {isLoading ? (
          <Flex
            align="center"
            justify="space-around"
            flexDir="column"
            gridGap="4"
            rounded="10px"
            color="white"
            flex="1"
            p="4"
          >
            <LoadingSpinner />
          </Flex>
        ) : (
          <Box h="100%" p="4">
            <Tabs isFitted variant="soft-rounded" colorScheme="gray">
              <TabList>
                <Tab color="#fff" bg="#0C6E9C" mr="8px">
                  Gerenciar Usuários
                </Tab>
                <Tab color="#fff" bg="#0C6E9C" mr="8px">
                  Gereciar Franquias
                </Tab>
                <Tab color="#fff" bg="#0C6E9C">
                  Configurações Gerais
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <UsersManagement setIsLoading={handleIsLoading} />
                </TabPanel>
                <TabPanel>
                  <FranchisedsByUser setIsLoading={handleIsLoading} />
                </TabPanel>
                <TabPanel>
                  <GeneralSettings setIsLoading={handleIsLoading} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
