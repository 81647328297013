import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Flex,
  Text,
  Link,
  Box,
  Button,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';

import { Input } from './Input';
import { useAuth } from '../hooks/auth';
import { api } from '../service/api';
import { convertCurrencyBrazil } from '../utils/convertData';
import { LoadingSpinner } from './LoadingSpinner';

interface Franchise {
  id: number;
  name: string;
  price: number;
}

interface Franchised {
  id: number;
  franchise: Franchise;
  status: string;
}

interface UserFranchised {
  franchises: Franchised[];
}

const MAX_SIZE_FILE = 2097152; // 2mb

export function WarningWindow(): JSX.Element {
  const toast = useToast();
  const { user, refreshUser, validToken } = useAuth();
  const [textMessage, setTextMessage] = useState('');
  const [selectedFranchised, setSelectedFranchised] = useState<UserFranchised>(
    {} as UserFranchised,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    if (user.id === 0) return;

    setIsLoading(true);

    try {
      api
        .get(`franchises/user/user/${user.id}`, {
          headers: { Authorization: `Bearer ${validToken()}` },
        })
        .then(response => setSelectedFranchised(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [user, validToken]);

  useEffect(() => {
    switch (user.activeStatus) {
      case 0:
        setTextMessage('Seu usuário está desabilitado.');
        break;
      case 3:
        setTextMessage('Envio de contrato pendente.');
        break;
      case 4:
        setTextMessage(
          user.role === 2 &&
            user.activeStatus === 4 &&
            selectedFranchised.franchises &&
            selectedFranchised.franchises.length > 0
            ? 'Seus dados foram atualizados, aguarde a ativação da conta.'
            : 'Aguarde enquanto estamos analisando seus dados.',
        );
        break;
      default:
        setTextMessage('Cadastro pendente');
        break;
    }
  }, [selectedFranchised.franchises, user.activeStatus, user.role]);

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex minW="100%" h="80vh" justify="center" align="center">
      <Flex
        flexDir="column"
        align="center"
        justify="center"
        backgroundColor="gray.700"
        maxW="1000px"
        p="10"
        rounded={10}
        gridGap="10"
      >
        <WarningIcon w={20} h={20} color="yellow.500" />

        {user.activeStatus === 2 ? (
          <Text fontSize="30" textAlign="center">
            Cadastro incompleto,{' '}
            <Link color="blue.600" href="/update-user">
              clique aqui
            </Link>
            , para completa-lo
          </Text>
        ) : (
          <Text fontSize="30" textAlign="center">
            {textMessage}
          </Text>
        )}

        {user.role === 2 && !user.isMigration && selectedFranchised.franchises && (
          <Flex align="center" flexDir="column" gridGap={5}>
            <Flex justifyContent="center" alignItems="center" gridGap={5}>
              <Heading fontSize="large">Valor da Franquia:</Heading>
              <Text fontSize="x-large" fontWeight="bold">
                ${' '}
                {convertCurrencyBrazil(
                  selectedFranchised.franchises[0].franchise.price,
                )}
              </Text>
            </Flex>
          </Flex>
        )}

        {user.notes && (
          <Box>
            <Text fontSize="xl" color="yellow.300">
              Seu cadastro foi negado pelo seguinte motivo:
            </Text>
            <Text textAlign="center" fontSize="2xl">
              {user.notes}
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}
