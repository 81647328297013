import {
  Button,
  Flex,
  Heading,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { ChangeEvent, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Header } from '../../components/Header';
import { Input } from '../../components/Input';
import { Sidebar } from '../../components/Sidebar';
import { Textarea } from '../../components/Textarea';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';

interface SupportFormData {
  name: string;
  email: string;
  whatsApp: string;
  message: string;
  file: File;
}

const supportFormDataSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  whatsApp: yup
    .string()
    .required('Telefone celular obrigatório')
    .length(15)
    .nullable(),
  message: yup.string().required('Mensagem é obrigatório').nullable(),
});

const MAX_SIZE_FILE = 2097152; // 2mb

export function Support(): JSX.Element {
  const refInputFile = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>({} as File);

  const { user } = useAuth();
  const toast = useToast();
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(supportFormDataSchema),
    defaultValues: {
      name: user.name,
      email: user.email,
      whatsApp: user.phoneNumber,
      message: '',
    },
  });

  const { errors } = formState;

  function resetFile() {
    if (refInputFile && refInputFile.current) refInputFile.current.value = '';
  }

  const handleSupportMessage: SubmitHandler<SupportFormData> = async data => {
    try {
      const formData = new FormData();

      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append('whatsApp', data.whatsApp);
      formData.append('message', data.message);
      formData.append('file', file);

      await api.post('email/support', formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });

      toast({
        title: 'Mensagem enviada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setValue('message', '');
      setFile({} as File);
      resetFile();
    } catch (err) {
      toast({
        title: 'Erro ao enviar a mensagem, tente novamente!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  function handleFile(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0].size > MAX_SIZE_FILE) {
      e.target.value = '';
      alert('O comprovante tem que ter tamanho máximo de 2MB.');
      return;
    }

    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  return (
    <Flex h={isWideVersion ? '100vh' : '100%'} bg="#C9C9C9">
      <Sidebar />

      <Flex flexDir="column" w="100%">
        <Header />

        <Flex
          w="100%"
          alignItems="stretch"
          gridGap={2}
          margin="auto auto"
          flexDir={isWideVersion ? 'row' : 'column'}
          justifyContent="center"
        >
          <Flex
            as="form"
            onSubmit={handleSubmit(handleSupportMessage)}
            bg="gray.800"
            p="10"
            w="100%"
            maxW="900px"
            rounded={10}
            flexDir="column"
            gridGap={4}
          >
            <Heading>Envie uma mensagem</Heading>

            <Input
              label="Nome completo"
              error={errors.name}
              isReadOnly
              {...register('name')}
            />

            <Flex gridGap={4}>
              <Input
                label="E-mail"
                error={errors.email}
                type="email"
                isReadOnly
                {...register('email')}
              />

              <Input
                mask="mobile"
                label="Número Whatsapp"
                isReadOnly
                error={errors.whatsApp}
                {...register('whatsApp')}
              />
            </Flex>

            <Textarea
              label="Mensagem"
              error={errors.message}
              {...register('message')}
            />

            <Input
              ref={refInputFile}
              label="Arquivo"
              name="file"
              variant="flushed"
              padding="2"
              id="file"
              type="file"
              width="100%"
              onChange={handleFile}
            />

            <Button
              mt="4"
              type="submit"
              isLoading={formState.isSubmitting}
              bg="#0C6E9C"
              alignSelf="center"
              w="28"
            >
              Enviar
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
