import React, { KeyboardEvent, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  Divider,
  useDisclosure,
  Button,
  Collapse,
  useMediaQuery,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { addDays, format, setMinutes, setSeconds } from 'date-fns';
import NumberFormat from 'react-number-format';

import 'react-datepicker/dist/react-datepicker.css';

import { useEffect } from 'react';
import { useRef } from 'react';
import { setHours } from 'date-fns/esm';
import { Input } from '../Input';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

interface User {
  id: number;
  name: string;
  username: string;
  email: string;
  birthDate: Date;
  country: string;
  gender: string;
  cep: string;
  city: string;
  state: string;
}
interface Extract {
  id: number;
  type: string;
  user: User;
  description: string;
  date: Date;
  amount: number;
}
interface ReportsData {
  cashIn: number;
  cashOut: number;
  incomePaid: number;
  indicationPaid: number;
  extractAlls: Extract[];
}

export function GeneralReport(): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const [reports, setReports] = useState<ReportsData>({} as ReportsData);
  const [initialDate, setInitialDate] = useState(addDays(new Date(), -7));
  const [finalDate, setFinalDate] = useState(new Date());
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onToggle } = useDisclosure();

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  useEffect(() => {
    setIsLoading(true);
    try {
      api
        .post('admin/report', {
          username: userName,
          initialDate,
          finalDate,
        })
        .then(response => {
          setReports(response.data);
        });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  function handleInitialDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setInitialDate(date);
  }

  function handleFinalDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setFinalDate(date);
  }

  async function handleSearchReport() {
    setIsLoading(true);

    const realInitialDate = setSeconds(
      setMinutes(setHours(initialDate, -3), 0),
      0,
    );

    const realFinalDate = setSeconds(
      setMinutes(setHours(finalDate, 20), 59),
      59,
    );

    try {
      const response = await api.post('admin/report', {
        username: userName,
        initialDate: realInitialDate,
        finalDate: realFinalDate,
      });

      setReports(response.data);
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }

  async function handleClearSearch() {
    setIsLoading(true);
    try {
      setInitialDate(addDays(new Date(), -7));
      setFinalDate(addDays(new Date(), 1));
      setUserName('');

      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }

      const response = await api.post('admin/report', {
        username: '',
        initialDate,
        finalDate,
      });

      setReports(response.data);
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }

  async function handleKeyPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const { nativeEvent } = event;
    if (nativeEvent.key === 'Enter') {
      await handleSearchReport();
    }
  }

  return (
    <>
      <Flex flexDir="column" gridGap="4">
        <Box p="2" bg="whiteAlpha" overflowY="auto">
          {isWideVersion && isLargerThan1368 ? (
            <Flex
              justify="space-between"
              flexDir="column"
              alignItems="flex-start"
              p="4"
              bg="gray.800"
            >
              <Flex gridGap="4" w="100%" align="center" justify="space-between">
                <Box w="700px">
                  <Input
                    ref={inputRef}
                    name="user"
                    label="Usuário"
                    placeholder="Nome do usuário"
                    onChange={event => setUserName(event.target.value)}
                    onKeyPress={handleKeyPressEnter}
                  />
                </Box>

                <Flex mr="auto" gridGap={4}>
                  <DatePicker
                    selected={initialDate}
                    dateFormat="dd/MM/yyyy"
                    maxDate={finalDate}
                    onChange={date => handleInitialDate(date)}
                    customInput={<Input name="from" label="De" />}
                    locale={ptBR}
                  />
                  <DatePicker
                    selected={finalDate}
                    dateFormat="dd/MM/yyyy"
                    minDate={initialDate}
                    onChange={date => handleFinalDate(date)}
                    customInput={<Input name="to" label="Até" />}
                    locale={ptBR}
                  />
                </Flex>

                <Flex gridGap={4} alignSelf="flex-end">
                  <Button
                    onClick={handleSearchReport}
                    type="button"
                    colorScheme="teal"
                    isLoading={isLoading}
                  >
                    Pesquisar
                  </Button>

                  <Button
                    onClick={handleClearSearch}
                    type="button"
                    colorScheme="whiteAlpha"
                    variant="outline"
                    isLoading={isLoading}
                  >
                    Limpar filtros
                  </Button>
                </Flex>
              </Flex>

              <Divider my="4" />

              <Flex alignItems="center" justify="space-evenly" w="100%">
                <Flex align="center" gridGap="4">
                  <Text fontSize="lg" fontWeight="bold">
                    Depósito
                  </Text>
                  <Box p="2" bg="yellow.600" rounded={10}>
                    <Text fontSize="lg" fontWeight="bold">
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={reports.cashIn}
                        decimalScale={2}
                        prefix="$ "
                      />
                    </Text>
                  </Box>
                </Flex>

                <Flex align="center" gridGap="4">
                  <Text fontSize="lg" fontWeight="bold">
                    Saída
                  </Text>
                  <Box p="2" bg="red.500" rounded={10}>
                    <Text fontSize="lg" fontWeight="bold">
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={reports.cashOut}
                        decimalScale={2}
                        prefix="$ "
                      />
                    </Text>
                  </Box>
                </Flex>

                <Flex align="center" gridGap="4">
                  <Text fontSize="lg" fontWeight="bold">
                    Rendimento
                  </Text>
                  <Box p="2" bg="red.800" rounded={10}>
                    <Text fontSize="lg" fontWeight="bold">
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={reports.incomePaid}
                        decimalScale={2}
                        prefix="$ "
                      />
                    </Text>
                  </Box>
                </Flex>

                <Flex align="center" gridGap="4">
                  <Text fontSize="lg" fontWeight="bold">
                    Indicação
                  </Text>
                  <Box p="2" bg="red.800" rounded={10}>
                    <Text fontSize="lg" fontWeight="bold">
                      <NumberFormat
                        thousandSeparator="."
                        decimalSeparator=","
                        displayType="text"
                        value={reports.indicationPaid}
                        decimalScale={2}
                        prefix="$ "
                      />
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          ) : (
            <>
              <Button colorScheme="teal" w="100%" onClick={onToggle}>
                Filtros
              </Button>

              <Collapse animateOpacity in={isOpen}>
                <Flex
                  justify="space-between"
                  flexDir="column"
                  alignItems="flex-start"
                  p="4"
                  bg="gray.800"
                >
                  <Heading>Filtros</Heading>

                  <Flex gridGap="4" w="100%" flexDir="column" align="center">
                    <Flex w="100%" gridGap="4">
                      <Input
                        name="user"
                        label="Usuário"
                        placeholder="Nome do usuário"
                        maxW="700px"
                        onChange={event => setUserName(event.target.value)}
                      />

                      <DatePicker
                        selected={initialDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => handleInitialDate(date)}
                        customInput={<Input name="from" label="De" />}
                        locale={ptBR}
                      />
                      <DatePicker
                        selected={finalDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={date => handleFinalDate(date)}
                        customInput={<Input name="to" label="Até" />}
                        locale={ptBR}
                      />
                    </Flex>

                    <Flex gridGap="4" justify="flex-start" w="100%">
                      <Button
                        onClick={handleSearchReport}
                        type="button"
                        colorScheme="teal"
                        isLoading={isLoading}
                      >
                        Pesquisar
                      </Button>

                      <Button
                        onClick={handleClearSearch}
                        type="button"
                        colorScheme="whiteAlpha"
                        variant="outline"
                        isLoading={isLoading}
                      >
                        Limpar filtros
                      </Button>
                    </Flex>
                  </Flex>

                  <Divider my="4" />

                  <Flex
                    alignItems="flex-start"
                    flexDir="column"
                    w="100%"
                    gridGap="4"
                  >
                    <Flex align="center" justify="space-around" w="100%">
                      <Text fontSize="lg" fontWeight="bold" flex="1">
                        Depósito
                      </Text>
                      <Box p="2" bg="yellow.600" rounded={10}>
                        <Text fontSize="lg" fontWeight="bold">
                          <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            value={reports.cashIn}
                            decimalScale={2}
                            prefix="$ "
                          />
                        </Text>
                      </Box>
                    </Flex>

                    <Flex align="center" justify="space-around" w="100%">
                      <Text fontSize="lg" fontWeight="bold" flex="1">
                        Saída
                      </Text>
                      <Box p="2" bg="red.500" rounded={10}>
                        <Text fontSize="lg" fontWeight="bold">
                          <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            value={reports.cashOut}
                            decimalScale={2}
                            prefix="$ "
                          />
                        </Text>
                      </Box>
                    </Flex>

                    <Flex align="center" justify="space-around" w="100%">
                      <Text fontSize="lg" fontWeight="bold" flex="1">
                        Rendimento
                      </Text>
                      <Box p="2" bg="red.800" rounded={10}>
                        <Text fontSize="lg" fontWeight="bold">
                          <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            value={reports.incomePaid}
                            decimalScale={2}
                            prefix="$ "
                          />
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Collapse>
            </>
          )}

          {isLoading ? (
            <Flex align="center" width="100%" height="100%" justify="center">
              <LoadingSpinner />
            </Flex>
          ) : (
            <Box
              maxH="480px"
              mt="2"
              overflowY="auto"
              sx={{
                '::-webkit-scrollbar': {
                  width: '4px',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: '#666',
                  borderRadius: '4px',
                },
                '::-webkit-scrollbar-track': {
                  backgroundColor: '#2f3136',
                },
              }}
            >
              {reports.extractAlls && (
                <Table bg="#0C6E9C" mt="10px" rounded={10}>
                  <Thead>
                    <Tr>
                      <Th color="#FFF">Nome</Th>
                      <Th color="#FFF">Usuário</Th>
                      <Th color="#FFF">Descrição</Th>
                      <Th color="#FFF">Data</Th>
                      <Th color="#FFF">Valor</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reports.extractAlls.map(extract => (
                      <Tr key={extract.id}>
                        <Td>{extract.user?.name}</Td>
                        <Td>{extract.user?.username}</Td>
                        <Td>{extract.description}</Td>
                        <Td>
                          {String(extract.date) === '1-0001-01-01T00:00:00'
                            ? ''
                            : format(new Date(extract.date), 'dd/MM/yyyy')}
                        </Td>
                        <Td
                          color={extract.amount >= 0 ? 'green.200' : 'red.200'}
                        >
                          <NumberFormat
                            thousandSeparator="."
                            decimalSeparator=","
                            displayType="text"
                            value={extract.amount}
                            decimalScale={2}
                            prefix="$"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
}
