import { Flex, Box, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useAuth, User } from '../../hooks/auth';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

export function MyReferrals(): JSX.Element {
  const { user } = useAuth();

  const [myNominees, setMyNominees] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      api
        .get(`users/user/${user.id}`)
        .then(response => setMyNominees(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [user.id]);

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex maxW="1980px">
      <Box flex="1" h="100%" p="4">
        <Box overflowX="auto" maxW="100vw" rounded={8}>
          <Table bg="#0C6E9C">
            <Thead>
              <Tr>
                <Th color="#FFF">Nome</Th>
                <Th color="#FFF">E-mail</Th>
                <Th color="#FFF">Telefone</Th>
                <Th color="#FFF">Situação</Th>
              </Tr>
            </Thead>
            <Tbody>
              {myNominees.map(nominees => (
                <Tr key={nominees.id}>
                  <Td>{nominees.name}</Td>
                  <Td>{nominees.email}</Td>
                  <Td>{nominees.phoneNumber}</Td>
                  <Td>{nominees.activeStatus === 1 ? 'Ativo' : 'Inativo'}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Flex>
  );
}
