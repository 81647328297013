import React from 'react';
import { EmailIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Stack,
  Image,
  Heading,
  useBreakpointValue,
  Link,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';

import personalFinance from '../../assets/background-login.jpg';

import { Input } from '../../components/Input';
import { api } from '../../service/api';

type ForgotPasswordData = {
  email: string;
};

const forgotPasswordFormSchema = yup.object().shape({
  email: yup.string().required().email('E-mail obrigatório!'),
});

export function ForgotPassword(): JSX.Element {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(forgotPasswordFormSchema),
  });

  const { errors } = formState;

  const toast = useToast();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const handleSendEmail: SubmitHandler<ForgotPasswordData> = async data => {
    try {
      await api.post(`email/send?email=${data.email}`);

      toast({
        title: 'E-mail de recuperação da senha enviado com sucesso!',
        description:
          'Verifique a caixa de entrada ou o lixo eletrônico do seu e-mail.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch {
      toast({
        title: 'Erro ao enviar o e-mail de recuperação da senha!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <Flex height="100vh" align="stretch">
      <Flex
        direction="column"
        bg="#0C6E9C"
        maxW="800px"
        width="100%"
        p={12}
        borderLeftRadius={6}
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          as="form"
          onSubmit={handleSubmit(handleSendEmail)}
          width="100%"
          maxWidth="500px"
          flexDir="column"
        >
          <Link mb={1} color="teal.600" alignSelf="start" href="/">
            Voltar para o login
          </Link>

          <Heading mb={6}>Recuperação de senha</Heading>

          <Stack spacing="4" width="100%" maxWidth="500px">
            <Input
              type="email"
              icon={<EmailIcon />}
              label="Digite seu e-mail"
              fontSize={['sm', 'md']}
              error={errors.email}
              {...register('email')}
            />
          </Stack>

          <Button
            type="submit"
            mt="4"
            colorScheme="teal"
            isLoading={formState.isSubmitting}
          >
            Enviar
          </Button>
        </Flex>
      </Flex>

      {isWideVersion && (
        <Image src={personalFinance} flex="1" backgroundSize="cover" />
      )}
    </Flex>
  );
}
