import React from 'react';
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react';

import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { Invoices } from '../../components/FinancialAdm/Invoices';
import { Withdrawals } from '../../components/FinancialAdm/Withdrawals';

export function FinancialAdm(): JSX.Element {
  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex w="100%" h="100%" flexDir="column">
        <Header />

        <Box p="4">
          <Tabs isFitted variant="soft-rounded" colorScheme="gray">
            <TabList>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Depósitos
              </Tab>
              <Tab color="#fff" bg="#0C6E9C">
                Saques
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Invoices />
              </TabPanel>
              <TabPanel>
                <Withdrawals />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Flex>
  );
}
