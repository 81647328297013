import React from 'react';
import {
  Box,
  Flex,
  useBreakpointValue,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { GeneralReport } from '../../components/Reports/GeneralReport';
import { IncomeReport } from '../../components/Reports/IncomeReport';
import { WithdrawByUser } from '../../components/Reports/WithdrawByUser';

export function Reports(): JSX.Element {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex h={isWideVersion ? '100vh' : '100%'} bg="#C9C9C9">
      <Sidebar />
      <Flex flexDir="column" w="100%">
        <Header />

        <Box h="100%" p="4">
          <Tabs isFitted variant="soft-rounded" colorScheme="gray">
            <TabList>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Geral
              </Tab>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Rendimentos
              </Tab>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Próximo saque máximo
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <GeneralReport />
              </TabPanel>
              <TabPanel>
                <IncomeReport />
              </TabPanel>
              <TabPanel>
                <WithdrawByUser />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Flex>
  );
}
