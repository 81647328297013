import React, { useState } from 'react';
import {
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalOverlay,
  Box,
  Link,
  Divider,
} from '@chakra-ui/react';

import { DownloadIcon } from '@chakra-ui/icons';
import { useReactModal } from '../../contexts/ReactModal';
import { useAuth } from '../../hooks/auth';
import { LoadingSpinner } from '../LoadingSpinner';

import contrato from '../../assets/contrato.pdf';
import apolice from '../../assets/apolice.pdf';

export function ContractModalDashboard(): JSX.Element {
  const { user, validToken, refreshUser } = useAuth();
  const { isOpenContractModalDashboard, handleCloseContractModalDashboard } =
    useReactModal();

  const [isLoading, setIsloading] = useState(false);

  async function handleConfirmationContract() {
    setIsloading(true);

    let token;

    while (!token) {
      token = validToken();
    }

    const api = `${process.env.REACT_APP_API_URL}/users/contractconfirmation/${user.id}`;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    await fetch(api, requestOptions);

    await refreshUser();

    setIsloading(false);

    handleCloseContractModalDashboard();
  }

  const ModalOverlayCuston = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="40%"
      backdropBlur="8px"
    />
  );

  return (
    <Modal
      isOpen={isOpenContractModalDashboard}
      onClose={() => {}}
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlayCuston />
      <ModalContent bg="gray.800">
        <ModalBody>
          {!user ? (
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          ) : (
            <Flex flexDir="column">
              <ModalHeader>Contrato</ModalHeader>
              <Box px="10">
                <Link href={contrato} download>
                  Termos de Uso
                  <DownloadIcon mx="2px" />
                </Link>

                <Divider mt={2} />
              </Box>

              <ModalHeader>Apólice</ModalHeader>
              <Box px="10">
                <Link href={apolice} download>
                  Seguro
                  <DownloadIcon mx="2px" />
                </Link>

                <Divider mt={2} />
              </Box>

              <Button
                colorScheme="green"
                mt="4"
                mb="4"
                ml="auto"
                onClick={handleConfirmationContract}
                isLoading={isLoading}
              >
                Li e aceito os termos
              </Button>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
