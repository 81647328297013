import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  Flex,
  Box,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
  Table,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Button,
  Text,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';

import { useState } from 'react';
import { api } from '../../service/api';
import { Input } from '../Input';

interface Franchises {
  id: number;
  franchise: {
    id: number;
    name: string;
    price: number;
  };
  status: string;
  proofPayment: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
}

interface User {
  id: number;
  name: string;
}

interface UserFranchiseds {
  user: User;
  franchises: Franchises[];
}

interface Props {
  setIsLoading: (isLoading: boolean) => void;
}

export function FranchisedsByUser({ setIsLoading }: Props): JSX.Element {
  const [userFranchiseds, setUserFranchiseds] = useState<UserFranchiseds[]>([]);
  const [userName, setUserName] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const indexOfLastItem = currentPage * 7;
  const indexOfFirstItem = indexOfLastItem - 7;

  useEffect(() => {
    try {
      setIsLoading(true);
      api.get<UserFranchiseds[]>('franchises/user').then(response => {
        setUserFranchiseds(response.data);
      });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextbtn = useCallback(() => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + 7);
      setMinPageNumberLimit(minPageNumberLimit + 7);
    }
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit]);

  const handlePrevbtn = useCallback(() => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % 7 === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - 7);
      setMinPageNumberLimit(minPageNumberLimit - 7);
    }
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit]);

  const usersFiltered = useMemo(() => {
    const lowerCase = userName?.toLowerCase();

    if (!userFranchiseds) return [];

    setCurrentPage(1);
    setMinPageNumberLimit(0);
    setMaxPageNumberLimit(7);

    const returnUserFranchiseds = userFranchiseds.filter(user =>
      user.user.name?.toLowerCase().includes(lowerCase),
    );

    return returnUserFranchiseds;
  }, [userName, userFranchiseds]);

  const userFranchisedsPagination = useMemo(() => {
    return usersFiltered.slice(indexOfFirstItem, indexOfLastItem);
  }, [indexOfFirstItem, indexOfLastItem, usersFiltered]);

  const pages = useMemo(() => {
    const pages = [] as number[];

    if (!usersFiltered || usersFiltered.length <= 1) return pages;

    const sizeList = Math.ceil(usersFiltered.length / 7);
    for (let i = 1; i <= sizeList; i++) {
      pages.push(i);
    }

    return pages;
  }, [usersFiltered]);

  const listCurrentPage = useMemo(() => {
    let listCurrentPage = currentPage * 7;

    if (listCurrentPage >= usersFiltered.length) {
      listCurrentPage = usersFiltered.length;
    }

    return listCurrentPage;
  }, [currentPage, usersFiltered.length]);

  const renderPageNumbers: ReactElement[] = useMemo(() => {
    const buttonsPagination = [] as ReactElement[];
    pages.forEach(number => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        const button = (
          <Button
            key={number}
            colorScheme={number === currentPage ? 'facebook' : 'twitter'}
            onClick={() => setCurrentPage(number)}
            color="#FFF"
          >
            {number}
          </Button>
        );

        buttonsPagination.push(button);
      }
    });

    return buttonsPagination;
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit, pages]);

  const showButtonsPagination = useMemo(() => {
    return renderPageNumbers.length > 1;
  }, [renderPageNumbers]);

  return (
    <Box>
      <Box p="10" border="2px" borderRadius={8} borderColor="#0C6E9C">
        <Flex w="100%">
          <Heading mb="4">Franquias por cliente</Heading>
        </Flex>

        {userFranchiseds && userFranchiseds.length > 0 ? (
          <Box>
            <Box maxW="30%" mb="10">
              <Input
                name="search"
                icon={<FiSearch color="gray.300" />}
                placeholder="Buscar investidor"
                value={userName}
                onChange={event => setUserName(event.target.value)}
              />
            </Box>

            <Accordion allowToggle overflowY="auto">
              <Flex w="100%" flexDir="column">
                {userFranchisedsPagination.map(user => (
                  <AccordionItem key={user.user.id} w="100%">
                    <h2>
                      <AccordionButton>
                        <Flex
                          textAlign="left"
                          mr="auto"
                          w="30%"
                          alignItems="center"
                        >
                          {user.user.name}
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      {user.franchises &&
                        user.franchises.map(franchise => (
                          <Table
                            maxW={360}
                            bg="#0C6E9C"
                            key={franchise.franchise.id}
                          >
                            <Thead>
                              <Tr>
                                <Th color="#fff">Franquia</Th>
                                <Th color="#fff">Status</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr key={franchise.id}>
                                <Td>{franchise.franchise.name}</Td>
                                <Td>{franchise.status}</Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        ))}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
                {showButtonsPagination && (
                  <Flex alignItems="center" justifyContent="space-between">
                    <Box>
                      <Text fontSize="large">
                        {listCurrentPage} de{' '}
                        {userFranchisedsPagination.length * pages.length}
                      </Text>
                    </Box>

                    <Flex
                      gridGap={3}
                      overflowY="auto"
                      mt="5"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        colorScheme="twitter"
                        onClick={handlePrevbtn}
                        disabled={currentPage === pages[0]}
                        color="#FFF"
                      >
                        Anterior
                      </Button>

                      {renderPageNumbers}

                      <Button
                        colorScheme="twitter"
                        onClick={handleNextbtn}
                        disabled={currentPage === pages[pages.length - 1]}
                        color="#FFF"
                      >
                        Próximo
                      </Button>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Accordion>
          </Box>
        ) : (
          <Heading textAlign="center" marginY={10}>
            Cliente sem cadastro nas franquias
          </Heading>
        )}
      </Box>
    </Box>
  );
}
