import React from 'react';
import {
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  Box,
  Link,
  ModalCloseButton,
  ModalFooter,
  Button,
} from '@chakra-ui/react';

import { DownloadIcon } from '@chakra-ui/icons';
import { useReactModal } from '../../contexts/ReactModal';

import contrato from '../../assets/contrato.pdf';
import apolice from '../../assets/apolice.pdf';

export function ContractModal(): JSX.Element {
  const { isOpenContractModal, handleCloseContractModal } = useReactModal();

  const ModalOverlayCuston = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="40%"
      backdropBlur="8px"
    />
  );

  return (
    <Modal
      isOpen={isOpenContractModal}
      onClose={handleCloseContractModal}
      size="2xl"
      scrollBehavior="inside"
    >
      <ModalOverlayCuston />
      <ModalContent bg="gray.800">
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDir="column" pb="4">
            <ModalHeader>Contrato</ModalHeader>
            <Box px="10">
              <Link href={contrato} download>
                Termos de Uso
                <DownloadIcon mx="2px" />
              </Link>
            </Box>

            <ModalHeader>Apólice</ModalHeader>
            <Box px="10">
              <Link href={apolice} download>
                Seguro
                <DownloadIcon mx="2px" />
              </Link>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleCloseContractModal}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
