import React, { useState } from 'react';
import { Flex, Text, Divider, Button } from '@chakra-ui/react';
import { FiCopy } from 'react-icons/fi';
import { GridItemOverview } from './GridItemOverview';
import { useAuth } from '../../hooks/auth';

interface OverviewData {
  title: string;
  value: number;
  isShow?: boolean;
  symbol: '$' | 'R$' | '%';
}
interface OverviewProps {
  overview1?: OverviewData;
  overview2?: OverviewData;
  overview3?: OverviewData;
  overview4?: OverviewData;
}

export function Overview({
  overview1 = { title: '', value: 0, isShow: true, symbol: '$' },
  overview2 = { title: '', value: 0, isShow: true, symbol: '$' },
  overview3 = { title: '', value: 0, isShow: true, symbol: '$' },
  overview4 = { title: '', value: 0, isShow: true, symbol: '$' },
}: OverviewProps): JSX.Element {
  const { user } = useAuth();

  const [textLink, setTextLink] = useState('Copiar link de indicação');

  async function handleCopyLink() {
    navigator.clipboard.writeText(
      `${window.location.origin}/signup/${user.username}`,
    );
    setTextLink('Link copiado');

    await new Promise(resolve => setTimeout(resolve, 1000));

    setTextLink('Copiar link de indicação');
  }

  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      bg="#0C6E9C"
      rounded={10}
      paddingX="3"
      flex="1"
      width="100%"
    >
      <Text as="h2" fontWeight="bold" fontSize="24">
        Visão Geral
      </Text>

      <Divider />

      <Flex flexDir="column">
        {overview1.isShow && (
          <GridItemOverview
            title={overview1.title}
            value={overview1.value}
            symbol={overview1.symbol}
            label={overview1.value}
          />
        )}

        {overview2.isShow && (
          <GridItemOverview
            title={overview2.title}
            value={overview2.value}
            symbol={overview2.symbol}
            label={overview2.value}
          />
        )}

        {overview3.isShow && (
          <GridItemOverview
            title={overview3.title}
            value={overview3.value}
            symbol={overview3.symbol}
            label={overview3.value}
          />
        )}

        {overview4.isShow && (
          <GridItemOverview
            title={overview4.title}
            value={(overview4.value / 100) * 100}
            symbol="%"
            label={String(overview4.value)}
          />
        )}
      </Flex>

      {user.role === 2 && (
        <Button
          onClick={handleCopyLink}
          alignSelf="center"
          colorScheme="white"
          mt="4"
          rightIcon={<FiCopy />}
          variant="link"
        >
          {textLink}
        </Button>
      )}
    </Flex>
  );
}
