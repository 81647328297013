import React, { ComponentType } from 'react';
import { Box, BoxProps, Flex, Icon, Text } from '@chakra-ui/react';
import NumberFormat from 'react-number-format';

interface CardIconProps extends BoxProps {
  title: string;
  info: string | number;
  icon: ComponentType;
  type: 'text' | 'currency';
  currencyType?: '$ ' | 'R$ ';
}

export function CardIcon({
  info,
  title,
  icon,
  type,
  currencyType = '$ ',
  ...rest
}: CardIconProps): JSX.Element {
  return (
    <Flex>
      <Box p="4" {...rest}>
        <Icon as={icon} height="100%" width="20" />
      </Box>

      <Flex
        flexDirection="column"
        align="start"
        justify="center"
        bg="whiteAlpha.900"
        color="blackAlpha.900"
        w="100%"
        maxW="360px"
        minW="200px"
        fontSize="lg"
        paddingX="2"
      >
        <Text>{title}</Text>
        <Text fontWeight="bold">
          {type === 'currency' ? (
            <NumberFormat
              fixedDecimalScale
              decimalSeparator=","
              thousandSeparator="."
              displayType="text"
              decimalScale={2}
              value={info}
              prefix={currencyType}
            />
          ) : (
            info
          )}
        </Text>
      </Flex>
    </Flex>
  );
}
