import React, { useEffect, useMemo, useState } from 'react';
import { addMonths, format, getDaysInMonth, setDate } from 'date-fns';
import { Box, Checkbox, Flex } from '@chakra-ui/react';

import { api } from '../../service/api';
import { ChartIncome } from '../Chart';

interface User {
  id: number;
  name: string;
  username: string;
}

interface IncomeReportProps {
  incomeReferrals: number;
  incomeTo: number;
  profitabilityDayMonth: number;
  user: User;
}

export interface IncomeReportFormatted {
  day: string;
  totalIncomeTo: number;
}

export function IncomeReportChart() {
  const [incomesChart, setIcomesChart] = useState<IncomeReportFormatted[]>([]);
  const [isShowWithoutZero, setIsShowWithoutZero] = useState(true);

  useEffect(() => {
    api.get<IncomeReportProps[]>('admin/incomesdetails').then(response => {
      const incomesSortted = response.data.sort((a, b) => {
        const dateA = setDate(new Date(), a.profitabilityDayMonth);
        const dateB = setDate(new Date(), b.profitabilityDayMonth);
        const acttualyDateA = dateA > new Date() ? dateA : addMonths(dateA, 1);
        const acttualyDateB = dateB > new Date() ? dateB : addMonths(dateB, 1);

        return acttualyDateA < acttualyDateB ? -1 : 1;
      });

      const incomesChart = [];
      const totalDaysInMonth = getDaysInMonth(new Date());
      for (let index = 1; index <= totalDaysInMonth; index++) {
        const daysSelected = incomesSortted.filter(
          income => income.profitabilityDayMonth === index,
        );

        const totalIcomeToDaySelectedIncomeTo = daysSelected.reduce(
          (acc, item) => acc + item.incomeTo,
          0,
        );

        const totalIcomeToDaySelectedIncomeReferrals = daysSelected.reduce(
          (acc, item) => acc + item.incomeReferrals,
          0,
        );

        const total =
          totalIcomeToDaySelectedIncomeTo +
          totalIcomeToDaySelectedIncomeReferrals;

        const dateDaySelected = setDate(new Date(), index);

        incomesChart.push({
          day: format(dateDaySelected, 'dd/MM'),
          totalIncomeTo: Number(total.toFixed(2)),
        });
      }

      setIcomesChart(incomesChart);
    });
  }, []);

  const incomesChartFiltered = useMemo(() => {
    if (isShowWithoutZero) {
      return incomesChart.filter(income => income.totalIncomeTo > 0);
    }

    return incomesChart;
  }, [isShowWithoutZero, incomesChart]);

  return (
    <Flex bg="#0C6E9C" flexDir="column" py="4">
      <Box px={4} alignSelf="center">
        <Checkbox
          isChecked={isShowWithoutZero}
          onChange={event => setIsShowWithoutZero(event.target.checked)}
        >
          Mostrar rendimentos maiores que zero
        </Checkbox>
      </Box>

      <Box w="100%" alignSelf="center">
        <ChartIncome data={incomesChartFiltered} />
      </Box>
    </Flex>
  );
}
