import React, { useEffect } from 'react';
import {
  Button,
  Flex,
  Link,
  Image,
  Heading,
  Stack,
  useBreakpointValue,
  useToast,
  useRadioGroup,
  IconButton,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { AxiosResponse } from 'axios';

import { Input } from '../../components/Input';

import { api } from '../../service/api';
import { RadioCard } from '../../components/RadioCard';

import logoSignin from '../../assets/logo-login.png';

type CreateUserFormData = {
  name: string;
  email: string;
  username: string;
  password: string;
  password_confirmation: string;
  dadId: string;
};

interface DadData {
  id: number;
  name: string;
}

const opitonsAccount = ['Cliente', 'Consultor'];

const createUserFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  username: yup
    .string()
    .required('Usuário obrigatório')
    .max(20, 'Usuário deve ter no máximo 20 caracteres'),
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres'),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisam ser iguais'),
  dadId: yup.string(),
});

interface Error {
  response: AxiosResponse;
}

export function Signup(): JSX.Element {
  const [dad, setDad] = useState<DadData>({} as DadData);
  const [accountType, setAccountType] = useState('Cliente');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { pathname } = useLocation();
  let username = pathname.replaceAll('/', '').replace('signup', '');
  const toast = useToast();
  const history = useHistory();

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(createUserFormSchema),
  });

  const { errors } = formState;

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {
    if (!username) return;

    api
      .get<DadData>(`users/name/${username}`)
      .then(response => {
        setDad(response.data);
        setValue('dadId', response.data.name);
      })
      .catch(() => history.push('/'));
  }, [username, setValue, history]);

  const handleCreateUser: SubmitHandler<CreateUserFormData> = async data => {
    try {
      const { email, name, password, username } = data;

      const request = {
        name,
        email,
        username,
        dadId: dad.id,
        password,
        role: accountType === 'Cliente' ? 1 : 2,
      };
      await api.post('/users', request);

      toast({
        title: 'Cliente cadastrado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      history.push('/');
    } catch (error) {
      const {
        response: { data, status },
      } = error as Error;

      const erroDescrition = typeof data === 'string' ? data : '';

      toast({
        title: 'Erro no cadastro!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? erroDescrition : '',
        position: 'top-right',
      });
    }
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'account',
    defaultValue: 'Cliente',
    onChange: setAccountType,
  });

  const group = getRootProps();

  const RightActionPasswordInput = () => (
    <IconButton
      aria-label="Mostrar/Ocultar senha"
      onClick={() => setShowPassword(!showPassword)}
      icon={showPassword ? <FiEyeOff /> : <FiEye />}
      colorScheme="whiteAlpha"
      bg="transparent"
      _hover={{
        background: 'transparent',
      }}
    />
  );

  const RightActionConfirmPasswordInput = () => (
    <IconButton
      aria-label="Mostrar/Ocultar senha"
      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
      icon={showConfirmPassword ? <FiEyeOff /> : <FiEye />}
      colorScheme="whiteAlpha"
      bg="transparent"
      _hover={{
        background: 'transparent',
      }}
    />
  );

  return (
    <Flex
      height={isWideVersion ? '100vh' : '100%'}
      align="center"
      justify="center"
      bg="#0C6E9C"
    >
      <Flex width="100%" maxW="1600px" flexDir="column" alignItems="center">
        {isWideVersion && (
          <Image src={logoSignin} maxW="250px" maxH="250" mt="-10" />
        )}

        <Heading my={4}>Cadastro</Heading>

        <Flex
          direction="column"
          as="form"
          onSubmit={handleSubmit(handleCreateUser)}
          alignItems="center"
        >
          <Stack spacing="4" width="100%" maxWidth="900px">
            <Flex {...group} justifyContent="space-between" gridGap={4}>
              {opitonsAccount.map(value => {
                const radio = getRadioProps({ value });
                return (
                  <RadioCard key={value} {...radio}>
                    {value}
                  </RadioCard>
                );
              })}
            </Flex>

            <Input label="Nome" error={errors.name} {...register('name')} />

            <Flex gridGap={4} flexDir={isWideVersion ? 'row' : 'column'}>
              <Input
                label="E-mail"
                type="email"
                error={errors.email}
                {...register('email')}
              />

              <Input
                label="Usuário"
                error={errors.username}
                maxLength={20}
                {...register('username')}
              />
            </Flex>

            <Flex gridGap={4} flexDir={isWideVersion ? 'row' : 'column'}>
              <Input
                isReadOnly
                label="Consultor"
                error={errors.dadId}
                {...register('dadId')}
              />

              <Input
                type={showPassword ? 'text' : 'password'}
                label="Senha"
                rightAction={<RightActionPasswordInput />}
                error={errors.password}
                {...register('password')}
              />

              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                label="Confirme a senha"
                rightAction={<RightActionConfirmPasswordInput />}
                error={errors.password_confirmation}
                {...register('password_confirmation')}
              />
            </Flex>
          </Stack>

          <Flex w="100%" align="center" justify="space-between" mt="4">
            <Link mt={isWideVersion ? '' : '4'} href="/">
              Voltar para o login
            </Link>

            <Button
              type="submit"
              colorScheme="blue"
              width="100%"
              maxWidth="120px"
              isLoading={formState.isSubmitting}
            >
              Cadastrar
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
