import React from 'react';
import {
  Flex,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { FinancialStatement } from '../../components/Financial/FinancialStatement';
import { MyInvestments } from '../../components/Financial/MyInvestments';
import { Withdrawals } from '../../components/Financial/Withdrawals';

export function Financial(): JSX.Element {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex h={isWideVersion ? '100vh' : '100%'} bg="#C9C9C9">
      <Sidebar />

      <Flex flexDir="column" w="100%">
        <Header />

        <Flex justify="center" w="100%">
          <Box
            h={isWideVersion ? '100%' : '100vh'}
            maxW="1980px"
            p="2"
            w="100%"
          >
            <Tabs isFitted variant="soft-rounded" colorScheme="gray">
              <TabList>
                <Tab color="#fff" bg="#0C6E9C" mr="8px">
                  Extrato Financeiro
                </Tab>
                <Tab color="#fff" bg="#0C6E9C" mr="8px">
                  Meus Depósitos
                </Tab>
                <Tab color="#fff" bg="#0C6E9C">
                  Meus Saques
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FinancialStatement />
                </TabPanel>
                <TabPanel>
                  <MyInvestments />
                </TabPanel>
                <TabPanel>
                  <Withdrawals />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
