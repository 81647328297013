import React, { useState, useEffect, KeyboardEvent } from 'react';
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useMediaQuery,
  useDisclosure,
  Button,
  Flex,
  Collapse,
  Text,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import { addDays, format, setHours } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useAuth, User } from '../../hooks/auth';
import { api } from '../../service/api';
import { Input } from '../Input';
import { Select } from '../Select';
import { ConvertDateAmerican } from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';

interface InvestmentsProps {
  id: number;
  user: User;
  type: string;
  status: string;
  amount: number;
  date: Date;
  confirmationDate: Date;
  paymentVoucher: string;
}

export function MyInvestments(): JSX.Element {
  const { user } = useAuth();

  const [investments, setInvestments] = useState<InvestmentsProps[]>([]);

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const [amount, setAmount] = useState(0);
  const [statusData, setStatusData] = useState('');
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingClearFilter, setIsLoadingClearFilter] = useState(false);
  const [initialDate, setInitialDate] = useState(addDays(new Date(), -7));
  const [finalDate, setFinalDate] = useState(new Date());

  useEffect(() => {
    try {
      api
        .get(`/investments/user/${user.id}`)
        .then(response => setInvestments(response.data));
    } catch (error) {
      //
    }
  }, [user.id]);

  useEffect(() => {
    if (isLargerThan1368) {
      onOpen();
    } else {
      onClose();
    }
  }, [isLargerThan1368, onOpen, onClose]);

  function handleInitialDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setInitialDate(setHours(date, 0));
  }

  function handleFinalDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setFinalDate(setHours(date, 23));
  }

  async function handleFilterInvestments() {
    try {
      setIsLoadingFilter(true);

      const initalDateRequest = ConvertDateAmerican(initialDate);
      const finalDateRequest = ConvertDateAmerican(addDays(finalDate, 1));

      await api
        .get(
          `/investments/user/${user.id}?amount=${amount}&initialDate=${initalDateRequest}&finalDate=${finalDateRequest}&status=${statusData}`,
        )
        .then(response => setInvestments(response.data));

      if (!isLargerThan1368) onClose();

      setIsLoadingFilter(false);
    } catch {
      setIsLoadingFilter(false);
    }
  }

  async function handleClearFilterInvestments() {
    setIsLoadingClearFilter(true);

    try {
      await api
        .get(`/investments/user/${user.id}`)
        .then(response => setInvestments(response.data));

      setAmount(0);
      setStatusData('-1');

      if (!isLargerThan1368) onClose();
    } catch (error) {
      //
    } finally {
      setIsLoadingClearFilter(false);
    }
  }

  function handleKeyPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const { nativeEvent } = event;
    if (nativeEvent.key === 'Enter') {
      handleFilterInvestments();
    }
  }

  if (!investments) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Box mt="4">
      {!isLargerThan1368 && (
        <Button
          colorScheme="teal"
          alignSelf="center"
          marginY="2"
          onClick={onToggle}
        >
          Filtros
        </Button>
      )}

      <Collapse animateOpacity in={isOpen}>
        <Flex flexDir="column" bg="#0C6E9C" gridGap="10" p="2" rounded={10}>
          <Flex flexDir="column" gridGap="4">
            <Flex
              gridGap="2"
              justify="space-between"
              w="100%"
              flexDir={isLargerThan1368 ? 'row' : 'column'}
            >
              <Flex flexDir="column" w="100%">
                <Text mb={2} fontWeight="bold">
                  Valor do depósito
                </Text>
                <NumberFormat
                  prefix="$ "
                  placeholder="Valor do depósito"
                  allowNegative={false}
                  decimalSeparator=","
                  decimalScale={2}
                  thousandSeparator="."
                  onValueChange={values => {
                    const { floatValue } = values;
                    setAmount(floatValue || 0);
                  }}
                  onKeyPress={handleKeyPressEnter}
                  style={{
                    width: '100%',
                    outline: '2px solid transparent',
                    outlineOffset: 2,
                    backgroundColor: '#181B23',
                    height: '3rem',
                    borderRadius: '0.375rem',
                    border: '2px solid trasparent',
                    fontSize: '1.125rem',
                    paddingInlineStart: '1rem',
                    paddingInlineEnd: '2.5rem',
                  }}
                />
              </Flex>

              <Select
                name="status"
                label="Status"
                value={statusData}
                onChange={event => setStatusData(event.target.value)}
                w="100%"
              >
                <option value="-1">Todos</option>
                <option value="0">Comprovante Pendente</option>
                <option value="1">Em Análise</option>
                <option value="2">Confirmado</option>
                <option value="3">Cancelado</option>
              </Select>
            </Flex>

            <Flex gridGap="4">
              <DatePicker
                selected={initialDate}
                maxDate={finalDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => handleInitialDate(date)}
                customInput={<Input name="from" label="De" />}
                locale={ptBR}
              />

              <DatePicker
                selected={finalDate}
                minDate={initialDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => handleFinalDate(date)}
                customInput={<Input name="to" label="Até" />}
                locale={ptBR}
              />

              <Flex ml="auto" gridGap="4" align="flex-end">
                <Button
                  colorScheme="teal"
                  type="button"
                  onClick={handleFilterInvestments}
                  isLoading={isLoadingFilter}
                >
                  Pesquisar
                </Button>
                <Button
                  type="button"
                  colorScheme="whiteAlpha"
                  variant="outline"
                  onClick={handleClearFilterInvestments}
                  isLoading={isLoadingClearFilter}
                >
                  Limpar
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Collapse>

      <Box
        hidden={isOpen && !isLargerThan1368}
        overflowX="auto"
        maxH={isLargerThan1368 ? '530px' : '380px'}
        mt="14px"
        rounded={8}
      >
        <Table bg="#0C6E9C">
          <Thead>
            <Tr>
              <Th color="#fff">Descrição</Th>
              <Th color="#fff">Valor</Th>
              <Th color="#fff">Data</Th>
              <Th color="#fff">Data de Confirmação</Th>
              <Th color="#fff">Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {investments.map(investment => (
              <Tr key={investment.id}>
                <Td>{investment.type}</Td>
                <Td>
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={investment.amount}
                    decimalScale={2}
                    prefix="$ "
                  />
                </Td>
                <Td>{format(new Date(investment.date), 'dd/MM/yyyy')}</Td>
                <Td>
                  {String(investment.confirmationDate) === '0001-01-01T00:00:00'
                    ? 'Não confirmado'
                    : format(
                        new Date(investment.confirmationDate),
                        'dd/MM/yyyy',
                      )}
                </Td>
                <Td>{investment.status}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
