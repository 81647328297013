import React from 'react';
import {
  Flex,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { MyReferrals } from '../../components/Franchised/MyReferrals';
import { MyFranchiseds } from '../../components/Franchised/MyFranchiseds';

export function ReferralsArea(): JSX.Element {
  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex flexDir="column" w="100%">
        <Header />

        <Box h="100%" p="4">
          <Tabs isFitted variant="soft-rounded" colorScheme="gray">
            <TabList>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Meus indicados
              </Tab>
              {/* <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Meus Consultores
              </Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <MyReferrals />
              </TabPanel>
              {/* <TabPanel>
                <MyFranchiseds />
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Flex>
  );
}
