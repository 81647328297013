import { Flex, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

export function LoadingSpinner(): JSX.Element {
  return (
    <Flex flexDir="column" gridGap="10" align="center" justify="center">
      <Spinner size="xl" />
      <Text>Carregando...</Text>
    </Flex>
  );
}
