import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { User } from '../../components/User';

export function UpdateUser(): JSX.Element {
  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex flexDir="column" w="100%" alignItems="center">
        <Header />
        <User />
      </Flex>
    </Flex>
  );
}
