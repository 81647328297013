import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, useMediaQuery } from '@chakra-ui/react';

import { addMonths, addYears, getMonth, getYear } from 'date-fns';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { ChartDashboard } from '../../components/Chart';
import { DashboardChart } from '../../components/Dashboard/AdmDashboard';
import { LoadingSpinner } from '../../components/LoadingSpinner';

interface DashboardProps {
  invested: number;
  profitability: number;
  balanceAvailable: number;
}

interface PeriodProps {
  value: string;
  label: string;
  pluralForm: string;
}

const typePeriods = [
  { value: 'month', label: 'Mês', pluralForm: 'mese(s)' },
  { value: 'year', label: 'Ano', pluralForm: 'ano(s)' },
];

export function InvestmentProjection(): JSX.Element {
  const { user } = useAuth();

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  const [seriesChart, setSeriesChart] = useState<DashboardChart[]>([]);
  const [dashboard, setDashboard] = useState<DashboardProps>(
    {} as DashboardProps,
  );
  const [isLoading, setIsLoading] = useState(false);

  const [period, setPeriod] = useState(0);
  const [typePeriod, setTypePeriod] = useState<PeriodProps>({
    value: 'year',
    label: 'Ano',
    pluralForm: 'ano(s)',
  });

  useEffect(() => {
    setIsLoading(true);
    try {
      api
        .get(`users/dashboard/${user.id}`)
        .then(response => setDashboard(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [user.id]);

  useEffect(() => {
    if (!dashboard.balanceAvailable) return;

    let totalPerPeriod = dashboard.balanceAvailable;
    let month = 0;
    let totalPerPeriodArray: DashboardChart[] = [];
    let balance = '';
    let convertedProfitability =
      dashboard.profitability > 0 ? dashboard.profitability / 100 : 0;
    for (let i = 0; i < 10; i++) {
      if (typePeriod.value === 'month') {
        totalPerPeriod *= 1 + convertedProfitability;
        month = getMonth(addMonths(new Date(), i + 1));
      } else {
        totalPerPeriod *= (1 + convertedProfitability) ** 12;
        month = getYear(addYears(new Date(), i + 1));
      }

      balance = totalPerPeriod.toFixed(2);

      totalPerPeriodArray.push({
        balance: Number(balance),
        month,
      });
    }

    setSeriesChart(totalPerPeriodArray);
  }, [dashboard.balanceAvailable, typePeriod.value]);

  function handleOnChangePeriod(periodSelected: string) {
    const periodSelect = typePeriods.filter(
      item => item.value === periodSelected,
    );

    setTypePeriod(periodSelect[0]);
  }

  async function handleSimulateInvestment() {
    let totalPerPeriod = dashboard.balanceAvailable;
    let month = 0;
    let totalPerPeriodArray: DashboardChart[] = [];
    let balance = '';
    let convertedProfitability =
      dashboard.profitability > 0 ? dashboard.profitability / 100 : 0;

    for (let i = 0; i < period; i++) {
      if (typePeriod.value === 'month') {
        totalPerPeriod *= 1 + convertedProfitability;
        month = getMonth(addMonths(new Date(), i + 1));
      } else {
        totalPerPeriod *= (1 + convertedProfitability) ** 12;
        month = getYear(addYears(new Date(), i + 1));
      }

      balance = totalPerPeriod.toFixed(2);

      totalPerPeriodArray.push({
        balance: Number(balance),
        month,
      });
    }

    setSeriesChart(totalPerPeriodArray);
  }

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex flexDir="column" w="100%">
        <Header />

        <Flex flexDir="column" p="4">
          <Flex flexDir="column" bg="#0C6E9C" p="4">
            <Heading>Projeção de ganhos</Heading>

            <Flex justify="center" width="100%" flexDir="column">
              <Flex bg="#0C6E9C" p="8" gridGap="8" rounded={10} width="100%">
                <Input
                  name="amount"
                  label="Depósito"
                  icon="$ "
                  type="number"
                  mask="currency"
                  disabled
                  value={dashboard.balanceAvailable}
                />

                <Select
                  name="typePeriod"
                  label="Periodo"
                  value={typePeriod.value}
                  onChange={event => handleOnChangePeriod(event.target.value)}
                >
                  {typePeriods.map(type => (
                    <option
                      style={{ color: '#1F2029' }}
                      key={type.value}
                      value={type.value}
                    >
                      {type.label}
                    </option>
                  ))}
                </Select>

                <Input
                  name="period"
                  label={`Tempo (${typePeriod.label})`}
                  type="number"
                  onChange={event => setPeriod(Number(event.target.value))}
                />
              </Flex>

              <Button
                type="button"
                colorScheme="whatsapp"
                mt="auto"
                onClick={handleSimulateInvestment}
                width="100%"
                alignSelf="center"
                maxW="300px"
              >
                Projetar
              </Button>
            </Flex>

            <Box w="100%">
              {dashboard.balanceAvailable > 0 && (
                <ChartDashboard
                  data={seriesChart}
                  type={typePeriod.value}
                  width={isLargerThan1368 ? '1080' : '700px'}
                  height={isLargerThan1368 ? '320px' : '320px'}
                />
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
