import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  IconButton,
  Select,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Input } from '../Input';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

type PasswordChangeFormData = {
  newPassword: string;
};

const notesFormSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'Mínimo 6 caracteres'),
});

interface User {
  name: string;
  newPassword: string;
}

export function PasswordChange(): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const { pathname } = useLocation();
  const [user, setUser] = useState<User>({} as User);

  const toast = useToast();

  const userId = pathname.replaceAll('/', '').replace('edit-user-adm', '');

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(notesFormSchema),
  });

  const { errors } = formState;

  const RightActionPasswordInput = () => (
    <IconButton
      aria-label="Mostrar/Ocultar senha"
      onClick={() => setShowPassword(!showPassword)}
      icon={showPassword ? <FiEyeOff /> : <FiEye />}
      bg="transparent"
      _hover={{
        background: 'transparent',
      }}
    />
  );

  useEffect(() => {
    if (!userId || userId === '0') return;

    try {
      api.get<User>(`users/${userId}`).then(response => setUser(response.data));
    } catch (error) {
      //
    }
  }, [userId]);

  useEffect(() => {
    setValue('newPassword', user.newPassword);
  }, [setValue, user.newPassword]);

  const functionHandleNoteUser: SubmitHandler<PasswordChangeFormData> =
    async data => {
      try {
        const { newPassword } = data;

        await api.put(`users/admin/password/new/${userId}`, {
          newPassword,
        });

        toast({
          title: 'Senha alterada com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const { data, status } = error.response;

        toast({
          title: 'Erro ao alterar senha do usuário!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: status === 400 ? data : '',
          position: 'top-right',
        });
      }
    };

  if (!user) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex>
      <Flex flexDir="column" w="100%">
        <Heading flexWrap="nowrap">
          <Text as="strong" color="green.400">
            {user.name}
          </Text>
        </Heading>

        <Flex
          bg="gray.800"
          p="4"
          flexDir="column"
          as="form"
          onSubmit={handleSubmit(functionHandleNoteUser)}
        >
          <Flex
            align="center"
            justify="center"
            w="100%"
            p="4"
            gridGap="10"
            position="relative"
          >
            <Stack w="100%" maxW="600px">
              <Text>Nova Senha</Text>
              <Input
                type={showPassword ? 'text' : 'password'}
                rightAction={<RightActionPasswordInput />}
                min="6"
                max="31"
                defaultValue={user.newPassword}
                error={errors.newPassword}
                {...register('newPassword')}
              />
            </Stack>
          </Flex>

          <Button
            type="submit"
            colorScheme="green"
            maxW="300px"
            w="100%"
            alignSelf="center"
            mt="8"
            isLoading={formState.isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
