import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Flex,
  Icon,
  useBreakpointValue,
  SimpleGrid,
  Button,
  useMediaQuery,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from '@chakra-ui/react';
import { FiArrowRightCircle, FiDollarSign, FiUsers } from 'react-icons/fi';
import { FaCalendarDay, FaMoneyBillAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { addMonths, format, setDate, isValid, isFriday } from 'date-fns';

import { CardIcon } from '../CardIcon';
import { Overview } from '../Overview';
import { ChartDashboard, ChartDashboardProfitSharing } from '../Chart';
import { api } from '../../service/api';
import { useAuth } from '../../hooks/auth';
import { WarningWindow } from '../WarningWindow';
import { useReactModal } from '../../contexts/ReactModal';
import { DashboardChart } from './AdmDashboard';
import { LoadingSpinner } from '../LoadingSpinner';

interface DashboardProps {
  balanceAvailable: number;
  referrals: number;
  invested: number;
  incomeGain: number;
  incomeReferralGain: number;
  referralGain: number;
  profitability: number;
  incomeChart: DashboardChart[];
  profitabilityDayMonth: number;
}

interface Settings {
  allowWithdraw: boolean;
}

export function UserDashboard(): JSX.Element {
  const { pathname } = useLocation();
  const { user, validToken } = useAuth();

  const { handleOpenWithdrawModal, handleOpenContractModalDashboard } =
    useReactModal();

  const [isShowModalContract, setIsShowModalContract] = useState(
    pathname === '/dashboard' &&
      user.activeStatus === 1 &&
      user.role !== 0 &&
      !user.contractConfirmation,
  );

  const [dashboard, setDashboard] = useState<DashboardProps>(
    {} as DashboardProps,
  );

  const [isAllowWithdraw, setIsAllowWithdraw] = useState(false);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [isLargerThan1630] = useMediaQuery('(min-width: 1630px)');

  useEffect(() => {
    try {
      api
        .get<Settings>('settings/setting', {
          headers: { Authorization: `Bearer ${validToken()}` },
        })
        .then(response => setIsAllowWithdraw(response.data.allowWithdraw));
    } catch (err) {
      // Tratar error
    }
  }, [user.id, validToken]);

  useEffect(() => {
    try {
      api
        .get(`users/dashboard/${user.id}`, {
          headers: { Authorization: `Bearer ${validToken()}` },
        })
        .then(response => setDashboard(response.data));
    } catch (err) {
      // Tratar error
    }
  }, [user.id, validToken]);

  useEffect(() => {
    if (isShowModalContract) {
      handleOpenContractModalDashboard();
      setIsShowModalContract(false);
    }
  }, [isShowModalContract, handleOpenContractModalDashboard]);

  const profitabilityDayMonth = useMemo(() => {
    if (!dashboard) return '';

    const date = setDate(new Date(), dashboard.profitabilityDayMonth);

    const acttualyDate = date > new Date() ? date : addMonths(date, 1);

    if (!isValid(acttualyDate)) return '';

    return format(acttualyDate, 'dd/MM/yyyy');
  }, [dashboard]);

  if (!dashboard) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex
      align="center"
      justify={user.activeStatus === 1 ? '' : 'center'}
      w="100%"
    >
      {user.activeStatus === 1 ? (
        <Flex
          flexDir="column"
          align="center"
          p="4"
          w="100%"
          h="100%"
          maxW="2000px"
          paddingX={isWideVersion ? '8' : '4'}
        >
          {isAllowWithdraw && (
            <Box alignSelf="flex-start" mb="4">
              <Button colorScheme="whatsapp" onClick={handleOpenWithdrawModal}>
                Solicitar saque{' '}
                <Icon as={FiArrowRightCircle} ml="1" fontSize="24" />
              </Button>
            </Box>
          )}

          <Flex
            justify="center"
            width="100%"
            mt={!isFriday(new Date()) ? '10' : ''}
          >
            <Flex
              gridGap="5"
              maxW={isLargerThan1630 ? '100%' : 1000}
              flexWrap="wrap"
              justify="center"
            >
              <CardIcon
                icon={FiDollarSign}
                bg="green.400"
                title="TOTAL GERAL (BUSD)"
                type="currency"
                info={dashboard.balanceAvailable}
              />

              {user.role === 2 && (
                <CardIcon
                  icon={FiUsers}
                  bg="red.400"
                  title="INDICADOS"
                  type="text"
                  info={dashboard.referrals || 0}
                />
              )}

              <CardIcon
                icon={FaMoneyBillAlt}
                bg="yellow.400"
                title="TOTAL DEPOSITADO (BUSD)"
                type="currency"
                info={dashboard.invested || 0}
              />

              {user.profitabilityDayMonth !== 0 && (
                <CardIcon
                  icon={FaCalendarDay}
                  bg="yellow.400"
                  title="DIA DO RENDIMENTO"
                  type="text"
                  info={profitabilityDayMonth}
                />
              )}
            </Flex>
          </Flex>

          <SimpleGrid
            gap="4"
            columns={isWideVersion ? 2 : 1}
            row={isWideVersion ? 2 : 1}
            mt="4"
            w="100%"
          >
            <Overview
              overview1={{
                title: 'Ganhos por indicação',
                value: dashboard.referralGain,
                isShow: user.role === 2,
                symbol: '$',
              }}
              overview2={{
                title: 'Ganhos rendimento',
                value: dashboard.incomeGain,
                isShow: true,
                symbol: '$',
              }}
              overview3={{
                title: 'Ganhos rendimentos da indicação',
                value: dashboard.incomeReferralGain,
                isShow: user.role === 2,
                symbol: '$',
              }}
              overview4={{
                title: 'Minha rentabilidade',
                value: dashboard.profitability,
                isShow: true,
                symbol: '%',
              }}
            />

            <ChartDashboard data={dashboard.incomeChart} />
          </SimpleGrid>
          <SimpleGrid gap="4" columns={1} row={1} mt="4" w="100%">
            <Box h="100%">
              <Flex justify="center" width="100%" mb="2">
                <Text as="h2" fontWeight="bold" fontSize="24" color="#000">
                  Histórico de P/L
                </Text>
              </Flex>
              <Tabs isFitted variant="soft-rounded" defaultIndex={3}>
                <TabList>
                  <Tab color="#fff" bg="#0C6E9C" mr="8px">
                    2020 (Lucro 85,3%)
                  </Tab>
                  <Tab color="#fff" bg="#0C6E9C" mr="8px">
                    2021 (Lucro 66,8%)
                  </Tab>
                  <Tab color="#fff" bg="#0C6E9C" mr="8px">
                    2022 (Lucro 62,9%)
                  </Tab>
                  <Tab color="#fff" bg="#0C6E9C" mr="8px">
                    2023 (Lucro 6,2%)
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <ChartDashboardProfitSharing
                      data={[
                        { month: 1, balance: 5.2 },
                        { month: 2, balance: 4.7 },
                        { month: 3, balance: 12.3 },
                        { month: 4, balance: 8.1 },
                        { month: 5, balance: 7.3 },
                        { month: 6, balance: 7.1 },
                        { month: 7, balance: 7.4 },
                        { month: 8, balance: 7.1 },
                        { month: 9, balance: 7.1 },
                        { month: 10, balance: 7.3 },
                        { month: 11, balance: 7.1 },
                        { month: 12, balance: 4.6 },
                      ]}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ChartDashboardProfitSharing
                      data={[
                        { month: 1, balance: 6.3 },
                        { month: 2, balance: 5.7 },
                        { month: 3, balance: 5.9 },
                        { month: 4, balance: 6.1 },
                        { month: 5, balance: 5.7 },
                        { month: 6, balance: 5.3 },
                        { month: 7, balance: 6.1 },
                        { month: 8, balance: 5.6 },
                        { month: 9, balance: 5.2 },
                        { month: 10, balance: 5.7 },
                        { month: 11, balance: 5.1 },
                        { month: 12, balance: 4.1 },
                      ]}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ChartDashboardProfitSharing
                      data={[
                        { month: 1, balance: 5.7 },
                        { month: 2, balance: 5.3 },
                        { month: 3, balance: 5.6 },
                        { month: 4, balance: 5.9 },
                        { month: 5, balance: 5.7 },
                        { month: 6, balance: 4.7 },
                        { month: 7, balance: 4.9 },
                        { month: 8, balance: 5.3 },
                        { month: 9, balance: 5.2 },
                        { month: 10, balance: 5.1 },
                        { month: 11, balance: 5.4 },
                        { month: 12, balance: 4.1 },
                      ]}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ChartDashboardProfitSharing
                      data={[{ month: 1, balance: 6.2 }]}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </SimpleGrid>
        </Flex>
      ) : (
        <WarningWindow />
      )}
    </Flex>
  );
}
