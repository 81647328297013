import React from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';

const ticketModalFormSchema = yup.object().shape({
  email: yup.string().email().required('Email obrigatório'),
});

export function TicketModal(): JSX.Element {
  const { isOpenTicketModal, handleCloseTicketModal } = useReactModal();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(ticketModalFormSchema),
    defaultValues: {
      email: '',
    },
  });

  const toast = useToast();

  const { errors } = formState;

  async function handleTicketModal() {
    try {
      toast({
        title: 'Senha alterada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      handleCloseTicketModal();
    } catch (error) {
      const { data, status } = error.response;
      toast({
        title: 'Erro ao resetar sua senha, tente novamente!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        description: status === 400 ? data : '',
      });
    }
  }

  return (
    <Modal isOpen={isOpenTicketModal} onClose={handleCloseTicketModal}>
      <ModalOverlay />

      <ModalContent bg="gray.800" p="4">
        <ModalHeader>Enviar Ticket</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex
            as="form"
            onSubmit={handleSubmit(handleTicketModal)}
            flexDir="column"
            gridGap="2"
          >
            <Input
              label="E-mail"
              type="password"
              error={errors.email}
              {...register('email')}
            />

            <Flex gridGap="4" mt="4" alignSelf="flex-end">
              <Button
                type="button"
                colorScheme="whiteAlpha"
                onClick={handleCloseTicketModal}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={formState.isSubmitting}
              >
                Enviar
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
