import React, { KeyboardEvent, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  useToast,
  useMediaQuery,
  useDisclosure,
  Collapse,
  Text,
} from '@chakra-ui/react';
import { addDays, addMonths, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import NumberFormat from 'react-number-format';
import { FiCheck, FiXCircle, FiDownload } from 'react-icons/fi';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';
import { Input } from '../Input';
import { Select } from '../Select';
import { ConvertDateAmerican } from '../../utils/convertData';

interface User {
  id: number;
  username: string;
  name: string;
  email: string;
  birthDate: Date;
  country: string;
  gender: string;
  cep: string;
  city: string;
  state: string;
}

interface PaymentVoucher {
  id: number;
  name: string;
  contentType: string;
  size: number;
  content: string;
}

interface InvoicesData {
  id: number;
  user: User;
  type: string;
  status: string;
  amount: number;
  date: Date;
  confirmationDate: Date;
  paymentVoucher: PaymentVoucher;
}

export function Invoices(): JSX.Element {
  const [invoices, setInvoices] = useState<InvoicesData[]>([]);
  const [isLoadingInvest, setIsLoadingInvest] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [statusData, setStatusData] = useState('');
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingClearFilter, setIsLoadingClearFilter] = useState(false);
  const [initialDate, setInitialDate] = useState(addMonths(new Date(), -1));
  const [finalDate, setFinalDate] = useState(new Date());

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  const toast = useToast();

  useEffect(() => {
    try {
      setIsLoading(true);
      api.get('investments').then(response => setInvoices(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isLargerThan1368) {
      onOpen();
    } else {
      onClose();
    }
  }, [isLargerThan1368, onOpen, onClose]);

  function handleInitialDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setInitialDate(date);
  }

  function handleFinalDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setFinalDate(date);
  }

  async function handleConfirmationInvestment(id: number) {
    try {
      setIsLoadingInvest(true);
      const result = window.confirm(
        'Tem certeza que gostaria de confirmar esse depósito?',
      );

      if (!result) {
        setIsLoadingInvest(false);
        return;
      }

      await api.get(`admin/investiment/confirm/${id}`);

      toast({
        title: 'Depósito confirmado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setIsLoadingInvest(false);

      api.get('investments').then(response => setInvoices(response.data));
    } catch (error) {
      setIsLoadingInvest(false);

      const { data } = error.response;

      toast({
        title: 'Erro ao confirmar o depósito!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: data,
        position: 'top-right',
      });
    }
  }

  async function handleCancelInvestment(id: number) {
    try {
      setIsLoadingCancel(true);
      const result = window.confirm(
        'Tem certeza que gostaria de cancelar esse depósito?',
      );

      if (!result) {
        setIsLoadingCancel(false);
        return;
      }

      await api.get(`admin/investiment/cancel/${id}`);

      toast({
        title: 'Depósito negado!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setIsLoadingCancel(false);

      api.get('investments').then(response => setInvoices(response.data));
    } catch (error) {
      setIsLoadingCancel(false);

      const { data } = error.response;

      toast({
        title: 'Erro ao cancelar o depósito!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: data,
        position: 'top-right',
      });
    }
  }

  function handleFilterInvoices() {
    try {
      setIsLoadingFilter(true);

      const initalDateRequest = ConvertDateAmerican(initialDate);
      const finalDateRequest = ConvertDateAmerican(addDays(finalDate, 1));

      api
        .get(
          `investments?name=${name}&amount=${amount}&initialDate=${initalDateRequest}&finalDate=${finalDateRequest}&status=${statusData}`,
        )
        .then(response => setInvoices(response.data));

      if (!isLargerThan1368) onClose();
    } catch (error) {
      //
    } finally {
      setIsLoadingFilter(false);
    }
  }

  async function handleClearFilterInvoices() {
    try {
      setIsLoadingClearFilter(true);
      await api.get('investments').then(response => setInvoices(response.data));

      setName('');
      setAmount(0);
      setStatusData('-1');

      if (!isLargerThan1368) onClose();
    } catch (error) {
      //
    } finally {
      setIsLoadingClearFilter(false);
    }
  }

  function handleKeyPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const { nativeEvent } = event;
    if (nativeEvent.key === 'Enter') {
      handleFilterInvoices();
    }
  }

  const groupActionsButton = (invoice: InvoicesData) => (
    <Flex align="center" gridGap="2">
      {invoice.status === 'Em Análise' && (
        <>
          <Button
            type="button"
            colorScheme="green"
            title="Confirmar depósito"
            isLoading={isLoadingInvest}
            onClick={() => handleConfirmationInvestment(invoice.id)}
          >
            <FiCheck />
          </Button>

          <Button
            type="button"
            colorScheme="red"
            title="Cancelar depósito"
            isLoading={isLoadingCancel}
            onClick={() => handleCancelInvestment(invoice.id)}
          >
            <FiXCircle />
          </Button>
        </>
      )}
      {invoice.paymentVoucher && (
        <Button
          as="a"
          href={`data:${invoice.paymentVoucher.contentType};base64, ${invoice.paymentVoucher.content}`}
          target="_blank"
          download={`Comprovante_${invoice.id}_${invoice.user.name}`}
          colorScheme="blue"
          title="Baixar comprovante"
        >
          <FiDownload />
        </Button>
      )}
    </Flex>
  );

  return (
    <Flex flexDir="column" h="100%">
      <Box bg="whiteAlpha">
        {!isLargerThan1368 && (
          <Button
            colorScheme="teal"
            alignSelf="center"
            marginY="2"
            onClick={onToggle}
          >
            Filtros
          </Button>
        )}

        <Collapse animateOpacity in={isOpen}>
          <Flex flexDir="column" bg="gray.800" gridGap="10" p="4" rounded={10}>
            <Flex flexDir="column" gridGap="4">
              <Flex
                gridGap="2"
                justify="space-between"
                w="100%"
                flexDir={isLargerThan1368 ? 'row' : 'column'}
                alignItems="center"
              >
                <Input
                  name="name"
                  label="Nome"
                  placeholder="Nome ou usuário"
                  value={name}
                  onChange={event => setName(event.target.value)}
                  onKeyPress={handleKeyPressEnter}
                />

                <Flex flexDir="column" w="75%">
                  <Text mb={2}>Saldo</Text>
                  <NumberFormat
                    prefix="$ "
                    placeholder="Saldo do usuário"
                    allowNegative={false}
                    decimalSeparator=","
                    decimalScale={2}
                    thousandSeparator="."
                    onValueChange={values => {
                      const { floatValue } = values;
                      setAmount(floatValue || 0);
                    }}
                    onKeyPress={handleKeyPressEnter}
                    style={{
                      width: '100%',
                      outline: '2px solid transparent',
                      outlineOffset: 2,
                      backgroundColor: '#181B23',
                      height: '3rem',
                      borderRadius: '0.375rem',
                      border: '2px solid trasparent',
                      fontSize: '1.125rem',
                      paddingInlineStart: '1rem',
                      paddingInlineEnd: '2.5rem',
                    }}
                  />
                </Flex>

                <Select
                  name="status"
                  label="Status"
                  value={statusData}
                  onChange={event => setStatusData(event.target.value)}
                >
                  <option value="-1">Todos</option>
                  <option value="0">Comprovante Pendente</option>
                  <option value="1">Em Análise</option>
                  <option value="2">Confirmado</option>
                  <option value="3">Cancelado</option>
                </Select>
              </Flex>

              <Flex gridGap="4">
                <DatePicker
                  selected={initialDate}
                  maxDate={finalDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => handleInitialDate(date)}
                  customInput={<Input name="from" label="De" />}
                  locale={ptBR}
                />

                <DatePicker
                  selected={finalDate}
                  minDate={initialDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={date => handleFinalDate(date)}
                  customInput={<Input name="to" label="Até" />}
                  locale={ptBR}
                />

                <Flex ml="auto" gridGap="4" align="flex-end">
                  <Button
                    colorScheme="teal"
                    type="button"
                    onClick={handleFilterInvoices}
                    isLoading={isLoadingFilter}
                  >
                    Pesquisar
                  </Button>
                  <Button
                    type="button"
                    colorScheme="whiteAlpha"
                    variant="outline"
                    onClick={handleClearFilterInvoices}
                    isLoading={isLoadingClearFilter}
                  >
                    Limpar
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Collapse>

        {isLoading ? (
          <Flex align="center" width="100%" height="100%" justify="center">
            <LoadingSpinner />
          </Flex>
        ) : (
          <Box
            hidden={isOpen && !isLargerThan1368}
            overflowX="auto"
            overflowY="auto"
            maxW={isLargerThan1368 ? '100%' : '998px'}
            maxH={isLargerThan1368 ? '530px' : '360px'}
            sx={{
              '::-webkit-scrollbar': {
                width: '4px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#666',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-track': {
                backgroundColor: '#2f3136',
              },
            }}
          >
            <Table bg="#0C6E9C" mt="10px" rounded={10}>
              <Thead>
                <Tr>
                  <Th color="#FFF">Usuário/Nome</Th>
                  <Th color="#FFF">Valor</Th>
                  <Th color="#FFF">Descrição</Th>
                  <Th color="#FFF">Data</Th>
                  <Th color="#FFF">Data de Confirmação</Th>
                  <Th color="#FFF">Situação</Th>
                  <Th color="#FFF">Ação</Th>
                </Tr>
              </Thead>

              <Tbody>
                {invoices.map(invoice => (
                  <Tr key={invoice.id}>
                    <Td title={invoice.user.name}>{invoice.user.username}</Td>
                    <Td>
                      <NumberFormat
                        fixedDecimalScale
                        decimalSeparator=","
                        thousandSeparator="."
                        displayType="text"
                        decimalScale={2}
                        value={invoice.amount}
                        prefix="$ "
                      />
                    </Td>
                    <Td>{invoice.type}</Td>
                    <Td>{format(new Date(invoice.date), 'dd/MM/yyyy')}</Td>
                    <Td>
                      {String(invoice.confirmationDate) !==
                      '0001-01-01T00:00:00'
                        ? format(
                            new Date(invoice.confirmationDate),
                            'dd/MM/yyyy',
                          )
                        : ''}
                    </Td>
                    <Td>{invoice.status}</Td>
                    <Td>{groupActionsButton(invoice)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Box>
    </Flex>
  );
}
