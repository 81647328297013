import React, { useEffect } from 'react';
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Heading,
  Box,
  Link,
  Button,
  useToast,
  IconButton,
} from '@chakra-ui/react';

import { useState } from 'react';
import { format } from 'date-fns';
import { FiDownload, FiX } from 'react-icons/fi';
import { WarningIcon } from '@chakra-ui/icons';
import { useReactModal } from '../../contexts/ReactModal';
import { api } from '../../service/api';
import { useAuth } from '../../hooks/auth';
import { Textarea } from '../Textarea';
import { convertCurrencyBrazil } from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';

interface Franchise {
  id: number;
  name: string;
  price: number;
}

interface ProofPayment {
  id: 0;
  name: string;
  contentType: string;
  size: number;
  content: string;
}

interface Franchised {
  id: number;
  franchise: Franchise;
  proofPayment: ProofPayment;
  status: string;
}

interface UserFranchised {
  franchises: Franchised[];
}

interface User {
  id: number;
  activeStatus: number;
  profitabilityGroup: {
    id: number;
    groupName: string;
    profitabilityBonus: number;
    referralBonus: number;
    isPattern: boolean;
  };
  identityImage: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
  adressImage: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
  contractConfirmation: boolean;
  role: number;
  username: string;
  name: string;
  cpf: string;
  email: string;
  birthDate: Date;
  gender: string;
  phoneNumber: string;
  phoneNumberAux: string;
  cep: string;
  street: string;
  number: string;
  state: string;
  city: string;
  country: string;
  neighborhood: string;
  complement: string;
  isMigration: boolean;
}

export function ConfirmedUserModal(): JSX.Element {
  const toast = useToast();
  const { user } = useAuth();
  const {
    idConfirmedUser,
    handleConfirmedUserClose,
    isOpenConfirmedUserModal,
  } = useReactModal();

  const [isOpenWarningMessage, setIsOpenWarningMessage] = useState(false);
  const [isLoadingWarningMessage, setIsLoadingWarningMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [selectedFranchised, setSelectedFranchised] = useState<UserFranchised>(
    {} as UserFranchised,
  );

  const [userConfirmed, setUserConfirmed] = useState<User>();

  useEffect(() => {
    if (!user || !idConfirmedUser) return;

    setIsOpenWarningMessage(false);

    try {
      setIsLoading(true);
      api
        .get(`users/${idConfirmedUser}`)
        .then(response => setUserConfirmed(response.data));
    } catch {
      //
    } finally {
      setIsLoading(false);
    }
  }, [idConfirmedUser, user]);

  async function handleConfirmedUser() {
    try {
      await api.get(`admin/user/confirm/${idConfirmedUser}`);

      toast({
        title: 'Investidor ativado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      handleConfirmedUserClose();
    } catch (error) {
      toast({
        title: 'Erro ao ativar o usuário!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  async function handleCancelUser() {
    try {
      setIsLoadingWarningMessage(true);

      if (!warningMessage || warningMessage === '') {
        throw new Error('Nenhuma mensagem foi informada');
      }

      await api.put(`admin/user/deny/${idConfirmedUser}`, {
        message: warningMessage,
      });

      toast({
        title: 'Mensagem enviada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      setIsLoadingWarningMessage(false);

      handleConfirmedUserClose();
    } catch (error) {
      setIsLoadingWarningMessage(false);

      toast({
        title: 'Erro ao enviar a mensagem!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: error.message,
        position: 'top-right',
      });
    }
  }

  useEffect(() => {
    if (idConfirmedUser === 0) return;

    try {
      setIsLoading(true);
      api
        .get(`franchises/user/user/${idConfirmedUser}`)
        .then(response => setSelectedFranchised(response.data));
    } catch {
      //
    } finally {
      setIsLoading(false);
    }
  }, [user, idConfirmedUser]);

  if (isLoading) {
    return (
      <Modal
        isOpen={isOpenConfirmedUserModal}
        onClose={handleConfirmedUserClose}
      >
        <ModalOverlay />

        <ModalContent bg="gray.800" p="2">
          <ModalCloseButton />

          <ModalBody>
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  if (!userConfirmed) {
    return (
      <Modal
        isOpen={isOpenConfirmedUserModal}
        onClose={handleConfirmedUserClose}
      >
        <ModalOverlay />

        <ModalContent bg="gray.800" p="2">
          <ModalCloseButton />

          <ModalBody>
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpenConfirmedUserModal}
      onClose={handleConfirmedUserClose}
      size="full"
    >
      <ModalOverlay />

      <ModalContent bg="gray.800" p="2">
        <ModalHeader>
          <Flex gap={4}>
            <Heading>Informações </Heading>

            {userConfirmed.isMigration && (
              <Flex align="center" justify="center" gap="4" color="yellow.300">
                -
                <WarningIcon />
                Usuário de migração
              </Flex>
            )}
          </Flex>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Flex flexDir="column" gridGap="4">
            <Box>
              <Flex align="center" justify="space-between">
                <Heading>Dados pessoais</Heading>

                {userConfirmed.identityImage ? (
                  <Link
                    href={`data:${userConfirmed.identityImage.contentType};base64, ${userConfirmed.identityImage.content}`}
                    target="_blank"
                    download
                    display="flex"
                    gridGap="2"
                    alignItems="center"
                  >
                    Comprovante de identidade
                    <Box ml="auto" color="teal.400">
                      <FiDownload />
                    </Box>
                  </Link>
                ) : (
                  <Text fontWeight="bold" fontSize="lg">
                    Usuário ainda não enviou o comprovante de identificação.
                  </Text>
                )}
              </Flex>

              <Flex
                mt="4"
                border="solid"
                p="4"
                borderColor="#666"
                rounded={10}
                wrap="wrap"
                gridGap="6"
              >
                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Nome:
                  </Text>
                  <Text>{userConfirmed.name}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Documento:
                  </Text>
                  <Text>{userConfirmed.cpf}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    E-mail:
                  </Text>
                  <Text>{userConfirmed.email}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Telefone 1:
                  </Text>
                  <Text>{userConfirmed.phoneNumber}</Text>
                </Flex>

                {userConfirmed.phoneNumberAux !== 'null' && (
                  <Flex wrap="nowrap" align="baseline" gridGap="2">
                    <Text fontWeight="bold" fontSize="lg">
                      Telefone 2:
                    </Text>
                    <Text>{userConfirmed.phoneNumberAux}</Text>
                  </Flex>
                )}

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Data de nascimento:
                  </Text>
                  <Text>
                    {format(new Date(userConfirmed.birthDate), 'dd/MM/yyyy')}
                  </Text>
                </Flex>
              </Flex>
            </Box>

            <Box>
              <Flex align="center" justify="space-between">
                <Heading>Endereço</Heading>

                {userConfirmed.adressImage ? (
                  <Link
                    href={`data:${userConfirmed.adressImage.contentType};base64, ${userConfirmed.adressImage.content}`}
                    target="_blank"
                    download
                    display="flex"
                    gridGap="2"
                    alignItems="center"
                  >
                    Comprovante de endereço
                    <Box ml="auto" color="teal.400">
                      <FiDownload />
                    </Box>
                  </Link>
                ) : (
                  <Text fontWeight="bold" fontSize="lg">
                    Usuário ainda não enviou o comprovante de endereço.
                  </Text>
                )}
              </Flex>

              <Flex
                mt="4"
                border="solid"
                p="4"
                borderColor="#666"
                rounded={10}
                wrap="wrap"
                gridGap="6"
              >
                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Cep:
                  </Text>
                  <Text>{userConfirmed.cep}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Rua:
                  </Text>
                  <Text>{userConfirmed.street}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Bairro:
                  </Text>
                  <Text>{userConfirmed.neighborhood}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Cidade:
                  </Text>
                  <Text>{userConfirmed.city}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Número:
                  </Text>
                  <Text>{userConfirmed.number}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    Estade:
                  </Text>
                  <Text>{userConfirmed.state}</Text>
                </Flex>

                <Flex wrap="nowrap" align="baseline" gridGap="2">
                  <Text fontWeight="bold" fontSize="lg">
                    País:
                  </Text>
                  <Text>{userConfirmed.country}</Text>
                </Flex>

                {userConfirmed.complement !== 'null' && (
                  <Flex wrap="nowrap" align="baseline" gridGap="2">
                    <Text fontWeight="bold" fontSize="lg">
                      Complemento:
                    </Text>
                    <Text>{userConfirmed.complement}</Text>
                  </Flex>
                )}
              </Flex>
            </Box>

            <Flex w="100%" align="center" gridGap={5}>
              {userConfirmed.role === 2 && (
                <Box w="100%">
                  <Flex align="center" justify="space-between">
                    <Heading>Franquia</Heading>

                    {selectedFranchised.franchises &&
                    selectedFranchised.franchises.length > 0 &&
                    selectedFranchised.franchises[0].proofPayment ? (
                      <Link
                        href={`data:${selectedFranchised.franchises[0].proofPayment.contentType};base64, ${selectedFranchised.franchises[0].proofPayment.content}`}
                        target="_blank"
                        download={`${selectedFranchised.franchises[0].proofPayment.name}`}
                        display="flex"
                        gridGap="2"
                        alignItems="center"
                      >
                        Comprovante de pagamento de franquia
                        <Box ml="auto" color="teal.400">
                          <FiDownload />
                        </Box>
                      </Link>
                    ) : (
                      <Text fontWeight="bold" fontSize="lg">
                        Usuário ainda não enviou o comprovante de pagamento da
                        franquia.
                      </Text>
                    )}
                  </Flex>

                  {userConfirmed.role === 2 && (
                    <Flex
                      border="solid"
                      p="4"
                      borderColor="#666"
                      rounded={10}
                      wrap="wrap"
                      gridGap="6"
                      mt="4"
                    >
                      {selectedFranchised.franchises &&
                        selectedFranchised.franchises.length > 0 && (
                          <>
                            <Flex wrap="nowrap" align="baseline" gridGap="2">
                              <Text fontWeight="bold" fontSize="lg">
                                Franquia:
                              </Text>
                              <Text>
                                {
                                  selectedFranchised.franchises[0].franchise
                                    .name
                                }
                              </Text>
                            </Flex>

                            <Flex wrap="nowrap" align="baseline" gridGap="2">
                              <Text fontWeight="bold" fontSize="lg">
                                Valor:
                              </Text>
                              <Text>
                                {convertCurrencyBrazil(
                                  selectedFranchised.franchises[0].franchise
                                    .price,
                                )}
                              </Text>
                            </Flex>
                          </>
                        )}
                    </Flex>
                  )}
                </Box>
              )}
            </Flex>

            {isOpenWarningMessage && (
              <Flex
                w="100%"
                position="relative"
                border="solid"
                rounded={10}
                p="4"
                flexDir="column"
              >
                <Heading mb="4">Informe o motivo</Heading>

                <IconButton
                  aria-label="Fechar mensagem de aviso"
                  icon={<FiX size={20} />}
                  variant="link"
                  position="absolute"
                  right="0"
                  top="3"
                  onClick={() => setIsOpenWarningMessage(false)}
                />

                <Textarea
                  name="warningMessage"
                  label="Digite o motivo pelo qual o investidor teve o cadastro negado"
                  onChange={event => setWarningMessage(event.target.value)}
                />

                <Button
                  alignSelf="center"
                  mt="4"
                  colorScheme="whatsapp"
                  onClick={handleCancelUser}
                  type="button"
                  isLoading={isLoadingWarningMessage}
                >
                  Enviar mensagem
                </Button>
              </Flex>
            )}

            {!isOpenWarningMessage && (
              <Flex gridGap="4" ml="auto">
                <Button
                  type="button"
                  onClick={handleConfirmedUser}
                  colorScheme="green"
                >
                  Confirmar Dados
                </Button>

                <Button
                  type="button"
                  onClick={() => setIsOpenWarningMessage(true)}
                  colorScheme="red"
                >
                  Negar cadastro
                </Button>
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
