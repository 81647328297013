import React from 'react';
import { Flex, Box, Link } from '@chakra-ui/react';

import { DownloadIcon } from '@chakra-ui/icons';

import contrato from '../../assets/contrato.pdf';
import apolice from '../../assets/apolice.pdf';

export function ContractsDocuments(): JSX.Element {
  return (
    <Flex flexDir="row" pb="4">
      <Box px="100">
        <Link href={contrato} download>
          Contrato
          <DownloadIcon mx="2px" />
        </Link>
      </Box>
      <Box px="100">
        <Link href={apolice} download>
          Apólice de Seguro
          <DownloadIcon mx="2px" />
        </Link>
      </Box>
    </Flex>
  );
}
