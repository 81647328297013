import React from 'react';
import { Button, Flex, useToast } from '@chakra-ui/react';
import * as yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '../Input';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';

const bankFormSchema = yup.object().shape({
  digitalWallet: yup
    .string()
    .required('Informe sua carteira digital')
    .nullable(),
});

type BankFormData = {
  digitalWallet: string;
};

export function BoxBankClient(): JSX.Element {
  const { user, refreshUser } = useAuth();
  const toast = useToast();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(bankFormSchema),
    defaultValues: {
      digitalWallet: user.digitalWallet,
    },
  });

  const { errors } = formState;

  const handleUpdateBank: SubmitHandler<BankFormData> = async data => {
    try {
      await api.put(`users/updatebank/${user.id}`, data);

      await refreshUser();

      toast({
        title: 'Dados bancários salvos com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro na atualização dos dados bancários!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        description: status === 400 || status === 404 ? data : '',
      });
    }
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(handleUpdateBank)}
      align="center"
      justify="space-around"
      flexDir="column"
      gridGap="4"
      rounded="10px"
      color="white"
      flex="1"
      p="4"
      bg="#0C6E9C"
    >
      <Input
        label="Sua carteira para recebimento: BUSD (Rede BSC - Bep20)"
        placeholder="Número da carteira digital"
        error={errors.digitalWallet}
        {...register('digitalWallet')}
      />

      <Button
        colorScheme="green"
        width="100%"
        maxW="320px"
        type="submit"
        isLoading={formState.isSubmitting}
      >
        Salvar
      </Button>
    </Flex>
  );
}
