import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Button,
} from '@chakra-ui/react';

import { FiSearch } from 'react-icons/fi';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { api } from '../../service/api';
import {
  convertCurrencyAmericanNumber,
  convertCurrencyBrazil,
} from '../../utils/convertData';
import { Input } from '../Input';
import { useSortableData } from '../../hooks/useSortableData';

interface WithdrawByUserProps {
  name: string;
  username: string;
  withdrawAmount: string;
}

interface ApiResponse {
  name: string;
  username: string;
  withdrawAmount: number;
}

export function WithdrawByUser() {
  const [withdraws, setWithdraws] = useState<ApiResponse[]>([]);
  const [withdrawsFormatted, setWithdrawsFormatted] = useState<
    WithdrawByUserProps[]
  >([]);
  const [userName, setUserName] = useState('');

  const { items, requestSort, sortConfig } = useSortableData({
    items: withdrawsFormatted,
    config: { key: 'name', direction: undefined, type: undefined },
  });

  useEffect(() => {
    try {
      api.get<ApiResponse[]>('admin/loot-expectation').then(response => {
        setWithdraws(response.data);
        const data = response.data.map(item => {
          const { withdrawAmount } = item;

          return {
            ...item,
            withdrawAmount: `$ ${convertCurrencyBrazil(withdrawAmount)}`,
          };
        });

        setWithdrawsFormatted(data);
      });
    } catch {
      // Tratar error
    }
  }, []);

  const withdrawsFilttered = useMemo(() => {
    const name = userName.toLocaleLowerCase();

    return items.filter(
      item =>
        item.name?.toLowerCase().includes(name) ||
        item.username?.toLowerCase().includes(name),
    );
  }, [userName, items]);

  const totalWithdraw = useMemo(() => {
    const withdrawsAmount = withdraws.map(item =>
      convertCurrencyAmericanNumber(item.withdrawAmount),
    );

    const total = withdrawsAmount.reduce((acc, item) => acc + item, 0);

    return `$ ${convertCurrencyBrazil(total)}`;
  }, [withdraws]);

  const getIconSort = (direction: 'ascending' | 'descending'): ReactElement => {
    return direction === 'ascending' ? <FaSortDown /> : <FaSortUp />;
  };

  const getIconFor = (name: string): ReactElement | undefined => {
    if (!sortConfig || !sortConfig.direction) {
      return <FaSort />;
    }

    return sortConfig.key === name
      ? getIconSort(sortConfig.direction)
      : undefined;
  };

  return (
    <Flex flexDir="column" py="4">
      <Flex w="100%" bg="gray.800" p={5}>
        <Box w="30%" mr="auto">
          <Input
            name="search"
            icon={<FiSearch color="gray.300" />}
            placeholder="Buscar usuário"
            value={userName}
            onChange={event => setUserName(event.target.value)}
          />
        </Box>

        <Flex align="center" gridGap="4" mr="auto">
          <Text fontSize="lg" fontWeight="bold">
            Total em saque
          </Text>
          <Box p="2" bg="blue.600" rounded={10}>
            <Text fontSize="lg" fontWeight="bold">
              {totalWithdraw}
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Box>
        <Table bg="#0C6E9C" mt="10px" rounded={10}>
          <Thead>
            <Tr>
              <Th color="#FFF">Nome</Th>
              <Th color="#FFF">Usuário</Th>
              <Th color="#FFF">
                <Flex align="center">
                  {getIconFor('withdrawAmount')}
                  <Button
                    color="#fff"
                    variant="link"
                    type="button"
                    textTransform="uppercase"
                    fontSize="12px"
                    onClick={() => requestSort('withdrawAmount', 'number')}
                  >
                    Valor
                  </Button>
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdrawsFilttered.map(withdraw => (
              <Tr key={withdraw.username}>
                <Td>{withdraw.name}</Td>
                <Td>{withdraw.username}</Td>
                <Td>{withdraw.withdrawAmount}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  );
}
