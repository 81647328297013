import { FormEvent } from 'react';

export function cep(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 9;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  // value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function currency(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value;
  return e;
}

export function percentual(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 6;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');

  if (value.length === 2) {
    value = value.replace(/(\d)(\d)$/, '$1,$2');
    e.currentTarget.value = value;
    return e;
  }

  if (value.length >= 6 && Number(value) === 100) {
    value = value.substr(0, value.length);

    value = value.replace(/(\d{3})(\d{2})$/, '$1,$2');

    e.currentTarget.value = value;

    return e;
  }

  value = value.replace(/(\d)(\d{2})$/, '$1,$2');

  if (Number(value.replace(',', '.')) > 100) {
    value = value.replace(',', '').substr(0, value.length - 1);
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  }

  e.currentTarget.value = value;
  return e;
}

export function cpf(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 25;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  // value = value.replace(/^(\d)/g, '$1');

  // if (value.length <= 11) {
  //   value = value.replace(/(\d{3})(\d)/, '$1.$2');
  //   value = value.replace(/(\d{3})(\d)/, '$1.$2');
  //   value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  // } else {
  //   value = value.replace(/^(\d{2})(\d)/, '$1.$2');
  //   value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  //   value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
  //   value = value.replace(/(\d{4})(\d)/, '$1-$2');
  // }
  e.currentTarget.value = value;
  return e;
}

export function date(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 10;
  let { value } = e.currentTarget;
  if (!value.match(/^(\d{2})\/(\d{2})\/(\d{4})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d)/, '$1/$2');
    value = value.replace(/(\d{2})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }
  e.currentTarget.value = value;
  return e;
}

export function mobile(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 25;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d)/g, '$1');
  // // value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  // // value = value.replace(/(\d{5})(\d{4})$/, '$1-$2');

  e.currentTarget.value = value;
  return e;
}

export function phone(
  e: React.FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> {
  e.currentTarget.maxLength = 25;
  let { value } = e.currentTarget;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d)/g, '$1');
  // value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  // value = value.replace(/(\d{4})(\d{4})$/, '$1-$2');

  e.currentTarget.value = value;
  return e;
}
