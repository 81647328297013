import { formatISO, format } from 'date-fns';

export function ConvertDateAmerican(date: Date | string): string {
  let dateString = '';
  let result = '';

  if (typeof date !== 'string') {
    dateString = format(date, 'dd/MM/yyyy');
  } else {
    dateString = date;
  }

  const [day, month, year] = dateString.split('/');

  result = formatISO(new Date(Number(year), Number(month) - 1, Number(day)), {
    representation: 'date',
  });

  return result;
}

export function convertCurrencyAmerican(amount: number | string): string {
  let amountTypeNumber = 0;

  if (typeof amount === 'string') {
    amountTypeNumber = Number.parseFloat(
      amount
        .replace(/[^\d,]+/g, '') // Remove caracteres desnecessários.
        .replace(',', '.'), // Troca o separador decimal (`,` -> `.`)
    );
  } else {
    amountTypeNumber = amount;
  }

  const amountFormatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amountTypeNumber);

  return amountFormatted;
}

export function convertCurrencyAmericanNumber(amount: number | string): number {
  let amountTypeNumber = 0;

  if (typeof amount === 'string') {
    amountTypeNumber = Number(
      amount
        .replace(/[^\d,]+/g, '') // Remove caracteres desnecessários.
        .replace(',', '.'), // Troca o separador decimal (`,` -> `.`)
    );
  } else {
    amountTypeNumber = amount;
  }

  const amountFormatted = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amountTypeNumber);

  return parseFloat(amountFormatted.replaceAll(',', ''));
}

export function convertCurrencyBrazil(amount: number): string {
  const amountFormatted = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return amountFormatted;
}
