import React, { useEffect, useMemo, useState } from 'react';
import {
  Flex,
  Box,
  Heading,
  IconButton,
  Button,
  List,
  ListItem,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { FiMinus, FiPlus, FiSearch } from 'react-icons/fi';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useHistory, useLocation } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { api } from '../../service/api';
import { Input } from '../../components/Input';
import { LoadingSpinner } from '../../components/LoadingSpinner';

interface ProfitabilityGroup {
  id: number;
  profitabilityBonus: number;
  referralBonus: number;
}

interface User {
  id: number;
  activeStatus: number;
  name: string;
  profitabilityGroup: ProfitabilityGroup;
  role: number;
}

type GroupFormData = {
  groupName: string;
  profitabilityBonus: string;
  referralBonus: string;
};

interface NewGroup {
  id: number;
}

const groupFormSchema = yup.object().shape({
  groupName: yup.string().required('Nome do grupo obrigatório'),
  profitabilityBonus: yup.string().required('Percentual obrigatório'),
  referralBonus: yup.string().required('Percentual obrigatório'),
});

export function NewProfitabilityGroups(): JSX.Element {
  const { pathname } = useLocation();
  const groupId = pathname
    .replaceAll('/', '')
    .replace('new-profitability-groups', '');

  const toast = useToast();
  const history = useHistory();

  const [users, setUsers] = useState<User[]>([]);
  const [usersAddGroup, setUsersAddGroup] = useState<User[]>([]);
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(groupFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (!groupId) return;

    setIsLoading(true);

    try {
      api.get(`profitabilityGroups/${groupId}`).then(response => {
        setValue('groupName', response.data.groupName);
        setValue(
          'profitabilityBonus',
          String(response.data.profitabilityBonus).replace('.', ','),
        );
        setValue(
          'referralBonus',
          String(response.data.referralBonus).replace('.', ','),
        );
      });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [groupId, setValue]);

  useEffect(() => {
    setUsersAddGroup([]);
    setIsLoading(true);
    try {
      api
        .get<User[]>('users')
        .then(response =>
          setUsers(
            response.data.filter(
              user =>
                user.activeStatus === 1 &&
                user.role !== 0 &&
                !user.profitabilityGroup,
            ),
          ),
        );
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, []);

  const userFiltered = useMemo(() => {
    const lowerCase = userName?.toLowerCase();
    return users.filter(user => user.name?.toLowerCase().includes(lowerCase));
  }, [userName, users]);

  function handleAddUserGroup(idUser: number) {
    const findUser = users.find(user => user.id === idUser);

    if (!findUser) return;

    setUsers(state => state.filter(user => user.id !== idUser));

    setUsersAddGroup(state => [...state, findUser]);
  }

  function handleRemoveAddGroup(idUser: number) {
    const findUser = usersAddGroup.find(user => user.id === idUser);

    if (!findUser) return;

    setUsersAddGroup(state => state.filter(user => user.id !== idUser));

    setUsers(state => [...state, findUser]);
  }

  const handleFormGroup: SubmitHandler<GroupFormData> = async values => {
    try {
      if (groupId) {
        const { groupName, profitabilityBonus, referralBonus } = values;

        await api.put(`profitabilityGroups/${groupId}`, {
          groupName,
          profitabilityBonus: Number(profitabilityBonus.replace(',', '.')),
          referralBonus: Number(referralBonus.replace(',', '.')),
        });

        await api.put(`profitabilityGroups/users/${groupId}`, usersAddGroup);
      } else {
        const { groupName, profitabilityBonus, referralBonus } = values;

        const response = await api.post<NewGroup>('profitabilityGroups', {
          groupName,
          profitabilityBonus: Number(profitabilityBonus.replace(',', '.')),
          referralBonus: Number(referralBonus.replace(',', '.')),
        });

        await api.put(
          `profitabilityGroups/users/${response.data.id}`,
          usersAddGroup,
        );
      }

      toast({
        title: `Grupo ${groupId ? 'editado' : 'adicionado'} com sucesso.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      history.goBack();
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro ao criar um novo grupo!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? data : '',
        position: 'top-right',
      });
    }
  };

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex w="100%" flexDir="column">
        <Header />

        <Flex flexDir="column" p="6">
          <Heading mb="3">
            {groupId ? 'Editar' : 'Novo'} grupo de rendimento
          </Heading>

          <Flex
            as="form"
            onSubmit={handleSubmit(handleFormGroup)}
            alignSelf="center"
            bg="gray.800"
            p="8"
            maxW="1120px"
            w="100%"
            flexDir="column"
            gridGap="4"
          >
            <Box>
              <Input
                placeholder="Nome do grupo"
                label="Nome do grupo"
                error={errors.groupName}
                {...register('groupName')}
              />

              <Flex mt="4" gridGap="4">
                <Input
                  icon="%"
                  mask="percentual"
                  placeholder="Rendimento"
                  label="Percentual Rendimento"
                  error={errors.profitabilityBonus}
                  {...register('profitabilityBonus')}
                />

                <Input
                  icon="%"
                  mask="percentual"
                  placeholder="Percentual de rendimento da indicação"
                  label="Percentual de rendimento da indicação"
                  error={errors.referralBonus}
                  {...register('referralBonus')}
                />
              </Flex>
            </Box>

            <Flex>
              <Flex gridGap="4" align="center" w="100%" justify="space-between">
                <Flex
                  flexDir="column"
                  flex="1"
                  bg="gray.900"
                  h="100%"
                  maxH={isLargerThan1368 ? '400px' : '250px'}
                  padding="4"
                >
                  <Input
                    name="search"
                    border="solid !important"
                    borderColor="#1F2029 !important"
                    icon={<FiSearch color="gray.300" />}
                    placeholder="Buscar investidor"
                    value={userName}
                    onChange={event => setUserName(event.target.value)}
                  />

                  <Box mt="4" h="100%" overflowY="auto">
                    <List>
                      {userFiltered.map(user => (
                        <ListItem
                          p="2"
                          borderBottom="solid"
                          borderBottomWidth="1px"
                          key={user.id}
                        >
                          <Flex align="center" justify="space-between" w="100%">
                            {user.name}
                            <IconButton
                              aria-label="Adicionar user ao grupo"
                              variant="link"
                              colorScheme="blue"
                              icon={<FiPlus />}
                              onClick={() => handleAddUserGroup(user.id)}
                            />
                          </Flex>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Flex>

                <Box
                  flex="1"
                  bg="gray.900"
                  h="100%"
                  maxH={isLargerThan1368 ? '400px' : '250px'}
                  padding="4"
                >
                  <Box mt="4" h="100%" overflowY="auto">
                    <List>
                      {usersAddGroup.map(user => (
                        <ListItem
                          p="2"
                          borderBottom="solid"
                          borderBottomWidth="1px"
                          key={user.id}
                        >
                          <Flex align="center" justify="space-between" w="100%">
                            {user.name}
                            <IconButton
                              aria-label="Adicionar user ao grupo"
                              variant="link"
                              colorScheme="red"
                              icon={<FiMinus />}
                              onClick={() => handleRemoveAddGroup(user.id)}
                            />
                          </Flex>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Box>
              </Flex>
            </Flex>

            <Flex gridGap="4" justify="center">
              <Button
                type="submit"
                isLoading={formState.isSubmitting}
                colorScheme="green"
              >
                Salvar
              </Button>

              <Button
                type="button"
                colorScheme="red"
                variant="outline"
                onClick={() => history.goBack()}
              >
                Voltar
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
