import React, { useEffect, useState } from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { BoxBank } from './BoxBank';
import { BoxBankClient } from './BoxBankClient';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

interface User {
  id: number;
  bankName: string;
  bankAgency: string;
  bankAccount: string;
  bankAccountType: string;
  pix: string;
  digitalWallet: string;
  role: number;
}

export function BankUser() {
  const { pathname } = useLocation();
  const [user, setUser] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const userId = pathname.replaceAll('/', '').replace('edit-user-adm', '');

  useEffect(() => {
    if (!userId || userId === '0') return;
    try {
      setIsLoading(true);
      api.get(`users/${userId}`).then(response => setUser(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex w="100%" gridGap="4" flexDir="column" h="100%">
      <Flex gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
        {user.role === 2 ? (
          <BoxBank
            id={user.id}
            bankAccount={user.bankAccount}
            bankAccountType={user.bankAccountType}
            bankAgency={user.bankAgency}
            bankName={user.bankName}
            digitalWallet={user.digitalWallet}
            pix={user.pix}
          />
        ) : (
          <BoxBankClient id={user.id} digitalWallet={user.digitalWallet} />
        )}
      </Flex>
    </Flex>
  );
}
