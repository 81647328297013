import React from 'react';
import { Box, Flex, Avatar, Text, Icon } from '@chakra-ui/react';
import { FiCalendar, FiBook } from 'react-icons/fi';
import { format } from 'date-fns';

import { useAuth } from '../../hooks/auth';

export function BoxUser(): JSX.Element {
  const { user } = useAuth();
  return (
    <Flex
      align="center"
      justify="center"
      flexDir="column"
      gridGap="4"
      bg="#0C6E9C"
      rounded="10px"
      flex="1"
      maxW="500px"
    >
      <Avatar size="md" name={user.name} mt="4" />

      <Box textAlign="center">
        <Text fontSize="2xl">{user.name}</Text>
        <Text color="gray.200">{user.email}</Text>
      </Box>

      {user.cpf && (
        <Flex gridGap="4">
          <Flex flexDir="column" align="center" justify="center" gridGap="2">
            <Icon as={FiBook} w={8} h={8} />
            <Text>{user.cpf}</Text>
          </Flex>

          <Flex flexDir="column" align="center" justify="center" gridGap="2">
            <Icon as={FiCalendar} w={8} h={8} />
            <Text>{format(new Date(user.birthDate), 'dd/MM/yyyy')}</Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
