import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Heading,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input } from '../Input';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

type NotesFormData = {
  notes: string;
  profitabilityDayMonth: number;
  automaticWithdraw: string;
  isMigration: string;
};

const notesFormSchema = yup.object().shape({
  notes: yup.string(),
  profitabilityDayMonth: yup
    .number()
    .typeError('Informe um valor entre 0 e 31')
    .min(0, 'Digite um valor entre 0 e 31')
    .max(31, 'Digite um valor entre 0 e 31')
    .nullable(),
});

interface User {
  name: string;
  notes: string;
  profitabilityDayMonth: number;
  automaticWithdraw: boolean;
  isMigration: boolean;
}

export function Notes(): JSX.Element {
  const { pathname } = useLocation();
  const [user, setUser] = useState<User>({} as User);

  const toast = useToast();

  const userId = pathname.replaceAll('/', '').replace('edit-user-adm', '');

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(notesFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (!userId || userId === '0') return;

    try {
      api.get<User>(`users/${userId}`).then(response => setUser(response.data));
    } catch (error) {
      //
    }
  }, [userId]);

  useEffect(() => {
    setValue('notes', user.notes);
    setValue('automaticWithdraw', user.automaticWithdraw ? 'yes' : 'no');
    setValue('profitabilityDayMonth', user.profitabilityDayMonth);
    setValue('isMigration', user.isMigration ? 'yes' : 'no');
  }, [
    setValue,
    user.notes,
    user.automaticWithdraw,
    user.profitabilityDayMonth,
    user.isMigration,
  ]);

  const functionHandleNoteUser: SubmitHandler<NotesFormData> = async data => {
    try {
      const { automaticWithdraw, notes, profitabilityDayMonth, isMigration } =
        data;

      await api.put(`users/dataAditional/${userId}`, {
        notes,
        profitabilityDayMonth,
        automaticWithdraw: automaticWithdraw === 'yes',
        isMigration: isMigration === 'yes',
      });

      toast({
        title: 'Nota adicionada com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      const { data, status } = error.response;

      toast({
        title: 'Erro ao adicionar/editar a nota ao usuário!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? data : '',
        position: 'top-right',
      });
    }
  };

  if (!user) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex>
      <Flex flexDir="column" w="100%">
        <Heading flexWrap="nowrap">
          <Text as="strong" color="green.400">
            {user.name}
          </Text>
        </Heading>

        <Flex
          bg="gray.800"
          p="4"
          flexDir="column"
          as="form"
          onSubmit={handleSubmit(functionHandleNoteUser)}
        >
          <Flex
            align="center"
            justify="center"
            w="100%"
            p="4"
            gridGap="10"
            position="relative"
          >
            <Stack w="100%" maxW="600px">
              <Text>Mensagem</Text>
              <Textarea
                defaultValue={user.notes}
                error={errors.notes}
                {...register('notes')}
              />
            </Stack>

            <Stack w="100%" maxW="600px">
              <Text>Dia do rendimento</Text>
              <Input
                type="number"
                max="31"
                defaultValue={user.profitabilityDayMonth}
                error={errors.profitabilityDayMonth}
                {...register('profitabilityDayMonth')}
              />
            </Stack>
          </Flex>

          <Flex gridGap="5" fontSize="xl" w="100%" justifyContent="center">
            <Flex flexDir="column" w="300px">
              <Text>Usuário de migração?</Text>
              <Select
                placeholder="Selecione uma opção"
                {...register('isMigration')}
              >
                <option value="yes" style={{ background: '#353646' }}>
                  Sim
                </option>
                <option value="no" style={{ background: '#353646' }}>
                  Não
                </option>
              </Select>
            </Flex>

            <Flex flexDir="column" w="300px">
              <Text>Habilitar saque automático?</Text>
              <Select
                placeholder="Selecione uma opção"
                {...register('automaticWithdraw')}
              >
                <option value="yes" style={{ background: '#353646' }}>
                  Sim
                </option>
                <option value="no" style={{ background: '#353646' }}>
                  Não
                </option>
              </Select>
            </Flex>
          </Flex>

          <Button
            type="submit"
            colorScheme="green"
            maxW="300px"
            w="100%"
            alignSelf="center"
            mt="8"
            isLoading={formState.isSubmitting}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
