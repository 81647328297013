import React from 'react';
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { EditUser } from '../../components/EditUserAdm/EditUser';
import { Notes } from '../../components/EditUserAdm/Notes';
import { BankUser } from '../../components/EditUserAdm/BankUser';
import { PasswordChange } from '../../components/EditUserAdm/PasswordChange';

export function EditUserAdimistrator(): JSX.Element {
  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex flexDir="column" w="100%">
        <Header />

        <Box h="100%" p="4">
          <Tabs isFitted variant="soft-rounded" colorScheme="gray">
            <TabList>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Informação do investidor
              </Tab>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Dados bancários
              </Tab>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Configurações
              </Tab>
              <Tab color="#fff" bg="#0C6E9C" mr="8px">
                Alterar acesso
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditUser />
              </TabPanel>
              <TabPanel>
                <BankUser />
              </TabPanel>
              <TabPanel>
                <Notes />
              </TabPanel>
              <TabPanel>
                <PasswordChange />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Flex>
  );
}
