import React, { useEffect, useState } from 'react';
import { Box, Circle, IconButton, Icon, Text } from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';
import { useReactModal } from '../contexts/ReactModal';
import { api } from '../service/api';
import { useAuth } from '../hooks/auth';

interface Message {
  id: number;
  content: string;
  status: number;
  updatedDate: Date;
}

export function Messages(): JSX.Element {
  const [messages, setMessages] = useState<Message[]>([]);
  const [numberMessagesRead, setNumberMessagesRead] = useState(0);
  const { handleOpenListMessagesModal } = useReactModal();
  const { user, validToken } = useAuth();

  useEffect(() => {
    try {
      api
        .get('notices/user', {
          headers: { Authorization: `Bearer ${validToken()}` },
        })
        .then(response => setMessages(response.data));
    } catch (error) {
      //
    }
  }, [validToken]);

  useEffect(() => {
    const messagesLocalStorage = localStorage.getItem('@Active:messages');

    if (!messagesLocalStorage) {
      setNumberMessagesRead(messages.length);
      handleOpenListMessagesModal();
      return;
    }

    const messagesObject = JSON.parse(messagesLocalStorage || '') as Message[];
    let numberMessages = 0;

    messages.forEach(message => {
      const isExistsIndex = messagesObject.findIndex(
        item => message.id === item.id && message.content === item.content,
      );

      if (isExistsIndex === -1) numberMessages++;
    });

    setNumberMessagesRead(numberMessages);

    if (numberMessages > 0) handleOpenListMessagesModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, handleOpenListMessagesModal]);

  return (
    <>
      {messages.length > 0 && user.role !== 0 && user.activeStatus === 1 && (
        <Box position="relative">
          {numberMessagesRead > 0 && (
            <Circle
              position="absolute"
              size="20px"
              bg="orange.500"
              color="white"
              zIndex={1}
            >
              <Text>{numberMessagesRead}</Text>
            </Circle>
          )}

          <IconButton
            aria-label="Menssagens"
            icon={<Icon as={FiBell} />}
            fontSize="24"
            variant="unstyled"
            onClick={handleOpenListMessagesModal}
          />
        </Box>
      )}
    </>
  );
}
