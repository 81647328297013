import React from 'react';

import { Flex, useBreakpointValue } from '@chakra-ui/react';
import { BoxBank } from './BoxBank';
import { BoxUser } from './BoxUser';
import { RecoverPassword } from './RecoverPassword';
import { useAuth } from '../../hooks/auth';
import { BoxBankClient } from './BoxBankClient';

export function GeneralData(): JSX.Element {
  const { user } = useAuth();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex w="100%" gridGap="4" flexDir="column" h="100%">
      <Flex gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
        <BoxUser />
        {user.role === 2 ? <BoxBank /> : <BoxBankClient />}
      </Flex>
      <RecoverPassword />
    </Flex>
  );
}
