import React, { KeyboardEvent, useEffect, useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useMediaQuery,
  useDisclosure,
  Button,
  Collapse,
  Flex,
  Text,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import ptBR from 'date-fns/locale/pt-BR';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { addDays, format, setHours } from 'date-fns';
import { useAuth, User } from '../../hooks/auth';
import { api } from '../../service/api';
import { Input } from '../Input';
import { Select } from '../Select';
import { ConvertDateAmerican } from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';

interface WithdrawalsData {
  id: number;
  user: User;
  description: string;
  paydayDate: Date;
  estimatePayday: Date;
  date: Date;
  status: string;
  amount: number;
  netAmount: number;
  bankName: string;
  bankAgency: string;
  bankAccount: string;
  digitalWallet: string;
}

interface WithdrawalsDataFormatted {
  id: number;
  user: User;
  description: string;
  paydayDate: string;
  date: string;
  status: string;
  amount: number;
  netAmount: number;
  bankName: string;
  bankAgency: string;
  bankAccount: string;
  digitalWallet: string;
}

export function Withdrawals(): JSX.Element {
  const { user } = useAuth();

  const [withdrawals, setWithdrawals] = useState<WithdrawalsDataFormatted[]>(
    [],
  );
  const [amount, setAmount] = useState(0);
  const [statusData, setStatusData] = useState('');
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [isLoadingClearFilter, setIsLoadingClearFilter] = useState(false);
  const [initialDate, setInitialDate] = useState(addDays(new Date(), -7));
  const [finalDate, setFinalDate] = useState(new Date());

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  useEffect(() => {
    try {
      api.get<WithdrawalsData[]>(`withdraws/user/${user.id}`).then(response => {
        const formattedWithdrawals: WithdrawalsDataFormatted[] =
          response.data.map(withdrawal => {
            const { paydayDate, estimatePayday, ...rest } = withdrawal;

            const realDate =
              String(paydayDate) === '0001-01-01T00:00:00'
                ? estimatePayday
                : paydayDate;

            return {
              ...rest,
              paydayDate: format(new Date(realDate), 'dd/MM/yyyy'),
              date: format(new Date(withdrawal.date), 'dd/MM/yyyy'),
            };
          });

        setWithdrawals(formattedWithdrawals);
      });
    } catch (error) {
      //
    }
  }, [user.id]);

  useEffect(() => {
    if (isLargerThan1368) {
      onOpen();
    } else {
      onClose();
    }
  }, [isLargerThan1368, onOpen, onClose]);

  function handleInitialDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setInitialDate(setHours(date, 0));
  }

  function handleFinalDate(date: Date | [Date, Date] | null) {
    if (!date) return;

    if (Array.isArray(date)) return;

    setFinalDate(setHours(date, 23));
  }

  async function handleFilterWithdrawals() {
    try {
      setIsLoadingFilter(true);

      const initalDateRequest = ConvertDateAmerican(initialDate);
      const finalDateRequest = ConvertDateAmerican(addDays(finalDate, 1));

      await api
        .get<WithdrawalsData[]>(
          `withdraws/user/${user.id}?amount=${amount}&initialDate=${initalDateRequest}&finalDate=${finalDateRequest}&status=${statusData}`,
        )
        .then(response => {
          const formattedWithdrawals: WithdrawalsDataFormatted[] =
            response.data.map(withdrawal => {
              const { paydayDate, estimatePayday, ...rest } = withdrawal;

              const realDate =
                String(paydayDate) === '0001-01-01T00:00:00'
                  ? estimatePayday
                  : paydayDate;

              return {
                ...rest,
                paydayDate: format(new Date(realDate), 'dd/MM/yyyy'),
                date: format(new Date(withdrawal.date), 'dd/MM/yyyy'),
              };
            });

          setWithdrawals(formattedWithdrawals);
        });

      if (!isLargerThan1368) onClose();

      setIsLoadingFilter(false);
    } catch {
      setIsLoadingFilter(false);
    }
  }

  async function handleClearFilterWithdrawals() {
    setIsLoadingClearFilter(true);

    try {
      await api
        .get(`withdraws/user/${user.id}`)
        .then(response => setWithdrawals(response.data));

      setAmount(0);
      setStatusData('-1');

      if (!isLargerThan1368) onClose();
    } catch (error) {
      //
    } finally {
      setIsLoadingClearFilter(false);
    }
  }

  function handleKeyPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const { nativeEvent } = event;
    if (nativeEvent.key === 'Enter') {
      handleFilterWithdrawals();
    }
  }

  if (!withdrawals) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Box mt="2">
      {!isLargerThan1368 && (
        <Button
          colorScheme="teal"
          alignSelf="center"
          marginY="2"
          onClick={onToggle}
        >
          Filtros
        </Button>
      )}

      <Collapse animateOpacity in={isOpen}>
        <Flex flexDir="column" bg="#0C6E9C" gridGap="10" p="2" rounded={10}>
          <Flex flexDir="column" gridGap="4">
            <Flex
              gridGap="2"
              justify="space-between"
              w="100%"
              flexDir={isLargerThan1368 ? 'row' : 'column'}
            >
              <Flex flexDir="column" w="100%">
                <Text mb={2} fontWeight="bold">
                  Valor do Saque
                </Text>
                <NumberFormat
                  prefix="$ "
                  placeholder="Valor do Saque"
                  allowNegative={false}
                  decimalSeparator=","
                  decimalScale={2}
                  thousandSeparator="."
                  onValueChange={values => {
                    const { floatValue } = values;
                    setAmount(floatValue || 0);
                  }}
                  onKeyPress={handleKeyPressEnter}
                  style={{
                    width: '100%',
                    outline: '2px solid transparent',
                    outlineOffset: 2,
                    backgroundColor: '#181B23',
                    height: '3rem',
                    borderRadius: '0.375rem',
                    border: '2px solid trasparent',
                    fontSize: '1.125rem',
                    paddingInlineStart: '1rem',
                    paddingInlineEnd: '2.5rem',
                  }}
                />
              </Flex>

              <Select
                name="status"
                label="Status"
                value={statusData}
                onChange={event => setStatusData(event.target.value)}
                w="100%"
              >
                <option value="-1">Todos</option>
                <option value="0">Em Análise</option>
                <option value="1">Pago</option>
                <option value="2">Negado</option>
              </Select>
            </Flex>

            <Flex gridGap="4">
              <DatePicker
                selected={initialDate}
                maxDate={finalDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => handleInitialDate(date)}
                customInput={<Input name="from" label="De" />}
                locale={ptBR}
              />

              <DatePicker
                selected={finalDate}
                minDate={initialDate}
                dateFormat="dd/MM/yyyy"
                onChange={date => handleFinalDate(date)}
                customInput={<Input name="to" label="Até" />}
                locale={ptBR}
              />

              <Flex ml="auto" gridGap="4" align="flex-end">
                <Button
                  colorScheme="teal"
                  type="button"
                  onClick={handleFilterWithdrawals}
                  isLoading={isLoadingFilter}
                >
                  Pesquisar
                </Button>
                <Button
                  type="button"
                  colorScheme="whiteAlpha"
                  variant="outline"
                  onClick={handleClearFilterWithdrawals}
                  isLoading={isLoadingClearFilter}
                >
                  Limpar
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Collapse>

      <Box
        hidden={isOpen && !isLargerThan1368}
        overflowX="auto"
        maxH={isLargerThan1368 ? '530px' : '380px'}
        mt="14px"
        rounded={8}
      >
        <Table bg="#0C6E9C">
          <Thead>
            <Tr>
              <Th color="#FFF">Valor</Th>
              <Th color="#FFF">Valor Líquido</Th>
              <Th color="#FFF">Data de Solicitação</Th>
              <Th color="#FFF">Data de Pagamento</Th>
              <Th color="#FFF">Status</Th>
              <Th color="#FFF">Conta | Carteira Digital</Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdrawals.map(withdrawal => (
              <Tr key={withdrawal.id}>
                <Td>
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={withdrawal.amount}
                    decimalScale={2}
                    prefix="$"
                  />
                </Td>
                <Td>
                  <NumberFormat
                    thousandSeparator="."
                    decimalSeparator=","
                    displayType="text"
                    value={withdrawal.netAmount}
                    decimalScale={2}
                    prefix="$"
                  />
                </Td>
                <Td>{withdrawal.date}</Td>
                <Td>
                  {withdrawal.status !== 'Negado' ? withdrawal.paydayDate : ''}
                </Td>
                <Td>{withdrawal.status}</Td>
                <Td>
                  {`${withdrawal.bankName} (Ag:${withdrawal.bankAgency} | Cc:${withdrawal.bankAccount}) || ${withdrawal.digitalWallet}`}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
