import React from 'react';
import { Flex, Box } from '@chakra-ui/react';

import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { Franchiseds } from '../../components/ManagementFranchised/Franchiseds';

export function ManagementFranchised(): JSX.Element {
  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex w="100%" flexDir="column" alignItems="center">
        <Header />

        <Box p="4" w="100%" maxW="1980px">
          <Franchiseds />
        </Box>
      </Flex>
    </Flex>
  );
}
