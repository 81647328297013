import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Flex,
  Button,
  useToast,
  Box,
  useBreakpointValue,
  useMediaQuery,
  Image,
  Text,
  Stack,
  HStack,
  Heading,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import axios from 'axios';
import { FiChevronLeft, FiUserX, FiUserCheck } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';

import { api } from '../../service/api';
import { Input } from '../Input';
import { Select } from '../Select';
import { ConvertDateAmerican } from '../../utils/convertData';
import { LoadingSpinner } from '../LoadingSpinner';

type UpdateUserFormData = {
  name: string;
  neighborhood: string;
  phoneNumber: string;
  phoneNumberAux: string;
  state: string;
  street: string;
  birthDate: string;
  cep: string;
  city: string;
  complement: string;
  number: number;
  country: string;
  cpf: string;
  email: string;
  gender: string;
  adressImage: File;
  identityImage: File;
};

interface CepProps {
  cep: string;
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
}

const updateUserFormDataSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  cpf: yup
    .string()
    .required('Documento é obrigatório')
    // .length(18, 'Digite um C.P.F. válido')
    .nullable(),
  email: yup
    .string()
    .required('E-mail obrigatório')
    .email('E-mail inválido')
    .nullable(),
  birthDate: yup.string().required('Data de nascimento obrigatória').nullable(),
  country: yup.string().required('Nacionalidade obrigatória').nullable(),
  gender: yup.string().required('Gênero obrigatório').nullable(),
  phoneNumber: yup
    .string()
    .required('Telefone celular obrigatório')
    // .length(15, 'Digite um telefone válido')
    .nullable(),
  phoneNumberAux: yup.string().nullable(),
  cep: yup.string().required('Cep obrigatório').nullable(),
  street: yup.string().required('Preencha o seu cep.').nullable(),
  neighborhood: yup.string().required('Preencha o seu cep.').nullable(),
  city: yup.string().required('Preencha o seu cep.').nullable(),
  state: yup.string().required('Preencha o seu cep.').nullable(),
  complement: yup.string().nullable(),
  number: yup.string().required('Número da residência.').nullable(),
  // identityImage: yup
  //   .mixed()
  //   .required('Comprovação de identidade necessário')
  //   .test('fileName', 'Documentação necessário', value => {
  //     return value[0] && value[0].name !== '';
  //   })
  //   .nullable(),
  // adressImage: yup
  //   .mixed()
  //   .required('Comprovante de endereço necessário')
  //   .test('fileName', 'Documentação necessário', value => {
  //     return value[0] && value[0].name !== '';
  //   })
  //   .nullable(),
});

interface User {
  id: number;
  activeStatus: number;
  profitabilityGroup: {
    id: number;
    groupName: string;
    profitabilityBonus: number;
    referralBonus: number;
    isPattern: boolean;
  };
  identityImage: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
  adressImage: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
  contractConfirmation: boolean;
  role: number;
  username: string;
  name: string;
  cpf: string;
  email: string;
  birthDate: Date;
  gender: string;
  phoneNumber: string;
  phoneNumberAux: string;
  cep: string;
  street: string;
  number: string;
  state: string;
  city: string;
  country: string;
  neighborhood: string;
  complement: string;
}

const MAX_SIZE_FILE = 2097152; // 2mb

export function EditUser(): JSX.Element {
  const { pathname } = useLocation();
  const [userEdit, setUserEdit] = useState<User>({} as User);
  const [isLoading, setIsLoading] = useState(false);

  const userId = pathname.replaceAll('/', '').replace('edit-user-adm', '');

  const history = useHistory();

  const [isLargerThan1280] = useMediaQuery('(max-height: 800px)');

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [identityImage, setIdentityImage] = useState<File>({} as File);
  const [adressImage, setAdressImage] = useState<File>({} as File);

  const toast = useToast();
  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(updateUserFormDataSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (!userId || userId === '0') return;
    try {
      setIsLoading(true);
      api.get(`users/${userId}`).then(response => setUserEdit(response.data));
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    setValue('name', userEdit.name);
    setValue('cpf', userEdit.cpf);
    setValue('email', userEdit.email);

    setValue(
      'birthDate',
      userEdit.birthDate
        ? format(new Date(userEdit.birthDate), 'dd/MM/yyyy')
        : null,
    );

    setValue('gender', userEdit.gender);
    setValue('phoneNumber', userEdit.phoneNumber);
    setValue(
      'phoneNumberAux',
      userEdit.phoneNumberAux !== null ? userEdit.phoneNumberAux : '',
    );
    setValue('cep', userEdit.cep);
    setValue('street', userEdit.street);
    setValue('number', userEdit.number);
    setValue('neighborhood', userEdit.neighborhood);
    setValue('city', userEdit.city);
    setValue('state', userEdit.state);
    setValue(
      'complement',
      userEdit.complement !== 'null' ? userEdit.complement : '',
    );
    setValue('country', userEdit.country);
  }, [setValue, userEdit]);

  const handleGetCep = async (cep: string) => {
    const formattefZipCod = cep.replace('-', '').replace('_', '');

    if (formattefZipCod.length !== 8) {
      setValue('street', '');
      setValue('neighborhood', '');
      setValue('city', '');
      setValue('state', '');
      setValue('country', '');
      return;
    }

    try {
      await axios
        .get<CepProps>(`https://viacep.com.br/ws/${formattefZipCod}/json/`)
        .then(response => {
          setValue('street', response.data.logradouro);
          setValue('neighborhood', response.data.bairro);
          setValue('city', response.data.localidade);
          setValue('state', response.data.uf);
          setValue('country', 'Brasil');
        });
    } catch (error) {
      toast({
        title: 'Erro ao enviar a mensagem!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: error.message,
        position: 'top-right',
      });
    }
  };

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = async values => {
    try {
      const formData = new FormData();

      formData.append('name', values.name);
      formData.append('neighborhood', values.neighborhood);
      formData.append('phoneNumber', values.phoneNumber);
      formData.append('state', values.state);
      formData.append('street', values.street);
      formData.append('number', String(values.number));
      formData.append('cep', values.cep);
      formData.append('city', values.city);
      formData.append('country', values.country);
      formData.append('cpf', values.cpf);
      formData.append('email', values.email);
      formData.append('gender', values.gender);

      const birthDate = ConvertDateAmerican(values.birthDate);
      formData.append('birthDate', birthDate);

      if (values.phoneNumberAux)
        formData.append('phoneNumberAux', values.phoneNumberAux);

      if (values.complement) formData.append('complement', values.complement);

      if (adressImage) formData.append('adressImage', adressImage);

      if (identityImage) formData.append('identityImage', identityImage);

      await api.put(`admin/data/${userId}`, formData, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      });

      toast({
        title: 'Investidor atualizado com sucesso.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      history.goBack();
    } catch {
      const { data, status } = error.response;

      const erroDescrition = typeof data === 'string' ? data : '';

      toast({
        title: 'Erro ao atualizar as informações do usuário',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? erroDescrition : '',
        position: 'top-right',
      });
    }
  };

  function handleIdentityImage(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0].size > MAX_SIZE_FILE) {
      e.target.value = '';
      alert('O comprovante tem que ter tamanho máximo de 2MB.');
      return;
    }

    if (e.target.files) {
      setIdentityImage(e.target.files[0]);
    }
  }

  function handleAdressImage(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files[0].size > MAX_SIZE_FILE) {
      e.target.value = '';
      alert('O comprovante tem que ter tamanho máximo de 2MB.');
      return;
    }

    if (e.target.files) {
      setAdressImage(e.target.files[0]);
    }
  }

  async function handleUnActiveUser() {
    try {
      const textAsk =
        userEdit.activeStatus === 1
          ? 'Tem certeza que deseja inativar esse investidor?'
          : 'Tem certeza que deseja ativar esse investidor?';
      if (confirm(textAsk)) {
        const status = userEdit.activeStatus === 1 ? 0 : 1;
        await api.put(`users/change-status/${userId}/${status}`);

        api.get(`users/${userId}`).then(response => setUserEdit(response.data));

        toast({
          title: `Investidor foi ${
            userEdit.activeStatus === 1 ? 'inativado' : 'ativado'
          } com sucesso.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      const { data, status } = error.response;

      const erroDescrition = typeof data === 'string' ? data : '';

      toast({
        title: `Não foi possivel ${
          userEdit.activeStatus === 1 ? 'inativar' : 'ativar'
        } o investidor.`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? erroDescrition : '',
        position: 'top-right',
      });
    }
  }

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="space-around"
        flexDir="column"
        gridGap="4"
        rounded="10px"
        color="white"
        flex="1"
        p="4"
      >
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Box p="2">
      <Flex alignItems="center" justify="space-between" mb={4}>
        <Button
          type="button"
          onClick={() => history.goBack()}
          leftIcon={<FiChevronLeft size={20} />}
          aria-label="Voltar"
          variant="link"
          color="white"
          textAlign="center"
        >
          <Heading>Editar usuário</Heading>
        </Button>

        {userEdit.activeStatus === 1 && (
          <Button
            type="button"
            onClick={handleUnActiveUser}
            leftIcon={<FiUserX size={20} />}
            aria-label="Inativar usuário"
            colorScheme="orange"
          >
            Inativar Usuário
          </Button>
        )}

        {userEdit.activeStatus === 0 && (
          <Button
            type="button"
            onClick={handleUnActiveUser}
            leftIcon={<FiUserCheck size={20} />}
            aria-label="Ativar usuário"
            colorScheme="green"
          >
            Ativar Usuário
          </Button>
        )}
      </Flex>

      <Box
        as="form"
        onSubmit={handleSubmit(handleUpdateUser)}
        p="4"
        bg="gray.800"
        h="100%"
        maxH={isLargerThan1280 ? '380px' : '680px'}
        style={{ overflowY: 'auto' }}
        sx={{
          '::-webkit-scrollbar': {
            width: '4px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#666',
            borderRadius: '4px',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: '#2f3136',
          },
        }}
      >
        <Flex
          as="fieldset"
          p="4"
          color="white"
          border="1px"
          borderStyle="solid"
          borderColor="gray.200"
        >
          <legend>Informações Pessoais</legend>

          <Stack spacing="4" width="100%">
            <Flex gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
              <Input
                placeholder="Nome"
                error={errors.name}
                {...register('name')}
              />

              <Input
                placeholder="Documento"
                mask="cpf"
                error={errors.cpf}
                {...register('cpf')}
              />
            </Flex>

            <Flex
              justify="space-between"
              gridGap="4"
              flexDir={isWideVersion ? 'row' : 'column'}
            >
              <Input
                placeholder="E-mail"
                type="email"
                error={errors.email}
                {...register('email')}
              />

              <Input
                placeholder="Data de nascimento"
                mask="date"
                error={errors.birthDate}
                {...register('birthDate')}
              />
            </Flex>

            <Flex
              justify="space-between"
              gridGap="4"
              flexDir={isWideVersion ? 'row' : 'column'}
            >
              <Select
                placeholder="Gênero"
                error={errors.gender}
                {...register('gender')}
              >
                <option style={{ color: '#000' }} value="male">
                  Masculino
                </option>
                <option style={{ color: '#000' }} value="female">
                  Feminino
                </option>
              </Select>
              <Input
                placeholder="Celular"
                mask="mobile"
                error={errors.phoneNumber}
                {...register('phoneNumber')}
              />
              <Input
                placeholder="Telefone"
                mask="phone"
                error={errors.phoneNumberAux}
                {...register('phoneNumberAux')}
              />
            </Flex>
          </Stack>
        </Flex>

        <Flex
          as="fieldset"
          p="4"
          mt="4"
          color="white"
          border="1px"
          borderStyle="solid"
          borderColor="gray.200"
        >
          <legend>Endereço</legend>
          <Stack spacing="4" width="100%">
            <Flex
              justify="space-between"
              gridGap="4"
              flexDir={isWideVersion ? 'row' : 'column'}
            >
              <Input
                placeholder="CEP"
                mask="cep"
                error={errors.cep}
                {...register('cep')}
                onChange={event => handleGetCep(event.target.value)}
              />
              <Input
                placeholder="Rua"
                isReadOnly
                error={errors.street}
                {...register('street')}
              />
              <Input
                placeholder="Número"
                error={errors.number}
                {...register('number')}
              />
            </Flex>

            <Flex
              justify="space-between"
              gridGap="4"
              flexDir={isWideVersion ? 'row' : 'column'}
            >
              <Input
                placeholder="Cidade"
                isReadOnly
                error={errors.city}
                {...register('city')}
              />
              <Input
                placeholder="Estado"
                isReadOnly
                error={errors.state}
                {...register('state')}
              />
              <Input
                placeholder="País"
                isReadOnly
                error={errors.country}
                {...register('country')}
              />
            </Flex>

            <Flex
              justify="space-between"
              gridGap="4"
              flexDir={isWideVersion ? 'row' : 'column'}
            >
              <Input
                placeholder="Bairro"
                isReadOnly
                error={errors.neighborhood}
                {...register('neighborhood')}
              />
              <Input
                placeholder="Complemento"
                error={errors.complement}
                {...register('complement')}
              />
            </Flex>
          </Stack>
        </Flex>

        <Flex
          as="fieldset"
          p="2"
          mt="4"
          color="white"
          border="1px"
          borderStyle="solid"
          borderColor="gray.200"
        >
          <legend>Fotos</legend>
          <Flex
            justify="space-between"
            gridGap="4"
            flexDir={isWideVersion ? 'row' : 'column'}
            width="100%"
          >
            <Flex align="center" flex="1" flexDir="column">
              <Text mb="2" fontSize="2xl">
                Comprovante de indentidade
              </Text>

              {userEdit.identityImage && (
                <Image
                  src={`data:${userEdit.identityImage.contentType};base64, ${userEdit.identityImage.content}`}
                  alt="Comprovante de indentidade"
                  h="200px"
                  id="identityImageSrc"
                  objectFit="cover"
                />
              )}

              <Input
                name="identityImage"
                variant="flushed"
                padding="2"
                id="identityImage"
                placeholder="Identidade"
                type="file"
                width="100%"
                onChange={handleIdentityImage}
              />
            </Flex>

            <Flex align="center" flex="1" flexDir="column">
              <Text mb="2" fontSize="2xl">
                Comprovante de endereço
              </Text>
              {userEdit.adressImage && (
                <Image
                  src={`data:${userEdit.adressImage.contentType};base64, ${userEdit.adressImage.content}`}
                  alt="Comprovante de indentidade"
                  h="200px"
                  id="identityImageSrc"
                  objectFit="cover"
                />
              )}

              <Input
                name="adressImage"
                variant="flushed"
                width="100%"
                padding="2"
                id="adressImage"
                type="file"
                onChange={handleAdressImage}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex
          mt="4"
          flexDir={isWideVersion ? 'row' : 'column'}
          justify="space-between"
          gridGap={isWideVersion ? '0' : '4'}
        >
          <HStack spacing="4" ml="auto">
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
}
