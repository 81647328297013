import React, { useState, useEffect } from 'react';
import {
  Flex,
  useBreakpointValue,
  Heading,
  Text,
  Divider,
  useMediaQuery,
  Grid,
  useToast,
  Button,
  Box,
} from '@chakra-ui/react';
import { FiDollarSign, FiUsers, FiLogOut, FiPlay } from 'react-icons/fi';
import NumberFormat from 'react-number-format';
import { AxiosResponse } from 'axios';

import { CardIcon } from '../CardIcon';
import { ChartDashboard } from '../Chart';
import { api } from '../../service/api';
import { useAuth } from '../../hooks/auth';
import { LoadingSpinner } from '../LoadingSpinner';

export interface DashboardChart {
  month: number;
  balance: number;
}
interface AdmDashboardData {
  clients: number;
  totalInvested: number;
  cashOut: number;
  totalIncomePaid: number;
  incomeTo: number;
  monthIncome: boolean;
  dashboardChart: DashboardChart[];
}

interface Error {
  response: AxiosResponse;
}

export function AdmDashboard(): JSX.Element {
  const [data, setData] = useState<AdmDashboardData>({} as AdmDashboardData);
  const [isExecutionIncome, setIsExecutionIncome] = useState(false);
  const [isExecutionMonth, setIsExecutionMonth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { refreshUser, validToken } = useAuth();

  const toast = useToast();

  useEffect(() => {
    try {
      setIsLoading(true);
      api
        .get('admin/dashboard', {
          headers: { Authorization: `Bearer ${validToken()}` },
        })
        .then(response => setData(response.data));
      setIsLoading(false);
    } catch {
      //
    }
  }, [validToken]);

  const [isLargerThan1280] = useMediaQuery('(max-width: 1280px)');

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  async function handleExecuteIncome() {
    try {
      setIsExecutionIncome(true);

      await api.get('admin/executionincome');

      await refreshUser();

      window.location.reload();

      setIsExecutionIncome(false);

      toast({
        title: 'Rendimento processado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      setIsExecutionIncome(false);

      const {
        response: { data },
      } = error as Error;

      toast({
        title: 'Erro ao processar o rendimento!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: data,
        position: 'top-right',
      });
    }
  }

  async function handleExecuteMonth() {
    try {
      setIsExecutionMonth(true);

      await api.get('admin/executionmonthly');

      await refreshUser();

      window.location.reload();

      setIsExecutionMonth(false);

      toast({
        title: 'Mensalidade processado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      setIsExecutionMonth(false);

      const { data } = error.response;

      toast({
        title: 'Erro ao processar a mensalidade!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: data,
        position: 'top-right',
      });
    }
  }

  return (
    <>
      {isLoading && !data ? (
        <Flex
          align="center"
          justify="space-around"
          flexDir="column"
          gridGap="4"
          rounded="10px"
          color="white"
          flex="1"
          p="4"
        >
          <LoadingSpinner />
        </Flex>
      ) : (
        <Flex
          flexDir="column"
          align="center"
          justify="flex-start"
          p="4"
          w="100%"
          h="100%"
          paddingX={isWideVersion ? '8' : '4'}
          bg="#C9C9C9"
        >
          <Flex align="flex-start" width="100%" gap={4}>
            {data && !data.monthIncome && (
              <Button
                leftIcon={isWideVersion ? <FiDollarSign /> : undefined}
                onClick={handleExecuteIncome}
                isLoading={isExecutionIncome}
                colorScheme="green"
                alignSelf="self-start"
              >
                Pagar rendimento
              </Button>
            )}

            <Button
              leftIcon={isWideVersion ? <FiPlay /> : undefined}
              onClick={handleExecuteMonth}
              isLoading={isExecutionMonth}
              colorScheme="yellow"
              color="white"
              alignSelf="self-start"
            >
              Executar mensalidade
            </Button>
          </Flex>

          <Flex
            flexDir={isWideVersion ? 'row' : 'column'}
            align={isWideVersion ? '' : 'center'}
            justifyContent="center"
            gridGap="10"
            width="100%"
            mt={isLargerThan1280 ? '' : '20px'}
          >
            <Grid
              templateColumns={isWideVersion ? 'repeat(4, 1fr)' : ''}
              templateRows={isWideVersion ? '' : 'repeat(4, 1fr)'}
              gap={6}
              w="100%"
            >
              <CardIcon
                icon={FiUsers}
                bg="green.400"
                title="CLIENTES"
                info={data.clients}
                type="text"
              />

              <CardIcon
                icon={FiDollarSign}
                bg="red.400"
                title="SALDO EM DÓLAR"
                info={data.totalInvested}
                type="currency"
              />

              <CardIcon
                icon={FiLogOut}
                bg="pink.600"
                title="SAÍDA"
                info={data.cashOut}
                type="currency"
              />

              <CardIcon
                icon={FiDollarSign}
                bg="pink.300"
                title="RENDIMENTOS PAGOS"
                info={data.totalIncomePaid}
                type="currency"
              />
            </Grid>
          </Flex>

          <Flex
            flexDir="column"
            alignItems="stretch"
            justifyContent="stretch"
            gridGap="4"
            mt="4"
            width="100%"
            maxW="1980px"
          >
            <Flex justifyContent="center" alignItems="stretch" gridGap="4">
              <Flex
                flexDir="column"
                gridGap="4"
                justifyContent="space-around"
                bg="#0C6E9C"
                rounded={10}
                p="4"
                flex="1"
                width="100%"
                maxW="700px"
              >
                <Flex flexDir="column" align="center" justify="center">
                  <Heading fontSize={isLargerThan1280 ? 'xl' : '3xl'}>
                    Rendimento pendente do mês
                  </Heading>
                  <Text fontSize={isLargerThan1280 ? 'xl' : '3xl'}>
                    <NumberFormat
                      thousandSeparator="."
                      decimalSeparator=","
                      displayType="text"
                      value={data.incomeTo}
                      decimalScale={2}
                      prefix="$ "
                    />
                  </Text>
                </Flex>

                <Divider marginY="5" />

                <Flex flexDir="column" align="center" justify="center">
                  <Heading fontSize={isLargerThan1280 ? 'xl' : '3xl'}>
                    Estado do rendimento no dia
                  </Heading>
                  <Text fontSize={isLargerThan1280 ? 'xl' : '3xl'}>
                    {data.monthIncome ? 'Pago' : 'Pendente'}
                  </Text>
                </Flex>
              </Flex>

              <Box w="100%" maxW="700px" alignSelf="center">
                <ChartDashboard data={data.dashboardChart} />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}
