import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';
import { useAuth } from '../../hooks/auth';
import { EditDataUser } from './EditDataUser';
import { GeneralData } from './GeneralData';

export function User(): JSX.Element {
  const { user } = useAuth();

  return (
    <Flex w="100%" p="4" justify="center" maxW="1980px">
      {user.activeStatus === 1 ? (
        <Tabs w="100%" maxW="1980px" isFitted variant="soft-rounded">
          <TabList>
            <Tab color="#fff" bg="#0C6E9C" mr="8px">
              Informações
            </Tab>
            <Tab color="#fff" bg="#0C6E9C">
              Meus dados
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <GeneralData />
            </TabPanel>
            <TabPanel>
              <EditDataUser />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <EditDataUser />
      )}
    </Flex>
  );
}
