import React, { useState, useRef } from 'react';
import {
  Flex,
  Button,
  Heading,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';
import Lottie from 'lottie-react-web';

import { addMonths, addYears, getMonth, getYear } from 'date-fns';
import investmentChart from '../../assets/investment-chart.json';
import investiment from '../../assets/investiment.jpeg';

import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { DashboardChart } from '../../components/Dashboard/AdmDashboard';
import { ChartDashboard } from '../../components/Chart';

interface PeriodProps {
  value: string;
  label: string;
  pluralForm: string;
}

const typePeriods = [
  { value: 'month', label: 'Mês', pluralForm: 'mese(s)' },
  { value: 'year', label: 'Ano', pluralForm: 'ano(s)' },
];

export function SimulateInvestment(): JSX.Element {
  const [seriesChart, setSeriesChart] = useState<DashboardChart[]>([]);

  const divRef = useRef<HTMLDivElement>(null);

  const [amount, setAmount] = useState(0);
  const [period, setPeriod] = useState(0);
  const [typePeriod, setTypePeriod] = useState<PeriodProps>({
    value: 'month',
    label: 'Mês',
    pluralForm: 'mese(s)',
  });
  const [investedAmount, setInvestedAmount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [isToggled, setIsToggled] = useState(false);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  function handleOnChangePeriod(periodSelected: string) {
    const periodSelect = typePeriods.filter(
      item => item.value === periodSelected,
    );

    setTypePeriod(periodSelect[0]);
  }

  async function handleSimulateInvestment() {
    if (amount === 0 || period === 0) return;

    if (!isWideVersion) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const periodYearOrMonth =
      typePeriod.value === 'month' ? period : period * 12;

    let totalPerPeriod = amount;
    let month = 0;
    let totalPerPeriodArray: DashboardChart[] = [];
    let balance = '';
    const calcPercent = percent / 100;
    for (let i = 1; i <= period; i++) {
      if (typePeriod.value === 'month') {
        totalPerPeriod *= 1 + calcPercent;
        month = getMonth(addMonths(new Date(), i + 1));
        month = month === 0 ? 12 : month;
      } else {
        totalPerPeriod *= (1 + calcPercent) ** 12;
        month = getYear(addYears(new Date(), i));
      }

      balance = totalPerPeriod.toFixed(2);

      totalPerPeriodArray.push({
        balance: Number(balance),
        month,
      });
    }

    setSeriesChart(totalPerPeriodArray);

    const total = amount * (1 + calcPercent) ** periodYearOrMonth;

    setIsToggled(true);
    setInvestedAmount(total);

    await new Promise(resolve => setTimeout(resolve, 2000));

    setIsToggled(false);
  }

  return (
    <Flex h={isWideVersion ? '100vh' : '100%'} bg="#C9C9C9">
      <Sidebar />

      <Flex direction="column" w="100%">
        <Header />

        <Flex maxW="1980px">
          <Flex flexDir="column" w="100%" flex="1" paddingX="10" gridGap="10">
            <Flex align="center" justify="center" mt="4">
              <Heading textAlign="center">Simulador de Ganhos</Heading>
            </Flex>

            <Flex
              justify="center"
              gridGap="10"
              width="100%"
              pb={isWideVersion ? '0' : '10'}
              flexDir={isWideVersion ? 'row' : 'column'}
            >
              <Flex
                bg="#0C6E9C"
                p="8"
                flexDir="column"
                gridGap="8"
                rounded={10}
              >
                <Input
                  name="amount"
                  label="Depósito"
                  icon="$"
                  type="number"
                  onChange={event => setAmount(Number(event.target.value))}
                />

                <Flex
                  gridGap="10"
                  align="flex-end"
                  w="100%"
                  flex="1"
                  flexWrap={isWideVersion ? 'nowrap' : 'wrap'}
                >
                  <Select
                    name="typePeriod"
                    label="Periodo"
                    onChange={event => handleOnChangePeriod(event.target.value)}
                  >
                    {typePeriods.map(type => (
                      <option
                        style={{ color: '#1F2029' }}
                        key={type.value}
                        value={type.value}
                      >
                        {type.label}
                      </option>
                    ))}
                  </Select>

                  <Input
                    name="period"
                    label={`Tempo (${typePeriod.label})`}
                    type="number"
                    onChange={event => setPeriod(Number(event.target.value))}
                  />

                  <Input
                    name="percent"
                    label="Procentagem:"
                    type="number"
                    icon="%"
                    onChange={event => setPercent(Number(event.target.value))}
                  />
                </Flex>

                <Button
                  type="button"
                  colorScheme="whatsapp"
                  mt="auto"
                  onClick={handleSimulateInvestment}
                >
                  Simular
                </Button>
              </Flex>

              {investedAmount === 0 ? (
                <Flex
                  align="center"
                  bg="#0C6E9C"
                  p="8"
                  flexDir="column"
                  gridGap="8"
                  rounded={10}
                  justify="center"
                  width="100%"
                >
                  <Text
                    w="auto"
                    color="whiteAlpha.900"
                    fontWeight="bold"
                    fontSize="3xl"
                  >
                    Preencha os campos para simular os ganhos
                  </Text>
                </Flex>
              ) : (
                <Flex
                  width="100%"
                  align="center"
                  bg="#0C6E9C"
                  p="8"
                  flexDir="column"
                  gridGap="8"
                  rounded={10}
                >
                  <Heading fontSize={isWideVersion ? '4xl' : 'md'}>
                    Em {period} {typePeriod.pluralForm} você terá
                  </Heading>

                  {isToggled ? (
                    <Flex maxH="inherit">
                      <Lottie
                        style={{ maxHeight: '200px' }}
                        options={{ animationData: investmentChart }}
                      />
                    </Flex>
                  ) : (
                    <Flex
                      ref={divRef}
                      align="center"
                      justify="center"
                      mt="auto"
                      h="100%"
                      minHeight="150px"
                      width="100%"
                      rounded={10}
                      bgImage={investiment}
                      bgPos="center"
                      bgSize="cover"
                    >
                      <Text
                        fontSize={isWideVersion ? '5xl' : '2xl'}
                        fontWeight="bold"
                      >
                        ${' '}
                        <NumberFormat
                          value={investedAmount}
                          thousandSeparator="."
                          decimalSeparator=","
                          displayType="text"
                          decimalScale={2}
                        />
                      </Text>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>

            {isLargerThan1368 && (
              <ChartDashboard
                data={seriesChart}
                width="1080px"
                height="320px"
                type={typePeriod.value}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
