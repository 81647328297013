import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import Modal from 'react-modal';

import { AuthProvider } from './hooks/auth';
import { ReactModalProvider } from './contexts/ReactModal';
import { theme } from './styles/theme';
import { Routes } from './routes';
import { SidebarDrawerProvider } from './contexts/SidebarDrawerContent';
import { NewMessageModal } from './components/NewMessageModal';
import { NewInvestment } from './components/NewInvestment';
import { WithdrawModal } from './components/WithdrawModal';
import { FranchisedModal } from './components/FranchisedModal';
import { ResetPasswordModal } from './components/ResetPasswordModal';
import { ListMessagesModal } from './components/ListMessagesModal';
import { ConfirmedUserModal } from './components/ConfirmedUserModal';
import { ContractModal } from './components/ContractModal';
import { ResetSecurityPassword } from './components/ResetSecurityPassword';
import { NewInvestmentAdm } from './components/NewInvestmentAdm';
import { ContractModalDashboard } from './components/ContractModalDashboard';
import { TicketModal } from './components/TicketModal';

Modal.setAppElement('#root');

export const App = (): JSX.Element => {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <SidebarDrawerProvider>
          <ReactModalProvider>
            <Router>
              <Routes />
            </Router>

            <NewMessageModal />
            <NewInvestment />
            <WithdrawModal />
            <FranchisedModal />
            <ResetPasswordModal />
            <ListMessagesModal />
            <ConfirmedUserModal />
            <ResetSecurityPassword />
            <NewInvestmentAdm />
            <ContractModal />
            <ContractModalDashboard />
            <TicketModal />
          </ReactModalProvider>
        </SidebarDrawerProvider>
      </ChakraProvider>
    </AuthProvider>
  );
};
