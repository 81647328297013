import React, { useEffect } from 'react';
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Icon,
} from '@chakra-ui/react';

import { useState } from 'react';
import { useReactModal } from '../../contexts/ReactModal';
import { api } from '../../service/api';
import { useAuth } from '../../hooks/auth';
import { LoadingSpinner } from '../LoadingSpinner';

interface Message {
  id: number;
  content: string;
  status: number;
  updatedDate: Date;
  isHighlight: boolean;
}

export function ListMessagesModal(): JSX.Element {
  const { user, validToken } = useAuth();
  const { isOpenListMessagesModal, handleCloseListMessagesModal } =
    useReactModal();

  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) return;

    const messagesLocalStorage = localStorage.getItem('@Active:messages');

    let messagesObject = [] as Message[];
    if (messagesLocalStorage) {
      messagesObject = JSON.parse(messagesLocalStorage || '') as Message[];
    }

    try {
      setIsLoading(true);
      api
        .get<Message[]>('notices/user', {
          headers: { Authorization: `Bearer ${validToken()}` },
        })
        .then(response => {
          const messages = response.data
            .map(message => {
              const isExistsIndex = messagesObject.findIndex(
                item =>
                  message.id === item.id && message.content === item.content,
              );

              return {
                ...message,
                isHighlight: isExistsIndex === -1,
              };
            })
            .sort((a, b) => (a.updatedDate > b.updatedDate ? -1 : 1));

          setMessages(messages);
        });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [user, validToken, isOpenListMessagesModal]);

  useEffect(() => {
    if (messages.length > 0 && isOpenListMessagesModal) {
      const messagesWithoutHighlight = messages.map(message => {
        return {
          ...message,
          isHighlight: false,
        };
      });

      localStorage.setItem(
        '@Active:messages',
        JSON.stringify(messagesWithoutHighlight),
      );
    }
  }, [messages, isOpenListMessagesModal]);

  return (
    <Modal
      isOpen={isOpenListMessagesModal}
      onClose={handleCloseListMessagesModal}
      size="xl"
    >
      <ModalOverlay />

      <ModalContent bg="gray.800" p="2">
        <ModalHeader>Mensagens</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {isLoading ? (
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          ) : (
            <Flex>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Mensagem</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {messages.map(message => (
                    <Tr key={message.id}>
                      <Td fontWeight={message.isHighlight ? 'bold' : 'normal'}>
                        <Flex
                          alignItems="center"
                          justify="flex-start"
                          gridGap={4}
                        >
                          {message.isHighlight && (
                            <Icon viewBox="0 0 200 200" color="yellow.500">
                              <path
                                fill="currentColor"
                                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                              />
                            </Icon>
                          )}
                          {message.content}
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
