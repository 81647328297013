import React from 'react';
import { Redirect, Switch, Route as ReactDOMRoute } from 'react-router-dom';

import { Signin } from '../pages/Signin';
import { Signup } from '../pages/Signup';
import { ForgotPassword } from '../pages/ForgortPassword';
import { Dashboard } from '../pages/Dashboard';
import { UpdateUser } from '../pages/UpdateUser';
import { Financial } from '../pages/Financial';
import { MyFranchisedArea } from '../pages/MyFranchisedArea';
import { SimulateInvestment } from '../pages/SimulateInvestment';
import { Management } from '../pages/Management';
import { FinancialAdm } from '../pages/FinancialAdm/Index';
import { Reports } from '../pages/Reports';
import { Route } from './Route';
import { BackupFile } from '../pages/BackupFile';
import { ResetPassword } from '../pages/ResetPassword';
import { Messages } from '../pages/Messages';
import { EditUserAdimistrator } from '../pages/EditUserAdm';
import { ProfitabilityGroups } from '../pages/ProfitabilityGroups';
import { NewProfitabilityGroups } from '../pages/NewProfitabilityGroups';
import { InvestmentProjection } from '../pages/InvestmentProjection';
import { ManagementFranchised } from '../pages/ManagementFranchised';
import { DashboardAdm } from '../pages/DashboardAdm';
import { Support } from '../pages/Support';
import { ReferralsArea } from '../pages/ReferralsArea';

export const Routes = (): JSX.Element => (
  <Switch>
    <Route exact path="/" component={Signin} />
    <Route path="/signup" component={Signup} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/update-user" component={UpdateUser} isPrivate />
    <Route path="/backup-file" component={BackupFile} isPrivate />
    <Route path="/support" component={Support} isPrivate />

    <Route path="/financial" component={Financial} isPrivate isActive />
    {/* <Route
      path="/my-franchised"
      component={MyFranchisedArea}
      isPrivate
      isFranchised
    /> */}
    <Route
      path="/my-referrals"
      component={ReferralsArea}
      isPrivate
      isFranchised
    />
    <Route
      path="/simulate-investment"
      component={SimulateInvestment}
      isPrivate
      isActive
    />
    <Route
      path="/investment-projection"
      component={InvestmentProjection}
      isPrivate
      isActive
    />

    {/* <Route path="/ticket" component={Ticket} isPrivate isActive /> */}

    <Route path="/dashboard-adm" component={DashboardAdm} isPrivate isAdmin />
    <Route path="/management" component={Management} isPrivate isAdmin />
    <Route path="/financial-adm" component={FinancialAdm} isPrivate isAdmin />
    <Route path="/reports" component={Reports} isPrivate isAdmin />
    <Route path="/messages" component={Messages} isPrivate isAdmin />
    <Route
      path="/edit-user-adm"
      component={EditUserAdimistrator}
      isPrivate
      isAdmin
    />

    <Route
      path="/profitability-groups"
      component={ProfitabilityGroups}
      isPrivate
      isAdmin
    />

    <Route
      path="/new-profitability-groups"
      component={NewProfitabilityGroups}
      isPrivate
      isAdmin
    />

    <Route
      path="/management-franchised"
      component={ManagementFranchised}
      isPrivate
      isAdmin
    />
    <Route path="/backup-file-adm" component={BackupFile} isPrivate isAdmin />

    <ReactDOMRoute path="*">
      <Redirect to="/" />
    </ReactDOMRoute>
  </Switch>
);
