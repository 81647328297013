import React from 'react';
import {
  Flex,
  theme,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react';
import ApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { useEffect } from 'react';
import { useState } from 'react';
import { DashboardChart } from './Dashboard/AdmDashboard';
import { IncomeReportFormatted } from './Reports/IncomeReportChart';
import { convertCurrencyBrazil } from '../utils/convertData';

const options: ApexOptions = {
  theme: {
    mode: 'dark',
  },
  chart: {
    background: 'transparent',
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
    foreColor: theme.colors.gray[500],
  },
  grid: {
    show: true,
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: 'top',
      },
    },
  },
  xaxis: {
    type: 'category',
    axisBorder: {
      color: theme.colors.gray[600],
    },
    axisTicks: {
      color: theme.colors.gray[600],
    },
    labels: {
      style: {
        colors: theme.colors.whiteAlpha[900],
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: theme.colors.whiteAlpha[900],
      },
    },
  },
};

const months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

interface ChartProps {
  data: DashboardChart[];
  width?: string;
  height?: string;
  type?: string;
}

interface ChartIncomesProps {
  data: IncomeReportFormatted[];
  width?: string;
  height?: string;
}

interface ChartDataXYIncome {
  x: string;
  y: number;
}

interface ChartDataXY {
  x: string;
  y: string;
}

interface ChartData {
  name: string;
  data: ChartDataXY[];
}

interface ChartDataIncome {
  name: string;
  data: ChartDataXYIncome[];
}

export function ChartDashboard({
  data,
  width = '500px',
  height = '270px',
  type = 'month',
}: ChartProps): JSX.Element {
  const [seriesChart, setSeriesChart] = useState<ChartData[]>([]);

  const [isLargerThan1280] = useMediaQuery('(min-width: 1368px)');

  useEffect(() => {
    if (!data) return;

    let period = '';
    const newSeries = [
      {
        name: 'Rendimentos',
        data: data.map(item => {
          if (type === 'month') {
            period = months[item.month - 1];
          } else {
            period = String(item.month);
          }

          return {
            x: period,
            y: item.balance ? String(item.balance) : '0',
          };
        }),
      },
    ];

    setSeriesChart(newSeries);
  }, [data, type]);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const optionsChart: ApexOptions = {
    ...options,
    dataLabels: {
      formatter(val: number) {
        return `$ ${convertCurrencyBrazil(val)}`;
      },
    },
    tooltip: {
      y: {
        formatter(val: number) {
          return `$ ${convertCurrencyBrazil(val)}`;
        },
      },
    },
  };

  return (
    <Flex
      bg="#0C6E9C"
      rounded={10}
      align="center"
      justify="center"
      width="100%"
      paddingY="2"
    >
      {seriesChart && (
        <ApexChart
          options={optionsChart}
          series={seriesChart}
          type="area"
          width={isWideVersion ? width : '100%'}
          height={isLargerThan1280 ? height : '100%'}
        />
      )}
    </Flex>
  );
}

export function ChartDashboardProfitSharing({
  data,
  width = '1200px',
  height = '270px',
  type = 'month',
}: ChartProps): JSX.Element {
  const [seriesChart, setSeriesChart] = useState<ChartData[]>([]);

  const [isLargerThan1280] = useMediaQuery('(min-width: 1368px)');

  useEffect(() => {
    if (!data) return;

    let period = '';
    const newSeries = [
      {
        name: 'Participação de Lucros',
        data: data.map(item => {
          if (type === 'month') {
            period = months[item.month - 1];
          } else {
            period = String(item.month);
          }

          return {
            x: period,
            y: item.balance ? String(item.balance) : '0',
          };
        }),
      },
    ];

    setSeriesChart(newSeries);
  }, [data, type]);

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const optionsChart: ApexOptions = {
    ...options,
    dataLabels: {
      formatter(val: number) {
        return `${val}%`;
      },
    },
    tooltip: {
      y: {
        formatter(val: number) {
          return `${val}%`;
        },
      },
    },
  };

  return (
    <Flex
      bg="#0C6E9C"
      rounded={10}
      align="center"
      justify="center"
      width="100%"
      paddingY="2"
    >
      {seriesChart && (
        <ApexChart
          options={optionsChart}
          series={seriesChart}
          type="area"
          width={isWideVersion ? width : '100%'}
          height={isLargerThan1280 ? height : '100%'}
        />
      )}
    </Flex>
  );
}

export function ChartIncome({
  data,
  width = '990px',
  height = '400px',
}: ChartIncomesProps): JSX.Element {
  const [seriesChart, setSeriesChart] = useState<ChartDataIncome[]>([]);

  const [isLargerThan2200] = useMediaQuery('(min-width: 2200px)');

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const widthCurrent = screen.width;
  const widthDesktop = isLargerThan2200 ? '2200px' : width;
  const widthReal = !isWideVersion ? `${widthCurrent - 200}px` : widthDesktop;

  useEffect(() => {
    if (!data) return;

    const newSeries = [
      {
        name: 'Rendimentos',
        data: data.map(item => {
          return {
            x: item.day,
            y: item.totalIncomeTo,
          };
        }),
      },
    ];

    setSeriesChart(newSeries);
  }, [data]);

  const optionsChart: ApexOptions = {
    ...options,
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter(val: number) {
        return `$ ${convertCurrencyBrazil(val)}`;
      },
      style: {
        fontSize: '16px',
        colors: ['#+'],
      },
    },
    tooltip: {
      y: {
        formatter(val: number) {
          return `$ ${convertCurrencyBrazil(val)}`;
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: '#255',
        },
      },
      position: 'top',
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
  };

  return (
    <Flex
      bg="#0C6E9C"
      rounded={10}
      align="center"
      justify="center"
      paddingY="2"
    >
      {seriesChart && (
        <ApexChart
          options={optionsChart}
          series={seriesChart}
          type="bar"
          width={widthReal}
          height={height}
        />
      )}
    </Flex>
  );
}
