import React, { ElementType } from 'react';
import {
  Link as ChakraLink,
  Text,
  Icon,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

interface NavLinkProps extends ChakraLinkProps {
  icon: ElementType;
  children: string;
  href: string;
}
export function NavLink({
  icon,
  children,
  href,
  ...rest
}: NavLinkProps): JSX.Element {
  const { pathname } = useLocation();

  const isActive = pathname === href;

  return (
    <ChakraLink
      href={href}
      display="flex"
      align="center"
      {...rest}
      color={isActive ? '#C2DCFF' : 'gray.50'}
    >
      <Icon as={icon} fontSize="20" />
      <Text ml="4" fontWeight="medium">
        {children}
      </Text>
    </ChakraLink>
  );
}
