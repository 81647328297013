import React from 'react';
import {
  Progress,
  ProgressLabel,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';

interface GridItemOverview {
  title: string;
  value: number;
  label: string | number;
  symbol: '$' | 'R$' | '%';
}

export function GridItemOverview({
  title,
  value,
  label,
  symbol,
}: GridItemOverview): JSX.Element {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <SimpleGrid columns={2} mt="4">
      <Text fontSize={isWideVersion ? '20' : '12'}>{title}</Text>

      <Progress value={value} colorScheme="linkedin" size="lg" p="4">
        <ProgressLabel
          color="blackAlpha.900"
          fontSize={isWideVersion ? '20' : '12'}
        >
          {symbol === '$' || symbol === 'R$' ? (
            <NumberFormat
              fixedDecimalScale
              decimalSeparator=","
              thousandSeparator="."
              displayType="text"
              decimalScale={2}
              value={label}
              prefix={`${symbol} `}
            />
          ) : (
            `${label}${symbol}`
          )}
        </ProgressLabel>
      </Progress>
    </SimpleGrid>
  );
}
