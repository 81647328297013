import React from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';

type ResetPasswordFormData = {
  password: string;
  newPassword: string;
  password_confirmation: string;
};

const resetPasswordFormSchema = yup.object().shape({
  password: yup.string().required('Senha antiga é obrigatório'),
  newPassword: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres'),
  password_confirmation: yup
    .string()
    .oneOf([null, yup.ref('newPassword')], 'As senhas precisam ser iguais'),
});

export function ResetPasswordModal(): JSX.Element {
  const { user } = useAuth();
  const { isOpenResetPassword, handleCloseResetPassword } = useReactModal();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(resetPasswordFormSchema),
    defaultValues: {
      password: '',
      newPassword: '',
      password_confirmation: '',
    },
  });

  const toast = useToast();

  const { errors } = formState;

  const handleNewWithdraw: SubmitHandler<ResetPasswordFormData> =
    async values => {
      try {
        const { password, newPassword } = values;
        const request = {
          user,
          password,
          newPassword,
        };

        await api.put('users/password/new', request);

        toast({
          title: 'Senha alterada com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });

        handleCloseResetPassword();
      } catch (error) {
        const { data, status } = error.response;
        toast({
          title: 'Erro ao resetar sua senha, tente novamente!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
          description: status === 400 ? data : '',
        });
      }
    };

  return (
    <Modal isOpen={isOpenResetPassword} onClose={handleCloseResetPassword}>
      <ModalOverlay />

      <ModalContent bg="gray.800" p="4">
        <ModalHeader>Resetar senha</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex
            as="form"
            onSubmit={handleSubmit(handleNewWithdraw)}
            flexDir="column"
            gridGap="2"
          >
            <Input
              label="Senha antiga"
              type="password"
              error={errors.password}
              {...register('password')}
            />

            <Input
              label="Nova senha"
              type="password"
              error={errors.newPassword}
              {...register('newPassword')}
            />

            <Input
              label="Confirme a nova senha"
              type="password"
              error={errors.password_confirmation}
              {...register('password_confirmation')}
            />

            <Flex gridGap="4" mt="4" alignSelf="flex-end">
              <Button
                type="button"
                colorScheme="whiteAlpha"
                onClick={handleCloseResetPassword}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="pink"
                isLoading={formState.isSubmitting}
              >
                Resetar senha
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
