import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Stack,
  useBreakpointValue,
  useToast,
  Heading,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Input } from '../Input';
import { Select } from '../Select';
import { Checkbox } from '../Checkbox';
import { useAuth } from '../../hooks/auth';
import { api } from '../../service/api';
// import { convertCurrencyBrazil } from '../../utils/convertData';
import { useReactModal } from '../../contexts/ReactModal';
import { LoadingSpinner } from '../LoadingSpinner';

type UpdateUserFormData = {
  name: string;
  cpf: string;
  email: string;
  birthdate: string;
  country: string;
  genre: string;
  phone: string;
  phoneHome: string;
  cep: string;
  street: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  number: number;
  identityImage: File;
  adressImage: File;
  contractConfirmation: boolean;
  franchiseId: number;
};

interface CepProps {
  cep: string;
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
}

// interface Franchise {
//   id: number;
//   name: string;
//   price: number;
// }

// interface Franchises {
//   id: number;
//   franchise: Franchise;
// }

// interface UserFranchises {
//   franchises: Franchises[];
// }

const createUserFormSchema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  cpf: yup
    .string()
    .required('Documento é obrigatório')
    // .length(18, 'Digite um C.P.F. válido')
    .nullable(),
  email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  birthdate: yup.string().required('Data de nascimento obrigatória'),
  country: yup.string().required('Nacionalidade obrigatória').nullable(),
  genre: yup.string().required('Gênero obrigatório'),
  phone: yup
    .string()
    .required('Telefone celular obrigatório')
    // .length(15)
    .nullable(),
  phoneHome: yup.string().nullable(),
  cep: yup.string().required('Cep obrigatório').nullable(),
  street: yup.string().required('Preencha o seu cep.').nullable(),
  number: yup.string().required('Número da residência.').nullable(),
  neighborhood: yup.string().required('Preencha o seu cep.').nullable(),
  city: yup.string().required('Preencha o seu cep.').nullable(),
  state: yup.string().required('Preencha o seu cep.').nullable(),
  complement: yup.string().nullable(),
  contractConfirmation: yup
    .boolean()
    .required('Você precisa aceitar os termos e condições.'),
  // franchiseId: yup
  //   .number()
  //   .required('Selecione uma franquia')
  //   .typeError('Selecione uma franquia'),
  // identityImage: yup
  //   .mixed()
  //   .required('Comprovação de identidade necessário')
  //   .test('fileName', 'Documentação necessário', value => {
  //     return value[0] && value[0].name !== '';
  //   })
  //   .nullable(),
  // adressImage: yup
  //   .mixed()
  //   .required('Comprovante de endereço necessário')
  //   .test('fileName', 'Documentação necessário', value => {
  //     return value[0] && value[0].name !== '';
  //   })
  //   .nullable(),
});

interface ImagesProps {
  identityImage: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
  adressImage: {
    id: number;
    name: string;
    contentType: string;
    size: number;
    content: string;
  };
}

// interface FranchisedProps {
//   id: number;
//   name: string;
//   price: number;
// }

const MAX_SIZE_FILE = 2097152; // 2mb

export function EditDataUser(): JSX.Element {
  const { user, updateUser } = useAuth();
  const { handleOpenContractModal } = useReactModal();

  const [identityImage, setIdentityImage] = useState<File>({} as File);
  const [adressImage, setAdressImage] = useState<File>({} as File);
  const [images, setImages] = useState<ImagesProps>({} as ImagesProps);
  // const [franchises, setFranchises] = useState<FranchisedProps[]>([]);
  // const [userFranchisedId, setUserFranchisedId] = useState(0);
  // const [selectedFranchisedId, setSelectedFranchisedId] = useState(0);
  // const [userHasFranchised, setUserHasFranchised] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const history = useHistory();

  const { register, handleSubmit, formState, setValue, clearErrors, setError } =
    useForm({
      resolver: yupResolver(createUserFormSchema),
      defaultValues: {
        name: user.name,
        cpf: user.cpf,
        email: user.email,
        birthdate:
          user.birthDate.toString() === '0001-01-01T00:00:00'
            ? ''
            : format(new Date(user.birthDate), 'dd/MM/yyyy'),
        genre: user.gender === 'female' ? 'female' : 'male',
        phone: user.phoneNumber,
        phoneHome: user.phoneNumberAux !== 'null' ? user.phoneNumberAux : '',
        cep: user.cep,
        street: user.street,
        number: user.number,
        neighborhood: user.neighborhood,
        city: user.city,
        state: user.state,
        complement: user.complement !== 'null' ? user.complement : '',
        country: user.country,
        // franchiseId: selectedFranchisedId,
        contractConfirmation: false,
      },
    });

  const { errors } = formState;

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  useEffect(() => {
    try {
      setIsLoading(true);
      api.get<ImagesProps>(`users/${user.id}`).then(response => {
        setImages(response.data);
      });

      // api.get<FranchisedProps[]>('franchises').then(response => {
      //   setFranchises(response.data);
      // });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [user.id]);

  // useEffect(() => {
  //   setValue('franchiseId', selectedFranchisedId);
  // }, [selectedFranchisedId, setValue]);

  const handleGetCep = async (cep: string) => {
    const formattefZipCod = cep.replace('-', '').replace('_', '');

    if (formattefZipCod.length !== 8) {
      setValue('street', '');
      setValue('neighborhood', '');
      setValue('city', '');
      setValue('state', '');
      setValue('country', '');
      return;
    }

    await axios
      .get<CepProps>(`https://viacep.com.br/ws/${formattefZipCod}/json/`)
      .then(response => {
        setValue('street', response.data.logradouro);
        setValue('neighborhood', response.data.bairro);
        setValue('city', response.data.localidade);
        setValue('state', response.data.uf);
        setValue('country', 'Brasil');
      });

    clearErrors('street');
    clearErrors('neighborhood');
    clearErrors('city');
    clearErrors('state');
    clearErrors('country');
  };

  const handleUpdateUser: SubmitHandler<UpdateUserFormData> = async values => {
    try {
      // if (user.role === 2 && selectedFranchisedId === 0) {
      //   setError('franchiseId', { message: 'Escolha a fraquia desajada' });
      //   return;
      // }

      if (!values.contractConfirmation) {
        setError('contractConfirmation', {
          message: 'Você precisa aceitar os termos e condições.',
        });
        return;
      }

      await updateUser({
        name: values.name,
        cpf: values.cpf,
        email: values.email,
        birthDate: values.birthdate,
        country: values.country,
        gender: values.genre,
        phoneNumber: values.phone,
        phoneNumberAux: values.phoneHome,
        cep: values.cep,
        street: values.street,
        neighborhood: values.neighborhood,
        city: values.city,
        state: values.state,
        complement: values.complement,
        number: values.number,
        identityImage,
        adressImage,
        contractConfirmation: values.contractConfirmation,
      });

      // if (user.role === 2 && selectedFranchisedId > 0) {
      //   const createFranchiseed = {
      //     user: user.id,
      //     franchise: selectedFranchisedId,
      //   };

      //   if (userHasFranchised) {
      //     const formData = new FormData();

      //     formData.append('user', String(user.id));
      //     formData.append('franchise', String(selectedFranchisedId));

      //     await api.put(`/franchises/user/${userFranchisedId}`, formData, {
      //       headers: {
      //         'Content-Type': `multipart/form-data;`,
      //       },
      //     });
      //   } else {
      //     await api.post('/franchises/user', createFranchiseed);
      //   }
      // }

      toast({
        title: 'Investidor atualizado com sucesso.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      history.push('/');
    } catch (error) {
      const { data, status } = error.response;

      const erroDescrition = typeof data === 'string' ? data : '';

      toast({
        title: 'Erro ao atualizar as informações do usuário',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: status === 400 ? erroDescrition : '',
        position: 'top-right',
      });
    }
  };

  function handleIdentityImage(e: ChangeEvent<HTMLInputElement>) {
    let arquivo = e.target.files[0];
    if (arquivo && arquivo.type && arquivo.type.startsWith('image/')) {
      if (e.target.files[0].size > MAX_SIZE_FILE) {
        e.target.value = '';
        alert('O comprovante tem que ter tamanho máximo de 2MB.');
      }
      setIdentityImage(arquivo);
    } else {
      e.target.value = '';
      alert('Por favor, selecione um arquivo de imagem!');
    }
  }

  function handleAdressImage(e: ChangeEvent<HTMLInputElement>) {
    let arquivo = e.target.files[0];
    if (arquivo && arquivo.type && arquivo.type.startsWith('image/')) {
      if (e.target.files[0].size > MAX_SIZE_FILE) {
        e.target.value = '';
        alert('O comprovante tem que ter tamanho máximo de 2MB.');
      }
      setAdressImage(arquivo);
    } else {
      e.target.value = '';
      alert('Por favor, selecione um arquivo de imagem!');
    }
  }

  // const selectedFranchised = useMemo(() => {
  //   const selectedFranchised = franchises.find(
  //     item => item.id === selectedFranchisedId,
  //   );

  //   return {
  //     name: selectedFranchised?.name,
  //     price: convertCurrencyBrazil(selectedFranchised?.price || 0),
  //   };
  // }, [franchises, selectedFranchisedId]);

  if (isLoading) {
    <Flex
      align="center"
      justify="space-around"
      flexDir="column"
      gridGap="4"
      rounded="10px"
      color="white"
      flex="1"
      p="4"
    >
      <LoadingSpinner />
    </Flex>;
  }

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(handleUpdateUser)}
      p="4"
      width="100%"
      h="100%"
      bg="#0C6E9C"
    >
      <Flex
        as="fieldset"
        p="4"
        color="white"
        border="1px"
        borderStyle="solid"
        borderColor="gray.200"
      >
        <legend>Informações Pessoais</legend>
        <Stack spacing="4" width="100%">
          <Flex gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
            <Input
              placeholder="Nome"
              isReadOnly={user.activeStatus === 1}
              error={errors.name}
              {...register('name')}
            />

            <Input
              placeholder="Documento"
              isReadOnly={user.activeStatus === 1}
              error={errors.cpf}
              mask="cpf"
              {...register('cpf')}
            />
          </Flex>

          <Flex
            justify="space-between"
            gridGap="4"
            flexDir={isWideVersion ? 'row' : 'column'}
          >
            <Input
              placeholder="E-mail"
              type="email"
              isReadOnly={user.activeStatus === 1}
              error={errors.email}
              {...register('email')}
            />
            <Input
              placeholder="Data de nascimento"
              mask="date"
              isReadOnly={user.activeStatus === 1}
              error={errors.birthdate}
              {...register('birthdate')}
            />
          </Flex>

          <Flex
            justify="space-between"
            gridGap="4"
            flexDir={isWideVersion ? 'row' : 'column'}
          >
            {user.activeStatus === 1 ? (
              <Input
                name="gender"
                placeholder="Gênero"
                mask="date"
                isReadOnly
                value={user.gender === 'male' ? 'Masculino' : 'Feminino'}
              />
            ) : (
              <Select
                placeholder="Gênero"
                isReadOnly={user.activeStatus === 1}
                error={errors.genre}
                {...register('genre')}
              >
                <option style={{ color: '#000' }} value="male">
                  Masculino
                </option>
                <option style={{ color: '#000' }} value="female">
                  Feminino
                </option>
              </Select>
            )}

            <Input
              placeholder="Celular"
              error={errors.phone}
              mask="mobile"
              isReadOnly={user.activeStatus === 1}
              {...register('phone')}
            />
            <Input
              placeholder="Telefone"
              mask="phone"
              isReadOnly={user.activeStatus === 1}
              error={errors.phoneHome}
              {...register('phoneHome')}
            />
          </Flex>
        </Stack>
      </Flex>

      <Flex
        as="fieldset"
        p="4"
        mt="4"
        color="white"
        border="1px"
        borderStyle="solid"
        borderColor="gray.200"
      >
        <legend>Endereço</legend>
        <Stack spacing="4" width="100%">
          <Flex
            justify="space-between"
            gridGap="4"
            flexDir={isWideVersion ? 'row' : 'column'}
          >
            <Input
              placeholder="CEP"
              mask="cep"
              isReadOnly={user.activeStatus === 1}
              error={errors.cep}
              {...register('cep')}
              onChange={event => handleGetCep(event.target.value)}
            />
            <Input
              placeholder="Rua"
              isReadOnly={user.activeStatus === 1}
              error={errors.street}
              {...register('street')}
            />
            <Input
              placeholder="Número"
              isReadOnly={user.activeStatus === 1}
              error={errors.number}
              {...register('number')}
            />
          </Flex>

          <Flex
            justify="space-between"
            gridGap="4"
            flexDir={isWideVersion ? 'row' : 'column'}
          >
            <Input
              placeholder="Cidade"
              error={errors.city}
              isReadOnly={user.activeStatus === 1}
              {...register('city')}
            />
            <Input
              placeholder="Estado"
              isReadOnly={user.activeStatus === 1}
              error={errors.state}
              {...register('state')}
            />
            <Input
              placeholder="País"
              error={errors.country}
              isReadOnly={user.activeStatus === 1}
              {...register('country')}
            />
          </Flex>

          <Flex
            justify="space-between"
            gridGap="4"
            flexDir={isWideVersion ? 'row' : 'column'}
          >
            <Input
              placeholder="Bairro"
              isReadOnly={user.activeStatus === 1}
              error={errors.neighborhood}
              {...register('neighborhood')}
            />
            <Input
              placeholder="Complemento"
              isReadOnly={user.activeStatus === 1}
              error={errors.complement}
              {...register('complement')}
            />
          </Flex>
        </Stack>
      </Flex>

      {/* {user.role === 2 && user.activeStatus !== 1 && (
        <Flex
          as="fieldset"
          p="4"
          mt="4"
          color="white"
          border="1px"
          borderStyle="solid"
          borderColor="gray.200"
          gridGap={10}
        >
          <legend>Escolha uma franquia</legend>
          <Select
            placeholder="Escolha uma franquia"
            label="Franquias"
            error={errors.franchiseId}
            {...register('franchiseId')}
            onChange={event =>
              setSelectedFranchisedId(Number(event.target.value))
            }
          >
            {franchises.map(franchises => (
              <option
                style={{ color: '#000' }}
                key={franchises.id}
                value={franchises.id}
              >
                {franchises.name}
              </option>
            ))}
          </Select>

          <Flex
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            gridGap={10}
          >
            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <Heading fontSize="large" color="#666">
                Franquia selecionada:
              </Heading>
              <Text fontSize="x-large" mt="15px" fontWeight="bold">
                {selectedFranchised?.name}
              </Text>
            </Flex>

            <Flex flexDir="column" justifyContent="center" alignItems="center">
              <Heading fontSize="large" color="#666">
                Valor da Franquia:
              </Heading>
              <Text fontSize="x-large" mt="15px" fontWeight="bold">
                $ {selectedFranchised?.price}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )} */}

      <Flex
        as="fieldset"
        p="2"
        mt="4"
        color="white"
        border="1px"
        borderStyle="solid"
        borderColor="gray.200"
      >
        <legend>Fotos</legend>
        <Flex
          justify="space-between"
          gridGap="4"
          flexDir={isWideVersion ? 'row' : 'column'}
          width="100%"
        >
          <Flex align="center" flex="1" flexDir="column">
            <Text mb="2" fontSize="2xl">
              Comprovante de indentidade
            </Text>
            {images.identityImage && (
              <Image
                src={`data:${images.identityImage.contentType};base64, ${images.identityImage.content}`}
                alt="Comprovante de indentidade"
                h="200px"
                id="identityImageSrc"
                objectFit="cover"
              />
            )}

            {user.activeStatus !== 1 && (
              <Input
                name="identityImage"
                variant="flushed"
                padding="2"
                id="identityImage"
                type="file"
                width="100%"
                accept="image/png, image/jpeg"
                onChange={handleIdentityImage}
              />
            )}
          </Flex>

          <Flex align="center" flex="1" flexDir="column">
            <Text mb="2" fontSize="2xl">
              Comprovante de endereço
            </Text>
            {images.adressImage && (
              <Image
                src={`data:${images.adressImage.contentType};base64, ${images.adressImage.content}`}
                alt="Comprovante de endereço"
                id="adressImageSrc"
                h="200px"
                objectFit="cover"
              />
            )}

            {user.activeStatus !== 1 && (
              <Input
                name="adressImage"
                variant="flushed"
                padding="2"
                id="adressImage"
                type="file"
                width="100%"
                accept="image/png, image/jpeg"
                onChange={handleAdressImage}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      {user.activeStatus !== 1 && (
        <Flex mt="4">
          <Flex alignItems="center" gridGap={2}>
            <Checkbox
              error={errors.contractConfirmation}
              {...register('contractConfirmation')}
            >
              Eu li e concordo com os termos de uso.
            </Checkbox>
            |
            <Button
              ml="5"
              variant="link"
              type="button"
              onClick={handleOpenContractModal}
              color="blue"
            >
              Abrir contrato
            </Button>
          </Flex>
          <Button
            type="submit"
            colorScheme="green"
            isLoading={formState.isSubmitting}
            w="28"
            ml="auto"
          >
            Salvar
          </Button>
        </Flex>
      )}
    </Box>
  );
}
