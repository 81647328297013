import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Text,
  FormLabel,
  Image,
  TagLabel,
} from '@chakra-ui/react';
import * as yup from 'yup';

import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReactModal } from '../../contexts/ReactModal';

import { Input } from '../Input';
import { api } from '../../service/api';
import { useAuth } from '../../hooks/auth';
import { convertCurrencyAmerican } from '../../utils/convertData';
import logoBnb from '../../assets/bnb-icon.svg';

type NewInvestmentProps = {
  amount: string;
  hashTransactionBep20: string;
};

const newInvestmentPropsSchema = yup.object().shape({
  amount: yup.string().required('Valor do depósito obrigatório'),
});

const MAX_SIZE_FILE = 2097152; // 2mb

export function NewInvestment(): JSX.Element {
  const { user, validToken } = useAuth();
  const { isOpenNewInvestment, handleCloseNewInvestment } = useReactModal();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(newInvestmentPropsSchema),
    defaultValues: {
      amount: '',
      hashTransactionBep20: '',
    },
  });

  const { errors } = formState;

  const toast = useToast();

  const handleNewInvestment: SubmitHandler<NewInvestmentProps> =
    async values => {
      try {
        const formData = new FormData();

        formData.append('amount', convertCurrencyAmerican(values.amount));

        formData.append('hashTransactionBep20', values.hashTransactionBep20);

        await api.post(`investments/${user.id}?culture=pt-BR`, formData, {
          headers: {
            Authorization: `Bearer ${validToken()}`,
            'Content-Type': `multipart/form-data;`,
          },
        });

        toast({
          title: 'Depósito solicitado com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });

        handleCloseNewInvestment();
      } catch (error) {
        const { data, status } = error.response;

        const erroDescrition = typeof data === 'string' ? data : '';

        toast({
          title: 'Erro no depósito!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          description: status === 400 ? erroDescrition : '',
          position: 'top-right',
        });
      }
    };

  return (
    <Modal
      isOpen={isOpenNewInvestment}
      size="xl"
      onClose={handleCloseNewInvestment}
    >
      <ModalOverlay />

      <ModalContent bg="gray.800" p="3">
        <ModalHeader display="flex">
          <Image
            src={logoBnb}
            alt="Logo BNB"
            fit="cover"
            maxW="50px"
            marginRight="0.5rem"
          />
          Depósito BUSD
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Flex
            as="form"
            onSubmit={handleSubmit(handleNewInvestment)}
            flexDir="column"
            gridGap="2"
          >
            <FormLabel>
              Carteira para depósito de BUSD: <br />
              0x012af3b45ebca4e379b2fc51d608ff29bb931d52 <br /> Atenção! O envio
              deverá ser feito através da rede BSC - BNB Smart Chain (BEP20)
            </FormLabel>
            <Input
              label="Valor do depósito"
              mask="currency"
              icon="$ "
              error={errors.amount}
              {...register('amount')}
            />

            <Input
              label="Hash da Transação"
              icon="0x"
              error={errors.hashTransactionBep20}
              {...register('hashTransactionBep20')}
            />

            <Flex gridGap="4" mt="4" alignSelf="flex-end">
              <Button
                type="button"
                colorScheme="whiteAlpha"
                onClick={handleCloseNewInvestment}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                bg="#0C6E9C"
                isLoading={formState.isSubmitting}
              >
                Solicitar
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
