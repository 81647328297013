import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
} from '@chakra-ui/react';

import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
// import { FirstSteps } from '../../components/BackupFile/FirstSteps';
import { Presentation } from '../../components/BackupFile/Presentation';
import { ContractsDocuments } from '../../components/BackupFile/ContractsDocuments';

export function BackupFile(): JSX.Element {
  return (
    <Flex h="100vh" bg="#C9C9C9">
      <Sidebar />

      <Flex direction="column" w="100%">
        <Header />

        <Box p={4}>
          <Accordion allowToggle defaultIndex={[0]} bg="#0C6E9C">
            {/* <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading>Como fazer saque do meu saldo</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <FirstSteps />
              </AccordionPanel>
            </AccordionItem> */}

            {/* <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading>Apresentação</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Presentation />
              </AccordionPanel>
            </AccordionItem> */}

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Heading>Contratos e Documentos</Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <ContractsDocuments />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Flex>
    </Flex>
  );
}
