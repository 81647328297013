import React, {
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Flex,
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useMediaQuery,
  IconButton,
  Button,
  useDisclosure,
  Collapse,
  Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { FiCheckCircle, FiEdit3, FiDollarSign } from 'react-icons/fi';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import NumberFormat from 'react-number-format';

import { api } from '../../service/api';
import { useReactModal } from '../../contexts/ReactModal';
import { Input } from '../Input';
import { Select } from '../Select';
import {
  convertCurrencyAmericanNumber,
  convertCurrencyBrazil,
} from '../../utils/convertData';
import { useSortableData } from '../../hooks/useSortableData';

const status = [
  'Inativo',
  'Ativo',
  'Cadastro incompleto',
  'Contrato pendente',
  'Aguardando aprovação',
];
interface User {
  id: number;
  activeStatus: number;
  name: string;
  username: string;
  cpf: string;
  email: string;
  balance: number;
  creationDate: Date;
}

interface UserFormatted {
  id: number;
  activeStatus: number;
  name: string;
  username: string;
  cpf: string;
  email: string;
  balance: string;
  creationDate: Date;
}

interface Props {
  setIsLoading: (isLoading: boolean) => void;
}

export function UsersManagement({ setIsLoading }: Props): JSX.Element {
  const [users, setUsers] = useState<UserFormatted[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<UserFormatted[]>([]);
  const [usersPagi, setUsersPagi] = useState<UserFormatted[]>([]);
  const [name, setName] = useState('');
  const [balance, setBalance] = useState(0);
  const [statusData, setStatusData] = useState(-1);
  const { items, requestSort, sortConfig } = useSortableData({
    items: usersFiltered,
    config: { key: 'name', direction: undefined, type: undefined },
  });

  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const [isLargerThan1368] = useMediaQuery('(min-width: 1368px)');

  const [currentPage, setCurrentPage] = useState(1);

  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

  const indexOfLastItem = useMemo(() => currentPage * 7, [currentPage]);
  const indexOfFirstItem = useMemo(
    () => indexOfLastItem - 7,
    [indexOfLastItem],
  );

  const { handleConfirmedUserOpen, handleOpenNewInvestmentAdmModal } =
    useReactModal();

  const history = useHistory();

  function capitalizeFirst(str: string) {
    let subst = str?.toLowerCase().replace(/(?:^|\s)\S/g, a => {
      return a.toUpperCase();
    });

    return subst;
  }

  useEffect(() => {
    try {
      setIsLoading(true);
      api.get<User[]>('users').then(response => {
        const usersFormatted = response.data.map(user => {
          return {
            ...user,
            name: capitalizeFirst(user.name),
            balance: convertCurrencyBrazil(user.balance),
          };
        });

        const usersFormattedSortedByCreationDate = usersFormatted.sort(
          (a, b) => {
            if (a.creationDate > b.creationDate) return -1;

            if (a.creationDate < b.creationDate) return 1;

            return 0;
          },
        );

        setUsers(usersFormattedSortedByCreationDate);
      });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUsersFiltered(users);
  }, [users]);

  useEffect(() => {
    setUsersPagi(items.slice(indexOfFirstItem, indexOfLastItem));
  }, [indexOfFirstItem, indexOfLastItem, items, usersFiltered]);

  useEffect(() => {
    if (isLargerThan1368) {
      onOpen();
    } else {
      onClose();
    }
  }, [isLargerThan1368, onOpen, onClose]);

  function handleNewInvestiment(idUser: number) {
    handleOpenNewInvestmentAdmModal(idUser);
  }

  function handleFilterUsers() {
    setUsersFiltered(
      users.filter(user => {
        const balanceFilter = convertCurrencyAmericanNumber(user.balance);
        return (
          (user.name?.toLowerCase().includes(name?.toLowerCase().trim()) ||
            user.username?.toLowerCase().includes(name.toLowerCase().trim())) &&
          balanceFilter >= balance &&
          (statusData === -1 || user.activeStatus === statusData)
        );
      }),
    );

    setCurrentPage(1);
    setMinPageNumberLimit(0);
    setMaxPageNumberLimit(7);

    if (!isLargerThan1368) onClose();
  }

  function handleClearFilterUsers() {
    setUsersFiltered(users);

    setName('');
    setBalance(0);
    setStatusData(-1);
    setCurrentPage(1);
    setMinPageNumberLimit(0);
    setMaxPageNumberLimit(7);

    if (!isLargerThan1368) onClose();
  }

  const handleNextbtn = useCallback(() => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + 7);
      setMinPageNumberLimit(minPageNumberLimit + 7);
    }
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit]);

  const handlePrevbtn = useCallback(() => {
    setCurrentPage(currentPage - 1);

    if ((currentPage - 1) % 7 === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - 7);
      setMinPageNumberLimit(minPageNumberLimit - 7);
    }
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit]);

  const pages = useMemo(() => {
    const pages = [] as number[];

    if (!usersFiltered || usersFiltered.length <= 1) return pages;

    const sizeList = Math.ceil(usersFiltered.length / 7);
    for (let i = 1; i <= sizeList; i++) {
      pages.push(i);
    }

    return pages;
  }, [usersFiltered]);

  const renderPageNumbers: ReactElement[] = useMemo(() => {
    const buttonsPagination = [] as ReactElement[];
    pages.forEach(number => {
      if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
        const button = (
          <Button
            key={number}
            colorScheme={number === currentPage ? 'facebook' : 'twitter'}
            onClick={() => setCurrentPage(number)}
            color="#FFF"
          >
            {number}
          </Button>
        );

        buttonsPagination.push(button);
      }
    });

    return buttonsPagination;
  }, [currentPage, maxPageNumberLimit, minPageNumberLimit, pages]);

  const showButtonsPagination = useMemo(() => {
    return renderPageNumbers.length > 0;
  }, [renderPageNumbers]);

  const getIconSort = (direction: 'ascending' | 'descending'): ReactElement => {
    return direction === 'ascending' ? <FaSortDown /> : <FaSortUp />;
  };

  const getIconFor = (name: string): ReactElement | undefined => {
    if (!sortConfig || !sortConfig.direction) {
      return <FaSort />;
    }

    return sortConfig.key === name
      ? getIconSort(sortConfig.direction)
      : undefined;
  };

  const listCurrentPage = useMemo(() => {
    let listCurrentPage = currentPage * 7;

    if (listCurrentPage >= usersFiltered.length) {
      listCurrentPage = usersFiltered.length;
    }

    return listCurrentPage;
  }, [currentPage, usersFiltered.length]);

  function handleKeyPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const { nativeEvent } = event;
    if (nativeEvent.key === 'Enter') {
      handleFilterUsers();
    }
  }

  return (
    <Flex flexDir="column" gridGap="4">
      <Box p="2" bg="whiteAlpha" overflowX="auto">
        {!isLargerThan1368 && (
          <Button
            colorScheme="teal"
            alignSelf="center"
            marginY="2"
            onClick={onToggle}
          >
            Filtros
          </Button>
        )}

        <Collapse animateOpacity in={isOpen}>
          <Flex
            gridGap="4"
            justify="space-between"
            alignItems="flex-end"
            bg="gray.800"
            p="4"
            rounded={10}
          >
            <Input
              name="name"
              label="Nome"
              placeholder="Nome ou usuário"
              value={name}
              onChange={event => setName(event.target.value)}
              onKeyPress={handleKeyPressEnter}
            />

            <NumberFormat
              prefix="$ "
              placeholder="Saldo do usuário"
              allowNegative={false}
              decimalSeparator=","
              decimalScale={2}
              thousandSeparator="."
              onValueChange={values => {
                const { floatValue } = values;
                setBalance(floatValue || 0);
              }}
              onKeyPress={handleKeyPressEnter}
              style={{
                width: '100%',
                outline: '2px solid transparent',
                outlineOffset: 2,
                backgroundColor: '#181B23',
                height: '3rem',
                borderRadius: '0.375rem',
                border: '2px solid trasparent',
                fontSize: '1.125rem',
                paddingInlineStart: '1rem',
                paddingInlineEnd: '2.5rem',
              }}
            />

            <Select
              name="status"
              label="Status"
              value={statusData}
              onChange={event => setStatusData(Number(event.target.value))}
            >
              <option value={-1}>Todos</option>
              <option value={0}>Inativo</option>
              <option value={1}>Ativo</option>
              <option value={2}>Cadastro incompleto</option>
              <option value={4}>Aguardando Aprovação</option>
            </Select>
            <Flex mb="1" gridGap={4}>
              <Button
                colorScheme="teal"
                type="button"
                onClick={handleFilterUsers}
              >
                Pesquisar
              </Button>
              <Button
                type="button"
                colorScheme="whiteAlpha"
                variant="outline"
                onClick={handleClearFilterUsers}
              >
                Limpar
              </Button>
            </Flex>
          </Flex>
        </Collapse>

        <Box
          hidden={isOpen && !isLargerThan1368}
          overflowY="auto"
          h="100%"
          maxH={isLargerThan1368 ? '550px' : '360px'}
          maxW={isLargerThan1368 ? '100%' : '998px'}
          sx={{
            '::-webkit-scrollbar': {
              width: '4px',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#666',
              borderRadius: '4px',
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: '#2f3136',
            },
          }}
        >
          <Table bg="#0C6E9C" mt="10px" rounded={10}>
            <Thead>
              <Tr>
                <Th color="#fff">
                  <Flex align="center">
                    {getIconFor('name')}
                    <Button
                      color="#fff"
                      variant="link"
                      type="button"
                      textTransform="uppercase"
                      fontSize="12px"
                      onClick={() => requestSort('name', 'string')}
                    >
                      Nome
                    </Button>
                  </Flex>
                </Th>
                <Th color="#fff">Usuário</Th>
                <Th color="#fff">Email</Th>
                <Th color="#fff">Documento</Th>
                <Th color="#fff">
                  <Flex align="center">
                    {getIconFor('balance')}
                    <Button
                      marginY="auto"
                      color="#fff"
                      variant="link"
                      type="button"
                      textTransform="uppercase"
                      fontSize="12px"
                      onClick={() => requestSort('balance', 'number')}
                    >
                      Saldo em Dólar
                    </Button>
                  </Flex>
                </Th>
                <Th color="#fff">
                  <Flex align="center">
                    {getIconFor('activeStatus')}
                    <Button
                      color="#fff"
                      variant="link"
                      type="button"
                      textTransform="uppercase"
                      fontSize="12px"
                      onClick={() => requestSort('activeStatus', 'number')}
                    >
                      Situação
                    </Button>
                  </Flex>
                </Th>
                <Th color="#fff">Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {usersPagi.map(user => (
                <Tr key={user.id}>
                  <Td>{user.name}</Td>
                  <Td>{user.username}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.cpf}</Td>
                  <Td>$ {user.balance}</Td>
                  <Td>{status[user.activeStatus]}</Td>
                  <Td>
                    <Flex gridGap="2">
                      <IconButton
                        colorScheme="blue"
                        aria-label="Editar investidor"
                        icon={<FiEdit3 />}
                        onClick={() =>
                          history.push(`/edit-user-adm/${user.id}`)
                        }
                      />

                      <IconButton
                        colorScheme="green"
                        aria-label="Novo depósito"
                        icon={<FiDollarSign />}
                        onClick={() => handleNewInvestiment(user.id)}
                      />

                      {user.activeStatus >= 2 && (
                        <IconButton
                          colorScheme="green"
                          aria-label="Ativar investidor"
                          icon={<FiCheckCircle />}
                          onClick={() => handleConfirmedUserOpen(user.id)}
                        />
                      )}
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        {showButtonsPagination && (
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <Text fontSize="large">
                {listCurrentPage} de {usersFiltered.length}
              </Text>
            </Box>
            <Flex
              gridGap={3}
              overflowY="auto"
              mt="5"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                colorScheme="twitter"
                onClick={handlePrevbtn}
                disabled={currentPage === pages[0]}
                color="#FFF"
              >
                Anterior
              </Button>

              {renderPageNumbers}

              <Button
                colorScheme="twitter"
                onClick={handleNextbtn}
                disabled={currentPage === pages[pages.length - 1]}
                color="#FFF"
              >
                Próximo
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
