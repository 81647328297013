import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react';
import { addMonths, format, setDate } from 'date-fns';
import { CSSObject } from '@emotion/react';
import { FiSearch } from 'react-icons/fi';
import {
  FaChartBar,
  FaList,
  FaSort,
  FaSortDown,
  FaSortUp,
} from 'react-icons/fa';
import { api } from '../../service/api';
import { convertCurrencyBrazil } from '../../utils/convertData';
import { Input } from '../Input';
import { useSortableData } from '../../hooks/useSortableData';
import { IncomeReportChart } from './IncomeReportChart';

interface User {
  id: number;
  name: string;
  username: string;
}

interface IncomeReportProps {
  incomeReferrals: number;
  incomeTo: number;
  profitabilityDayMonth: number;
  user: User;
}

interface IncomeReportFormatted {
  incomeReferrals: number;
  incomeTo: number;
  incomeReferralsFormatted: string;
  incomeToFormatted: string;
  profitabilityDayMonth: string;
  user: User;
}

const objectScroll: CSSObject = {
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#666',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: '#2f3136',
  },
};

export function IncomeReport(): JSX.Element {
  const [isModeChart, setIsModeChart] = useState(false);
  const [incomes, setIcomes] = useState<IncomeReportProps[]>([]);
  const [incomesFormatted, setIcomesFormatted] = useState<
    IncomeReportFormatted[]
  >([]);

  const [userName, setUserName] = useState('');
  const { items, requestSort, sortConfig } = useSortableData({
    items: incomesFormatted,
    config: { key: 'name', direction: undefined, type: undefined },
  });

  useEffect(() => {
    api.get<IncomeReportProps[]>('admin/incomesdetails').then(response => {
      setIcomes(response.data);
      const incomesReportFormatted = response.data
        .sort((a, b) => {
          const dateA = setDate(new Date(), a.profitabilityDayMonth);
          const dateB = setDate(new Date(), b.profitabilityDayMonth);
          const acttualyDateA =
            dateA > new Date() ? dateA : addMonths(dateA, 1);
          const acttualyDateB =
            dateB > new Date() ? dateB : addMonths(dateB, 1);

          return acttualyDateA < acttualyDateB ? -1 : 1;
        })
        .map(income => {
          const { incomeReferrals, incomeTo, profitabilityDayMonth, user } =
            income;

          const date = setDate(new Date(), profitabilityDayMonth);

          const acttualyDate = date > new Date() ? date : addMonths(date, 1);

          return {
            profitabilityDayMonth: format(acttualyDate, 'dd/MM/yyyy'),
            incomeReferrals,
            incomeTo,
            incomeReferralsFormatted: `$ ${convertCurrencyBrazil(
              incomeReferrals,
            )}`,
            incomeToFormatted: `$ ${convertCurrencyBrazil(incomeTo)}`,
            user,
          };
        });

      setIcomesFormatted(incomesReportFormatted);
    });
  }, []);

  const incomesReportsFiltered = useMemo(() => {
    const name = userName?.toLowerCase();

    return items
      .filter(
        income =>
          income.user.name?.toLowerCase().includes(name) ||
          income.user.username?.toLowerCase().includes(name),
      )
      .map(income => {
        const {
          incomeReferrals,
          incomeTo,
          incomeReferralsFormatted,
          incomeToFormatted,
          profitabilityDayMonth,
          user,
        } = income;

        return {
          incomeReferrals,
          incomeTo,
          incomeReferralsFormatted,
          incomeToFormatted,
          profitabilityDayMonth,
          user,
        };
      });
  }, [items, userName]);

  const icomesForeseenMonth = useMemo(() => {
    const icomesPaidMonth = incomes
      .map(income => income.incomeReferrals)
      .reduce((acc, incomeReferrals) => acc + incomeReferrals, 0);

    const totalIcomeTo = incomes
      .map(income => income.incomeTo)
      .reduce((acc, incomeTo) => acc + incomeTo, 0);

    const total = icomesPaidMonth + totalIcomeTo;

    return `$ ${convertCurrencyBrazil(total)}`;
  }, [incomes]);

  const getIconSort = (direction: 'ascending' | 'descending'): ReactElement => {
    return direction === 'ascending' ? <FaSortDown /> : <FaSortUp />;
  };

  const getIconFor = (name: string): ReactElement | undefined => {
    if (!sortConfig || !sortConfig.direction) {
      return <FaSort />;
    }

    return sortConfig.key === name
      ? getIconSort(sortConfig.direction)
      : undefined;
  };

  return (
    <Flex flexDir="column">
      <Flex w="100%" mb="4" bg="gray.800" p={5}>
        {!isModeChart && (
          <Box w="30%" mr="auto">
            <Input
              name="search"
              icon={<FiSearch color="gray.300" />}
              placeholder="Buscar usuário"
              value={userName}
              onChange={event => setUserName(event.target.value)}
            />
          </Box>
        )}

        <Flex align="center" gridGap="4" ml="10" mr="auto">
          <Text fontSize="lg" fontWeight="bold">
            Rendimetos totais
          </Text>
          <Box p="2" bg="blue.600" rounded={10}>
            <Text fontSize="lg" fontWeight="bold">
              {icomesForeseenMonth}
            </Text>
          </Box>
        </Flex>

        <Button
          colorScheme="white"
          variant="link"
          leftIcon={isModeChart ? <FaList /> : <FaChartBar />}
          onClick={() => setIsModeChart(!isModeChart)}
        >
          {isModeChart ? 'Modo relatório' : 'Modo gráfico'}
        </Button>
      </Flex>

      {isModeChart ? (
        <IncomeReportChart />
      ) : (
        <Box maxH="480px" mt="2" overflowY="auto" sx={objectScroll}>
          <Table bg="#0C6E9C" mt="10px" rounded={10}>
            <Thead>
              <Tr>
                <Th color="#FFF">Nome</Th>
                <Th color="#FFF">Usuário</Th>
                <Th color="#FFF">
                  <Flex align="center">
                    {getIconFor('profitabilityDayMonth')}
                    <Button
                      color="#fff"
                      variant="link"
                      type="button"
                      textTransform="uppercase"
                      fontSize="12px"
                      onClick={() =>
                        requestSort('profitabilityDayMonth', 'date')
                      }
                    >
                      Dia do rendimento
                    </Button>
                  </Flex>
                </Th>
                <Th color="#FFF">
                  <Flex align="center">
                    {getIconFor('incomeToFormatted')}
                    <Button
                      color="#fff"
                      variant="link"
                      type="button"
                      textTransform="uppercase"
                      fontSize="12px"
                      onClick={() => requestSort('incomeToFormatted', 'number')}
                    >
                      Rendimento
                    </Button>
                  </Flex>
                </Th>
                <Th color="#FFF">Indicação</Th>
              </Tr>
            </Thead>
            <Tbody>
              {incomesReportsFiltered.map(income => (
                <Tr key={income.user.id}>
                  <Td>{income.user.name}</Td>
                  <Td>{income.user.username}</Td>
                  <Td>{income.profitabilityDayMonth}</Td>
                  <Td>{income.incomeToFormatted}</Td>
                  <Td>{income.incomeReferralsFormatted}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Flex>
  );
}
