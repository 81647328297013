import React, { useEffect, useState } from 'react';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useReactModal } from '../../contexts/ReactModal';
import { Select } from '../Select';
import { Textarea } from '../Textarea';
import { api } from '../../service/api';
import { LoadingSpinner } from '../LoadingSpinner';

interface NewMessageModalFormData {
  content: string;
  status: string;
}

const newMessageModalFormData = yup.object().shape({
  content: yup.string().required('Conteúdo da mensagem obrigatório'),
  status: yup.string().required('Status obrigatório'),
});

export function NewMessageModal(): JSX.Element {
  const { isOpenNewMessageModal, handleCloseNewMessageModal, idMessage } =
    useReactModal();

  const toast = useToast();

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(newMessageModalFormData),
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (idMessage === 0) {
      setValue('content', '');
      setValue('status', '');
      return;
    }
    try {
      setIsLoading(true);
      api.get(`notices/${idMessage}`).then(response => {
        setValue('content', response.data.content);
        setValue('status', response.data.status);
      });
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  }, [idMessage, setValue]);

  const { errors } = formState;

  const handleFormNewMessage: SubmitHandler<NewMessageModalFormData> =
    async data => {
      try {
        const { content, status } = data;

        const statusCode = status === 'Inativo' ? 0 : 1;

        if (idMessage === 0) {
          await api.post('notices', {
            content,
            status: statusCode,
          });
        } else {
          await api.put(`notices/${idMessage}`, {
            content,
            status: statusCode,
          });
        }

        toast({
          title: `Mensagem ${
            idMessage === 0 ? 'criada' : 'editada'
          } com sucesso!`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });

        handleCloseNewMessageModal();
      } catch {
        toast({
          title: 'Erro oa criar uma nova mensagem!',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      }
    };

  return (
    <Modal
      blockScrollOnMount
      isOpen={isOpenNewMessageModal}
      onClose={handleCloseNewMessageModal}
    >
      <ModalOverlay />

      <ModalContent bg="gray.800" p="10">
        <ModalHeader>Adicionar aviso</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {isLoading ? (
            <Flex
              align="center"
              justify="space-around"
              flexDir="column"
              gridGap="4"
              rounded="10px"
              color="white"
              flex="1"
              p="4"
            >
              <LoadingSpinner />
            </Flex>
          ) : (
            <Flex
              as="form"
              onSubmit={handleSubmit(handleFormNewMessage)}
              flexDir="column"
              gridGap="2"
            >
              <Textarea
                label="Conteúdo"
                error={errors.content}
                {...register('content')}
              />

              <Select
                label="Status"
                error={errors.status}
                {...register('status')}
              >
                <option style={{ background: '#353646' }} value="Ativo">
                  Ativo
                </option>
                <option style={{ background: '#353646' }} value="Inativo">
                  Inativo
                </option>
              </Select>

              <Flex gridGap="4" mt="4" alignSelf="flex-end">
                <Button
                  type="button"
                  colorScheme="whiteAlpha"
                  onClick={handleCloseNewMessageModal}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  bg="#0C6E9C"
                  isLoading={formState.isSubmitting}
                >
                  Salvar
                </Button>
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
