import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  isFranchised?: boolean;
  isActive?: boolean;
  component: React.ComponentType;
}

export const Route = ({
  component: Component,
  isPrivate = false,
  isAdmin = false,
  isFranchised = false,
  isActive,
  ...rest
}: RouteProps) => {
  const { user } = useAuth();
  const isUserAdmin = user && user.role === 0;
  const isUserActive = user && user.activeStatus === 1;
  const isUserFranchised = user && user.role === 2;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        const routeRedirectDashboard = isUserAdmin
          ? '/dashboard-adm'
          : '/dashboard';

        if (!(isPrivate === !!user)) {
          return (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : routeRedirectDashboard,
                state: { from: location },
              }}
            />
          );
        }

        if (isAdmin) {
          return isUserAdmin ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : routeRedirectDashboard,
                state: { from: location },
              }}
            />
          );
        }

        if (isFranchised && !isUserAdmin) {
          return isUserFranchised ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : routeRedirectDashboard,
                state: { from: location },
              }}
            />
          );
        }

        if (isActive) {
          return isUserActive ? (
            <Component />
          ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : routeRedirectDashboard,
                state: { from: location },
              }}
            />
          );
        }

        return !isUserAdmin ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : routeRedirectDashboard,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
