import { Button, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { useReactModal } from '../../contexts/ReactModal';

export function RecoverPassword(): JSX.Element {
  const { handleOpenResetPassword, handleOpenResetSecurityPassword } =
    useReactModal();

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex w="100%" gridGap="4" flexDir={isWideVersion ? 'row' : 'column'}>
      <Flex
        alignItems="center"
        flex="1"
        bg="#0C6E9C"
        rounded="10px"
        p="4"
        flexDir="column"
      >
        <Text fontSize={isWideVersion ? 'lg' : 'md'}>
          Deseja alterar sua senha de acesso?
        </Text>
        <Button
          mt="4"
          colorScheme="green"
          type="button"
          flex="1"
          p="3"
          onClick={handleOpenResetPassword}
          fontSize={isWideVersion ? 'lg' : 'md'}
        >
          Alterar senha de acesso
        </Button>
      </Flex>

      <Flex
        alignItems="center"
        flex="1"
        bg="#0C6E9C"
        rounded="10px"
        p="4"
        flexDir="column"
      >
        <Text fontSize={isWideVersion ? 'lg' : 'md'}>
          Deseja alterar sua senha de segurança?
        </Text>
        <Button
          mt="4"
          colorScheme="green"
          type="button"
          flex="1"
          p="3"
          onClick={handleOpenResetSecurityPassword}
          fontSize={isWideVersion ? 'lg' : 'md'}
        >
          Alterar senha de segurança
        </Button>
      </Flex>
    </Flex>
  );
}
