import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import {
  FormControl,
  FormLabel,
  SelectProps as ChakraSelectProps,
  Select as ChakraSelect,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FieldError } from 'react-hook-form';

interface SelectProps extends ChakraSelectProps {
  name: string;
  label?: string;
  disabled?: boolean;
  error?: FieldError;
  children: ReactNode;
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  { name, label, disabled = false, error = null, children, ...rest },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <ChakraSelect
        name={name}
        id={name}
        focusBorderColor="gray.500"
        background="gray.900"
        color="gray.50"
        variant="filled"
        _hover={{ bgColor: 'gray.900' }}
        size="lg"
        disabled={disabled}
        _placeholder={{ bgColor: 'gray.900' }}
        ref={ref}
        {...rest}
      >
        {children}
      </ChakraSelect>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const Select = forwardRef(SelectBase);
