import React, { useState } from 'react';
import {
  Button,
  Flex,
  Stack,
  Image,
  Link,
  Heading,
  useBreakpointValue,
  useMediaQuery,
  useToast,
  Checkbox,
  IconButton,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useEffect } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import logoSignin from '../../assets/logo-login.png';

import { Input } from '../../components/Input';
import { useAuth } from '../../hooks/auth';

type SigninFormData = {
  login: string;
  password: string;
  isRemember: boolean;
};

const signinFormSchema = yup.object().shape({
  login: yup.string().required('Usuário ou email obrigatório'),
  password: yup.string().required('Senha deve ser preenchida'),
  isRemember: yup.boolean(),
});

export function Signin(): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);
  const { signIn } = useAuth();
  const toast = useToast();

  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)');

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(signinFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    const loginRemember = localStorage.getItem('@Active:rememberLogin');

    if (loginRemember) {
      setValue('login', loginRemember);
    }
  }, [setValue]);

  const handleSignin: SubmitHandler<SigninFormData> = async data => {
    try {
      await signIn(data);
    } catch (err) {
      toast({
        title: 'Erro na autenticação!',
        status: 'error',
        duration: 3000,
        isClosable: true,
        description: 'Ocorreu um erro ao fazer o login. cheque as credenciais',
        position: 'top-right',
      });
    }
  };

  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const RightActionPasswordInput = () => (
    <IconButton
      aria-label="Mostrar/Ocultar senha"
      onClick={() => setShowPassword(!showPassword)}
      icon={showPassword ? <FiEyeOff /> : <FiEye />}
      bg="transparent"
      _hover={{
        background: 'transparent',
      }}
    />
  );

  return (
    // <Heading color="black" textAlign="center">
    //   Sistema em manutenção de rotina - System under routine maintenance.
    // </Heading>
    <Flex
      h="100vh"
      w="100vw"
      align={isWideVersion ? 'stretch' : 'center'}
      justify={isWideVersion || isLargerThan1280 ? 'center' : 'center'}
      bg="#0C6E9C"
    >
      <Flex
        maxW="1600px"
        width="100%"
        h="100%"
        p={12}
        justifyContent="space-between"
        alignItems="center"
        flexDir="column"
        overflowY="auto"
      >
        <Image src={logoSignin} maxW="400px" />

        <Stack
          as="form"
          spacing="4"
          width="100%"
          maxWidth="500px"
          height="100%"
          justify="center"
          onSubmit={handleSubmit(handleSignin)}
        >
          <Heading textAlign="center">Entrar</Heading>
          <Input
            label="E-mail ou Usuário"
            error={errors.login}
            {...register('login')}
          />

          <Input
            type={showPassword ? 'text' : 'password'}
            label="Senha"
            rightAction={<RightActionPasswordInput />}
            error={errors.password}
            {...register('password')}
          />

          <Checkbox defaultChecked {...register('isRemember')}>
            Lembrar-me
          </Checkbox>

          <Button
            colorScheme="blue"
            mt="4"
            width="100%"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            Entrar
          </Button>

          <Flex
            maxWidth="500px"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            mt="8"
            color="gray.100"
            flexDir={isWideVersion ? 'row' : 'column'}
            gridGap={isWideVersion ? '4' : '8'}
          >
            <Link href="/forgot-password">Esqueci minha senha</Link>
            <Link href="/signup">Cadastre-se</Link>
          </Flex>
        </Stack>
      </Flex>
    </Flex>
  );
}
