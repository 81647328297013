import React from 'react';
import { Flex, useBreakpointValue } from '@chakra-ui/react';

import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { UserDashboard } from '../../components/Dashboard/UserDashboard';

export function Dashboard(): JSX.Element {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Flex h={isWideVersion ? '100vh' : '100%'} bg="#C9C9C9">
      <Sidebar />

      <Flex direction="column" w="100%">
        <Header />

        <UserDashboard />
      </Flex>
    </Flex>
  );
}
