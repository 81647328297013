import React from 'react';
import { Stack } from '@chakra-ui/react';
import {
  FiUser,
  FiDollarSign,
  FiUsers,
  FiBarChart,
  FiFolder,
  FiSettings,
  FiHelpCircle,
} from 'react-icons/fi';
import { FaMoneyBillAlt, FaCalculator } from 'react-icons/fa';
import { BiTachometer, BiNews, BiMessage, BiGroup } from 'react-icons/bi';

import { NavLink } from './NavLink';
import { useAuth } from '../../hooks/auth';

export function SidebarNav(): JSX.Element {
  const { user } = useAuth();
  const isAdm = user.role === 0;

  return (
    <Stack spacing="12" align="flex-start">
      {!isAdm ? (
        <>
          <NavLink
            icon={BiTachometer}
            href="/dashboard"
            children="Painel de controle"
          />

          <NavLink icon={FiUser} href="/update-user" children="Minha conta" />

          {user.activeStatus === 1 && (
            <>
              <NavLink
                icon={FiDollarSign}
                href="/financial"
                children="Financeiro"
              />

              {user.role === 2 && (
                <>
                  {/* <NavLink
                    icon={FiUsers}
                    href="/my-franchised"
                    children="Minhas franquias"
                  /> */}

                  <NavLink
                    icon={FiUsers}
                    href="/my-referrals"
                    children="Meus indicados"
                  />
                </>
              )}

              {/* <NavLink
                icon={BiCreditCardFront}
                href="/ticket"
                children="Tickets"
              /> */}

              <NavLink
                icon={FiBarChart}
                href="/investment-projection"
                children="Projeção de Ganhos"
              />

              <NavLink
                icon={FaCalculator}
                href="/simulate-investment"
                children="Simular ganhos"
              />

              <NavLink icon={FiHelpCircle} href="/support" children="Suporte" />

              <NavLink
                icon={FiFolder}
                href="/backup-file"
                children="Materiais de apoio"
              />
            </>
          )}
        </>
      ) : (
        <>
          <NavLink
            icon={BiTachometer}
            href="/dashboard-adm"
            children="Painel de controle"
          />

          <NavLink
            icon={FiSettings}
            href="/management"
            children="Gerenciamento"
          />

          <NavLink
            icon={FaMoneyBillAlt}
            href="/financial-adm"
            children="Financeiro"
          />

          <NavLink icon={BiNews} href="/reports" children="Relatório" />

          <NavLink icon={BiMessage} href="/messages" children="Mensagens" />

          <NavLink
            icon={FiFolder}
            href="/management-franchised"
            children="Franquias"
          />

          <NavLink
            icon={BiGroup}
            href="/profitability-groups"
            children="Grupos de Rendimento"
          />

          <NavLink
            icon={FiFolder}
            href="/backup-file-adm"
            children="Materiais de apoio"
          />
        </>
      )}
    </Stack>
  );
}
